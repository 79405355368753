import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
}  from '@material-ui/core/';
import { firebaseApp, provider } from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';
import HeaderComponent from './header.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';
import CoursesListComponent from './courses_list.component';
import TermsPrivacyAgreement from '../agreements/termsPrivacyAgreement.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    paddingTop: '15vh',
    backgroundColor: fade(theme.palette.primary.main,0.20),
    minHeight: '100vh'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  google_btn: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(15)
  }
}));

const TeacherDashboardComponent = props => {
  let { history } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ courses, setCourses ] = useState([]);

//
  useEffect(() => {

    // setCourses(testCourses);
    // setLoading(false);

    if(!mainState.authUser) {
      history.push('/teacher-login');
      return;
    }

    if(!mainState.credentials) {

      provider.addScope('https://www.googleapis.com/auth/classroom.courses.readonly');
      provider.addScope('https://www.googleapis.com/auth/classroom.rosters.readonly');
      provider.addScope('https://www.googleapis.com/auth/classroom.profile.emails');

      firebaseApp.auth().signInWithPopup(provider).then(function(result) {

        mainDispatch({ type: 'setCredentials', payload: result.credential });

        getCourses(result.credential.accessToken);
        initCheckAgreement();

      }).catch(function(error) {

        console.dir(error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

        setLoading(false);
        SignOut();
        history.push('/teacher-login');
        alert(errorMessage);
      });

      return;

    }

    getCourses(mainState.credentials.accessToken);
    initCheckAgreement();

  }, []);
//*/

  const initCheckAgreement = async () => {

    console.log('Checking agreement.');

    let email = mainState.authUser.email;

    try {
      
      let agreementStatus = await firebaseApp.firestore()
      .collection('termsPrivacyAgreement').doc(email).get();

      if(!agreementStatus.exists) {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: true });
        return;
      }

    } catch(e) {
      // statements
      console.log(e);
    }

  }

  const agreementResponse = agreed => {

    if(!agreed) {
      SignOut();
      mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      history.push('/home');
      return;
    }

    let email = mainState.authUser.email;

    firebaseApp.firestore().collection('termsPrivacyAgreement').doc(email)
      .set({
        agreed: Date.now()
      })
      .then(() => {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })
      .catch(err => {
        console.log(err);
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })

  }

  const getCourses = async (accessToken=mainState.credentials.accessToken) => {

    let tokenUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/getClassroomKey";

    let apiKey = await fetch(tokenUrl,{
      method: 'Post',
      body: JSON.stringify({
        email: mainState.authUser.email
      })
    });

    let apiTextRes = await apiKey.text();

    if(!apiKey.ok) {
      setLoading(false);
      setTimeout(() => {
        alert(`ERROR: ${apiTextRes}`);
      },500);
      return;
    }

    mainDispatch({ type: 'setClassroomKey', payload: apiTextRes });

    let url = `https://classroom.googleapis.com/v1/courses?courseStates=ACTIVE&teacherId=${mainState.authUser.email}&access_token=${accessToken}&key=${apiTextRes}`;

    try {

      let initGetCourses = await fetch(url);

      if(initGetCourses.ok) {
        let json = await initGetCourses.json();

        if(json.hasOwnProperty('courses') && Array.isArray(json.courses)) {
          setCourses(json.courses);
        }

        setLoading(false);
        return;
      }
      setLoading(false);
      alert('Error locating your courses.');

    } catch (e) {
      console.dir(e);
      setLoading(false);
      alert('Error locating your courses.');
    }
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <HeaderComponent title="Teacher Dashboard" />

      {
        courses.length <= 0 && !loading && (
          <Typography
            align='center'
            component="h1"
            variant="h4"
          >No Courses Found</Typography>
        )
      }

      {
        courses.length > 0 && (
          <CoursesListComponent courses={courses} />
        )
      }

      <TermsPrivacyAgreement
        visible={mainState.termsPrivacyAgreement}
        agreementResponse={agreementResponse}
      />
      <BackDrop loading={loading}/>
    </Grid>
  )
}

export default TeacherDashboardComponent
