import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode';
import { analytics } from '../../firebase';

import CustomHeader  from './header.component';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '10vh',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: '100vh',
		padding: theme.spacing(5)
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 400,
		padding: theme.spacing(1),
		margin: theme.spacing(2)
	},
	textField: {
		margin: theme.spacing(2),
		minWidth: 350,
	},
	btn: {
		margin: theme.spacing(1),
		minWidth: 350,
	},
	instructions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 550,
		margin: theme.spacing(2)
	}
}));

const QRCodeGenerator = () => {

	const classes = useStyles();
	const [ showInstructions, setShowInstructinos ] = useState(false);
	const [ showDownloadBtn, setShowBtn ] = useState(false);

	useEffect(() => {
	    document.getElementById('inquiry').style.display = 'none';
	})	

	const handleSubmit = async (e) => {

		e.preventDefault();
		e.persist();

		let url = e.target[0].value;

		if(!url) {
			return;
		}

		//  HIDE DOWNLOAD BUTTON
		setShowBtn(false);

		//  CLEAR THE CANVAS FIRST
		let canvas = document.getElementById('qr-canvas');

		let context = canvas.getContext('2d');
		context.clearRect(0,0,canvas.width,canvas.height);

		let urlDomain = url.split('/')[2];

		if(!urlDomain) {

			analytics.logEvent('QRCode_created',{ url: false });

		} else {
			analytics.logEvent('QRCode_created',{ url: true, domain: urlDomain });
		}

		try {

			let toCanvas = await QRCode.toCanvas(canvas,url);

			setShowBtn(true);

			e.target.reset();

		} catch(e) {
			console.log(e.message);
		}
		
	}

	const downloadQRCode = async () => {

		let downloadLink = document.createElement('a');
	    downloadLink.setAttribute('download', 'qrCode.png');
	    let canvas = document.getElementById('qr-canvas');
	    canvas.toBlob(function(blob) {
	      let url = URL.createObjectURL(blob);
	      downloadLink.setAttribute('href', url);
	      downloadLink.click();

	      analytics.logEvent('QRCode_downloaded');
	    });

	}

	return (
		<div className={classes.root}>
			<CustomHeader title="QR Code Generator" />

			<form 
				onSubmit={handleSubmit}
				className={classes.form}
			>
				<TextField
					label="Paste/Enter URL"
					className={classes.textField}
				/>
				<Button
					variant="contained"
					color='primary'
					type='submit'
					className={classes.btn}
				>
					Generate QR Code
				</Button>
			</form>

			<canvas id='qr-canvas'></canvas>

			{
				showDownloadBtn && (
					<div className={classes.instructions}>
						<Button color='primary' onClick={downloadQRCode}>Download QR Code</Button>
						<Typography component='h1'>
							Be sure to test the QR Code before downloading and distributing the image.
						</Typography>
					</div>
				)
			}


		</div>
	)
}

export default QRCodeGenerator
