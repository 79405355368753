import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Grid
}  from '@material-ui/core/';
import { firebaseApp, provider } from '../firebase';
import { SignOut } from './utilities/firebase.actions';
import HeaderComponent from './header.component';
import BackDrop from './backdrop.component';
import MainContext from './state/main.context';
import GoogleBtn from './images/google_signin_buttons/web/2x/btn_google_signin_dark_focus_web@2x.png';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    paddingTop: '15vh',
    backgroundColor: fade(theme.palette.primary.main,0.20),
    minHeight: '100vh'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  google_btn: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(15)
  }
}));

const LoginComponent = props => {
  const location = useLocation();
  const query = useQuery();
  const redirect = query.get('redirect');
  const params = useParams();
  let { history } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  //  AUTH LISTENER
  useEffect(() => {

    const authListener = firebaseApp.auth().onAuthStateChanged( (user) => {

      mainDispatch({ type: 'setLoading', payload: true });

      if (user) {

        verifyUser(user);

      } else {

        mainDispatch({ type:'setLoading', payload: false });

        console.log('THERE IS NO USER.');
        mainDispatch({ type: 'setLoading', payload: false });

      }

    });


      return () => authListener();
  }, [])

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const verifyUser = async (user) => {

    console.log('Verifying user...');

    let options = {
      method: 'Post',
      body: JSON.stringify({
        user:user
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/verifyUser";

    try {

      let initFetch = await fetch(url,options);

      if(initFetch.ok) {

        let jsonRes = await initFetch.json();
        console.log('User is verified...');
        mainDispatch({ type: 'setAuthUser', payload: user });
        mainDispatch({ type: 'setDomain', payload: user.email.split('@')[1] });
        mainDispatch({ type: 'setIsAdmin', payload: jsonRes.isAdmin });
        mainDispatch({ type: 'setLoading', payload: false });

        if(redirect) {
          return pushPage(redirect);
        }

        return pushPage(jsonRes.page);

      }

      mainDispatch({ type: 'setLoading', payload: false });
      //  Sign user out
      SignOut();
      alert('You do not have permission.  If this was a mistake, please contact your domain administrator.')

      return pushPage('home');

    } catch (e) {

      console.dir(e);

      mainDispatch({ type: 'setLoading', payload: false });
      //  Sign user out
      SignOut();
      pushPage('home');
      alert('You do not have permission.  If this was a mistake, please contact your domain administrator.')

    }

  }

  const pushPage = (page) => {
    history.push('/' + page);
  }

  const submit = async (e) => {

    e.preventDefault();
    e.persist();

    try {

      mainDispatch({ type: 'setLoading', payload: true });

      let initSignin = await firebaseApp.auth().signInWithEmailAndPassword(email, password);

      if(initSignin.hasOwnProperty('user') && initSignin.user.email.toLowerCase() === email.toLowerCase()){
        mainDispatch({ type: 'setLoading', payload: false });
        return;
      }

      mainDispatch({ type: 'setLoading', payload: false });
      //  Sign user out
      SignOut();
      alert('You do not have permission.  If this was a mistake, please contact your domain administrator.')

    } catch (e) {

      console.log(e);
      mainDispatch({ type: 'setLoading', payload: false });
      //  Sign user out
      SignOut();
      alert('You do not have permission.  If this was a mistake, please contact your domain administrator.')

    }

  }

  const initSignIn = () => {

    mainDispatch({ type: 'setLoading', payload: true });

    //  DO NOT NEED THESE SCOPES
    //  GETTING INFOR VIA DOMAIN WIDE DELEGATION
    // provider.addScope("https://www.googleapis.com/auth/admin.directory.orgunit.readonly");
    // provider.addScope("https://www.googleapis.com/auth/admin.directory.user.readonly");

    firebaseApp.auth().signInWithPopup(provider).then(function(result) {

      mainDispatch({ type: 'setCredentials', payload: result.credential });

      mainDispatch({ type: 'setLoading', payload: false });

    }).catch(function(error) {

      console.dir(error);
      // Handle Errors here.
      mainDispatch({ type: 'setLoading', payload: false });
    });
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <HeaderComponent title="Login" />

      <div className={classes.formContainer}>

        <img
          src={GoogleBtn}
          onClick={initSignIn}
          className={classes.google_btn}
          alt="Google Signin Button"
        />

        <Paper className={classes.paper} raised={2}>

        <Typography className={classes.title}>
          Non-SSO Sign-in
        </Typography>

        <form onSubmit={ (event)=> submit(event) } className={classes.form}>
          <TextField
            id="email"
            label="Email"
            type='email'
            onChange={e => setEmail(e.target.value)}
            margin="normal"
            required={true}
          />
          <TextField
            id="password"
            label="Password"
            onChange={e => setPassword(e.target.value)}
            margin="normal"
            required={true}
            type='password'
            error={showError}
            helperText={showError ? errorMessage : null}
          />

          <Button
            type='submit'
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={mainState.isLoading}
          >
            Log In
          </Button>
        </form>

        </Paper>

      </div>

      <BackDrop loading={mainState.isLoading}/>
    </Grid>
  )
}

export default LoginComponent
