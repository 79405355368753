import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import GlobalState from './app/state/global.state';
import HomeComponent from './app/home.component';
import LandingPageComponent from './app/landing_page.component';
import LoginComponent from './app/login.component';
import PricingComponent from './app/pricing.component';
import ContactUsComponent from './app/contact_us.component';
import PublicComponent from './app/public/public.component';
import LegalDocuments from './app/policies/legal.docs.component';
import DashboardComponent from './app/dashboard/dashboard.component';
import WeeklyReportComponent from './app/dashboard/apps/weekly_report.component';
import SupervisorDashboardComponent from './app/dashboard/supervisor-components/supervisor-dashboard.component';
import SignUpComponent from './app/sign_up.component';
import FinalizaAccountComponent from './app/finalize-account.component';
import NoPageComponent from './app/noPageFound.component';
import GettingStartedComponent from './app/getting_started.component';
import ApproveItLoginComponent from './app/approveit/login.component';
import ApproveItComponent from './app/approveit/approveit.component';
import TeacherLoginComponent from './app/teacher-dashboard/teacher.login.component';
import TeacherDashboardComponent from './app/teacher-dashboard/teacher.dashboard.component';
import QRCodeGenerator from './app/QRCodeGenerator/qrcode.component';
import AnnouncementRendererComponent from './app/announcement/announcement.component';

//  APP REQUESTS
import RequestFormComponent from './app/inquiry_forms/appRequestComponents/request_form.component';
import RequestStatusComponent from './app/inquiry_forms/appRequestComponents/request_status.component'; 
import RequestAdminComponent from './app/inquiry_forms/appRequestComponents/request_admin.component'; 

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// CUSTOM THEME
const theme = createMuiTheme({"breakpoints": {
  "values": {
    xs: 0,
    sm: 750,
    md: 1280,
    lg: 1400,
    xl: 2200
  }
},"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"rgba(100, 70, 167, 1)","main":"rgba(51, 29, 119, 1)","dark":"rgba(0, 0, 74, 1)","contrastText":"#fff"},"secondary":{"light":"rgba(200, 233, 90, 1)","main":"rgba(148, 183, 37, 1)","dark":"rgba(98, 135, 0, 1)","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}});


const App = props => {

  useEffect(() => {
    document.title = "Manage iT";
  },[]);

  return (
    <GlobalState>
      <Router>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path='/' exact={true} component={LandingPageComponent} />
            <Route path='/home' component={HomeComponent} />
            <Route path='/login' component={LoginComponent} />
            <Route path='/contact-us' component={ContactUsComponent} />
            <Route path='/pricing' component={PricingComponent} />
            <Route path='/signup' component={SignUpComponent} />
            <Route path='/finalize-account/:email' component={FinalizaAccountComponent} />

            <Route path='/legal/:doc' component={LegalDocuments} />

            <Route path='/dashboard' component={DashboardComponent} />
            <Route path='/weekly-report' component={WeeklyReportComponent} />

            <Route path='/supervisor-dashboard' component={SupervisorDashboardComponent} />
            <Route path='/approve-it/login' component={ApproveItLoginComponent} />
            <Route path='/approve-it' component={ApproveItComponent} />
            <Route path='/public/:domain' component={PublicComponent} />


            <Route path='/request-form/:domain/:user' component={RequestFormComponent} />
            <Route path='/request-status/:domain/:requestId' component={RequestStatusComponent} />
            <Route path='/request-admin/:domain/:requestId' component={RequestAdminComponent} />

            <Route 
              path='/manageit-domains/:domain/announcements/:announcementId' 
              component={AnnouncementRendererComponent} 
            />

            <Route path='/teacher-login' component={TeacherLoginComponent} />
            <Route path='/teacher-dashboard' component={TeacherDashboardComponent} />

            <Route path='/generate-qrcode' component={QRCodeGenerator} />

            <Route component={NoPageComponent} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    </GlobalState>
  )
}

export default App;
