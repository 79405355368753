import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Paper,
} from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		// alignItems: 'center',
		marginTop: theme.spacing(2),
		maxWidth: 850,
		minWidth: 700
	},
	card: {
		margin: theme.spacing(1),
		backgroundColor: fade(theme.palette.primary.main,0.60),
		minWidth: 700
	},
	listItem: {

	},
	primaryText: {
		fontSize: 14
	},
	secondaryText: {
		marginLeft: theme.spacing(1),
		fontSize: 18,
		marginBottom: theme.spacing(2)
	}
}));

const CourseAnnouncementsComponent = ({ announcements }) => {

	console.dir(announcements);

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		
		

	},[]);

	if(announcements.length <= 0) {
		return (
			<Typography variant='subtitle1' component='h1' align='center'>No Announcements</Typography>
		)
	}

	return (
		<div className={classes.root}>
				{

				
					announcements.map(announcement =>
						<Card className={classes.card} key={announcement.id}>
							<CardContent>

								<Typography className={classes.primaryText} component="h1">
									{ moment(announcement.creationTime).format("dddd, MMMM Do YYYY, h:mm a") }
								</Typography>

								<Typography className={classes.secondaryText} component="h1">
									{ announcement.text }
								</Typography>

								<Typography component="h1"><b>Materials:</b></Typography>

				         		{  //  PARSE THROUGH MATERIALS AND RENDER EACH COURSE WORK POSTINGS
				         			(announcement.hasOwnProperty('materials') && announcement.materials.length > 0) && (
				         				announcement.materials.reduce((acc,curr) => {
				         					if(curr.hasOwnProperty('driveFile')) {
				         						let tempObj = {};
				         						tempObj.title = curr.driveFile.driveFile.title;
				         						tempObj.url = curr.driveFile.driveFile.alternateLink;
				         						acc.push(tempObj);
				         						return acc;
				         					}
				         					if(curr.hasOwnProperty('form')) {
				         						let tempObj = {};
				         						tempObj.title = curr.form.title;
				         						tempObj.url = curr.form.formUrl;
				         						acc.push(tempObj);
				         						return acc;
				         					}
				         					if(curr.hasOwnProperty('link')) {
				         						let tempObj = {};
				         						tempObj.title = curr.link.title;
				         						tempObj.url = curr.link.url;
				         						acc.push(tempObj);
				         						return acc;
				         					}

				         					return acc;

				         				},[]).map(each => 
				         					<a key={each.url} href={each.url} target="_blank">
				         						<Typography>{each.title}</Typography>
				         					</a>
				         				)
				         			)
				         		}

							</CardContent>
						</Card>
					)

				}

		</div>
	)
}

export default CourseAnnouncementsComponent


