import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Divider,
  CheckBox,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ManageAppsComponent from './getting_started/manage_apps.component';
import ManageResourcesComponent from './getting_started/manage_resources.component';
import ManageUsersComponent from './getting_started/manage_users.component';
import ManageDirectoryComponent from './getting_started/manage_directory.component';
import InstallExtensionComponent from './getting_started/install_extension.component';
import DomainWideDelegation from './getting_started/domain_delegation.component';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '10vh',
    paddingBottom: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(5),
    margin: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(2)
  },
  videoDesc: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  templateLink: {
    margin: theme.spacing(5)
  },
  linkDiv: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
  tabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))

const GettingStartedComponent = props => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [tab, setTab] = React.useState(0);


  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.paperTabs,'apps-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            className={classes.tabs}
            centered
          >
            <Tab label="Manage Apps" className="apps-table-tab" />
            <Tab label="Manage Resources" className="apps-search-tab" />
            <Tab label="Manage Users" className="apps-add-tab" />
            <Tab label="Manage Directory" className="apps-data-tab" />
            <Tab label="Install Chrome Extension" className="install-extension-tab" />
            <Tab label="Domain Wide Delegation" className="apps-data-tab" />
          </Tabs>
        </Paper>
      </div>

      {
        tab === 0 && (
          <ManageAppsComponent />
        )
      }

      {
        tab === 1 && (
          <ManageResourcesComponent />
        )
      }

      {
        tab === 2 && (
          <ManageUsersComponent />
        )
      }

      {
        tab === 3 && (
          <ManageDirectoryComponent />
        )
      }

      {
        tab === 4 && (
          <InstallExtensionComponent />
        )
      }

      {
        tab === 5 && (
          <DomainWideDelegation />
        )
      }

    </div>
  )

  return (
    <div className={classes.root}>

    {/*  DATA STRUCTURE  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          Step 1:  STRUCTURE YOUR DATA
        </Typography>

        <div className={classes.videoDesc}>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/xYDFatxJ8Gc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>

      </Paper>

      {/*  PREP/UPLOAD CSV FILES  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          Step 2:  PREP AND UPLOAD .CSV FILES
        </Typography>

        <div className={classes.videoDesc}>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/N9jjw_tTOQA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        <div className={classes.linkDiv}>

          <a
            href='https://docs.google.com/spreadsheets/d/1wLVKbYVMNcXMzlws6EdotUueJOihURXgwbraldpQkV8/edit#gid=0'
            target="_blank"
          >
            <Typography
              align='left'
              variant='h6'
              component='h1'
              className={classes.templateLink}
            >
              Users Template
            </Typography>
          </a>

          <a
            href='https://docs.google.com/spreadsheets/d/1wR719yBEPl34txcrNgiTtcAoTK-Ycbc41exu9noagbQ/edit#gid=0'
            target="_blank"
          >
            <Typography
              align='left'
              variant='h6'
              component='h1'
              className={classes.templateLink}
            >
              Apps Template
            </Typography>
          </a>

          <a
            href='https://docs.google.com/spreadsheets/d/1wYjSb09F89I-He4o9BhzfCvHcsj3MtSK1O3Hlm0k-k8/edit#gid=0'
            target="_blank"
          >
            <Typography
              align='left'
              variant='h6'
              component='h1'
              className={classes.templateLink}
            >
              Resources Template
            </Typography>
          </a>

          <a
            href='https://docs.google.com/spreadsheets/d/1fJcPqKmbbZT-xciKpOJpcVlMV_DDhirxAofvP_GPx6w/edit#gid=0'
            target="_blank"
          >
            <Typography
              align='left'
              variant='h6'
              component='h1'
              className={classes.templateLink}
            >
              Directory Template
            </Typography>
          </a>



        </div>

        </div>

      </Paper>

      {/*  TEST EXTENSION  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          Step 3: TEST THE EXTENSION
        </Typography>

        <div className={classes.videoDesc}>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/lniRaRil60k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>

      </Paper>

      {/*  INSTALL EXTENSION  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          Step 4:  INSTALL MANAGE IT EXTENSION
        </Typography>

        <div className={classes.videoDesc}>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/WcHtz1fKWtE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        <div className={classes.linkDiv}>

          <a
            href='https://chrome.google.com/webstore/detail/manage-it-extension/dbngcgkkmlhlnlekkjpakcncgkfekghh'
            target="_blank"
          >
            <Typography variant='h6' component='h1' className={classes.templateLink}>
              Extension Link
            </Typography>
          </a>

          <Typography>
            Ext ID:  dbngcgkkmlhlnlekkjpakcncgkfekghh
          </Typography>

        </div>

        </div>

      </Paper>

      {/*  DETERMINE AND MARK PUBLIC ITEMS  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          Step 5:  PUBLIC ENTITIES
        </Typography>

        <div className={classes.videoDesc}>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/G5EQdk5PODQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>

      </Paper>

      {/*  DELEGATION  */}
      <Paper className={classes.paper} elevation={3}>

        <Typography
          variant="h3" align="center"
          className={classes.title}
        >
          <a href="https://docs.google.com/document/d/1hCYGmAzK6THn8Ec9ca5fGgUXwLHvjRpdMzqWs7QbqSM/preview" target="_blank">
            API Control Access and Domain Wide Delegation
          </a>
        </Typography>

      </Paper>

    </div>
  )
}

export default GettingStartedComponent
