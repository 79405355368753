import React, {
  useEffect,
  useState
} from 'react';
import {
  Button,
  Typography,
  CardContent,
  Card,
  TextField,
  IconButton,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import HeaderComponent from './header.component';
import FooterComponent from '../footer.component';
import BackDrop from '../backdrop.component.js';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table'

const useStyles = makeStyles( theme => ({
  root: {
    paddingTop: '6vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  header: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  tableDiv: {
    overflow: 'scroll'
  },
  descriptionsCont: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    alignSelf: 'flex-start'
  },
  descriptions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  image: {
    flexGrow: 1,
    height: 150,
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(5)
  },
  paperTabs: {
    flexGrow: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    alignSelf: 'center',
  },
  paper: {
    minWidth: 400,
    maxWidth: 550
  },
  emptyRight: {
    flexGrow: 1
  },
  desc1: {
    marginBottom: theme.spacing(2),
    fontSize: '1.25em',
    whiteSpace: 'pre-wrap'
  },
  desc2: {
    whiteSpace: 'pre-wrap',
  },
  menuContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  card: {
    minWidth: 345,
    maxWidth: 345,
    margin: theme.spacing(2)
  },
  heading: {
    fontSize: '1.5em',
    marginTop: '5vh'
  },
  resourcesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '40vh',
    marginTop: theme.spacing(3),
    maxWidth: 1500
  },
  appsContainer: {
    // flexGrow:
    display: 'flex',
    flexDirection: 'column',

    overflow: 'scroll'
  },
  resourceTitle: {
    fontSize: '1.5em'
  },
  resourceCardItem: {
    margin: theme.spacing(1)
  },
  contentTitle: {
    fontWeight: 'bolder'
  },
  url: {
    textDecoration: 'none'
  },
  noData: {
    textAlign: 'center',
    fontSize: '2em',
    marginTop: '10vh',
    marginBottom: '40vh'
  },
  table: {
    marginTop: theme.spacing(5)
  },
  title: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  gradeLevel: {
    maxWidth: 150,
    minWidth: 100,
    overflow: 'scroll'
  },
  desc: {
    maxWidth: 300,
    maxHeight: 300,
    minWidth: 200,
    overflow: 'scroll'
  },
  urlBox: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  contentArea: {
    minWidth: 250,
    maxWidth: 300
  },
  agreementsColumn: {
    maxWidth: 100
  },
  img: {
    maxWidth: 100,
    maxHeight: 100
  }
}));

const Resources = ({ resources, domain, loading }) => {

  const classes = useStyles();
  const [ searching, setSearching ] = useState(false);
  const [ filtered, setFiltered ] = useState(null);
  const [ noResult, setNoResult ] = useState(false);
  const [ data, setData ] = useState(null);

  useEffect(() => {

    processResources();

  },[])

  useEffect(() => {

    processResources();

  },[resources])

  const processResources = () => {
    let reduced = resources.reduce((accumulator,currentValue) => {
      if(accumulator.hasOwnProperty(currentValue.category.toLowerCase())) {
        accumulator[currentValue.category.toLowerCase()].push(currentValue);
        return accumulator;
      }
      accumulator[currentValue.category.toLocaleLowerCase()] = [];
      accumulator[currentValue.category.toLowerCase()].push(currentValue);
      return accumulator;
    },{});

    setData(reduced);
  }

  const noResults = () => {
    setNoResult(true);
    setTimeout(() => {
      setNoResult(false);
    },2500);
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.persist();

    let searchTerm = new RegExp(e.target[0].value,'i');

    let results = resources.filter(item => {
      if(item.title.search(searchTerm) > -1 ||
        item.desc.search(searchTerm) > -1) {
          return item;
        }

    });

    if(results.length <= 0) {
      noResults();
      return;
    }

    let reduced = results.reduce((accumulator,currentValue) => {
      if(accumulator.hasOwnProperty(currentValue.category.toLowerCase())) {
        accumulator[currentValue.category.toLowerCase()].push(currentValue);
        return accumulator;
      }
      accumulator[currentValue.category.toLocaleLowerCase()] = [];
      accumulator[currentValue.category.toLowerCase()].push(currentValue);
      return accumulator;
    },{});


    setFiltered(reduced);
    setSearching(true);
  }

  if(loading) {
    return (
      <Typography className={classes.noData}>
        Loading...
      </Typography>
    )
  }

  if(resources.length <= 0) {
    return (
      <Typography className={classes.noData}>
        No public resources found for domain: { domain.toUpperCase() }
      </Typography>
    )
  }

  return (
    <div className={classes.resourcesContainer}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Search Resources"
        />

        <IconButton
          type="submit"
          color='primary'
        >
          <SearchIcon />
        </IconButton>

        <Button
          color='primary'
          type='reset'
          onClick={() => setSearching(false)}
        >
          reset
        </Button>
      </form>

      {
        noResult ?
          <Typography color='primary'>
            No results...
          </Typography>
        : null
      }

      <div className={classes.cards}>
        {

          (searching && filtered) ?

            Object.keys(filtered).map((key,i) => {
            return (
              <Card className={classes.card} key={i}>

                  <CardContent>
                  <Typography className={classes.resourceTitle} align='center'>
                    { key.toUpperCase() }
                  </Typography>

                  {
                      filtered[key].map((item,p) => {
                        return (
                          <a key={p} className={classes.url} href={item.url} target="_blank">
                          <Card className={classes.resourceCardItem}>
                            <CardContent>
                              <Typography
                                color='primary'
                                className={classes.contentTitle}
                              >{item.title.toUpperCase()}</Typography>
                              <Typography>{item.desc}</Typography>
                              </CardContent>
                          </Card>
                          </a>
                        )
                      })
                  }

                </CardContent>

              </Card>
            )
          })

            : data ?

            Object.keys(data).map((key,i) => {
              return (
                <Card className={classes.card} key={i}>

                    <CardContent>
                    <Typography className={classes.resourceTitle} align='center'>
                      { key.toUpperCase() }
                    </Typography>

                    {
                        data[key].map((item,p) => {
                          return (
                            <a key={p} className={classes.url} href={item.url} target="_blank">
                            <Card className={classes.resourceCardItem}>
                              <CardContent>
                                <Typography
                                  color='primary'
                                  className={classes.contentTitle}
                                >{item.title.toUpperCase()}</Typography>
                                <Typography>{item.desc}</Typography>
                                </CardContent>
                            </Card>
                            </a>
                          )
                        })
                    }

                  </CardContent>

                </Card>
              )
            })

            : null

        }
      </div>

    </div>
  )
}

const Apps = ({ apps, domain }) => {

  const classes = useStyles();

  useEffect(() => {},[apps]);

  const columns = [
    {
      render: rowData => {
        if(rowData.hasOwnProperty('imgUrl') && rowData.imgUrl) {
          return (
            <img src={rowData.imgUrl} className={classes.img} />
          )
        }
        return (
          <p>No Image</p>
        )
      },
      sorting: false
    },
    {
      title: 'Title',
      field: 'title',
        render: rowData => (
          <a href={rowData.url} target="_blank">
            <p className={classes.title}>{ rowData.title.toUpperCase() }</p>
          </a>
      )
    },
    {
      title: 'Company',
      field: 'company',
      render: rowData => <p className={classes.title}>{ rowData.company.toUpperCase() }</p>
    },
    {
      title: 'Desc',
      field: 'desc',
      render: rowData =>
        <div className={classes.desc}>
          <p>{ rowData.desc }</p>
        </div>,
      sorting: false
    },
    {
      title: 'Agreement Status',
      field: 'agreementStatus',
      render: rowData => <p className={classes.url}>{ rowData.agreementStatus.toUpperCase() }</p>
    },
    {
      title: 'Agreement Type',
      field: 'agreementType',
      sorting: false,
      render: rowData => {
        var regex = new RegExp('http','i');

        //  CHECK IF IT'S AN ARRAY TYPE FIRST
        if(Array.isArray(rowData.agreementType)) {
          return (
            <div className={classes.agreementsColumn}>
              {
                rowData.agreementType.map(agreement => 
                  <React.Fragment key={agreement.url}>
                  {
                    agreement.url ?
                      <a 
                        href={agreement.url} 
                        target="_blank"><p style={{'wordWrap': 'break-word'}}>{ agreement.title ? agreement.title : "Agreement" }</p></a>
                    : <p style={{'wordWrap': 'break-word'}}>{agreement.title}</p>
                  }
                  <br/>
                  <br />
                  </React.Fragment>
                )
              }
            </div>
          )
        }

        if(rowData.agreementType.search(regex) > -1){
          return (
            <div className={classes.agreementsColumn}>
              <a href={rowData.agreementType} target="_blank">
                <p>Agreement</p>
              </a>
            </div>
          )
        }
        return (
          <p className={classes.agreementsColumn}>{ rowData.agreementType }</p>
        )

      }
    },
    {
      title: 'Approval Date',
      field: 'approvalDate',
      sorting: false,
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('approvalDate') ? rowData.approvalDate : '' }
        </p>
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      sorting: false,
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('expirationDate') ? rowData.expirationDate : '' }
        </p>
    },
    {
      title: 'Data',
      field: 'data',
      sorting: false,
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('data') ? rowData.data : '' }
        </p>
    },
    {
      title: 'Grade Level',
      field: 'gradeLevel',
      sorting: false,
      render: rowData => <p className={classes.gradeLevel}>{ rowData.gradeLevel }</p>
    },
    {
      title: 'Content Area',
      field: 'contentArea',
      sorting: false,
      render: rowData =>
        <div className={classes.desc}>
          <p className={classes.contentArea}>{ rowData.contentArea }</p>
        </div>

    }
  ];

  return (
    <div className={classes.appsContainer}>

      <MaterialTable
        title="Apps"
        columns={columns}
        data={apps}
        style={{
          margin: '20px',
          marginTop: '50px'
        }}
        options={{
          pageSize: 20,
          search: true,
          sorting: true
        }}
      />

    </div>
  )
}

const Directory = ({ directory, domain, loading }) => {

  const classes = useStyles();
  const [ searching, setSearching ] = useState(false);
  const [ filtered, setFiltered ] = useState([]);
  const [ noResult, setNoResult ] = useState(false);

  const noResults = () => {
    setNoResult(true);
    setTimeout(() => {
      setNoResult(false);
    },2500);
  }

  const handleSubmit = e => {

    e.preventDefault();
    e.persist();

    let searchTerm = new RegExp(e.target[0].value,'i');

    let results = directory.filter(item => {
      if(item.contact.search(searchTerm) > -1 ||
        item.email.search(searchTerm) > -1 ||
        item.department.search(searchTerm) > -1) {
          return item;
        }
    });

    if(results.length <= 0) {
      noResults();
      return;
    }

    setFiltered([...results]);
    setSearching(true);

  }

  if(loading) {
    return (
      <Typography className={classes.noData}>
        Loading...
      </Typography>
    )
  }

  if(directory.length <= 0) {
    return (
      <Typography className={classes.noData}>
        No public directory found for domain: { domain.toUpperCase() }
      </Typography>
    )
  }

  return (
    <div className={classes.resourcesContainer}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Search Directory"
        />

        <IconButton
          type="submit"
          color='primary'
        >
          <SearchIcon />
        </IconButton>

        <Button
          color='primary'
          type='reset'
          onClick={() => setSearching(false)}
        >
          reset
        </Button>
      </form>
      {
        noResult ?
          <Typography color='primary'>
            No results...
          </Typography>
        : null
      }
      <div className={classes.cards}>
      {
        (searching && filtered.length > 0) ?

          filtered.map((contact,i) =>
          <Card variant="outlined"
                className={classes.card}
                key={i}
          >

            <CardContent>

              <Typography gutterBottom variant="h5" component="h2">
                { contact.contact.toUpperCase() }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                DEPARTMENT: { contact.department }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                EMAIL:  { contact.email || "NA" }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                PHONE:  { contact.phone || "NA" }
              </Typography>

            </CardContent>

          </Card>
        )

          : directory.map((contact,i) =>
          <Card variant="outlined"
                className={classes.card}
                key={i}
          >

            <CardContent>

              <Typography gutterBottom variant="h5" component="h2">
                { contact.contact.toUpperCase() }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                DEPARTMENT: { contact.department }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                EMAIL:  { contact.email || "NA" }
              </Typography>

              <Typography variant="body2" color="textSecondary" component="p">
                PHONE:  { contact.phone || "NA" }
              </Typography>

            </CardContent>

          </Card>
        )
      }
      </div>

    </div>
  )
}

const PublicComponent = props => {
  const classes = useStyles();
  const params = useParams();

  const [ loadingApps, setLoadingApps ] = useState(true);
  const [ loadingResources, setLoadingResources ] = useState(true);
  const [ loadingDirectory, setLoadingDirectory ] = useState(true);
  const [ apps, setApps ] = useState([]);
  const [ resources, setResources ] = useState([]);
  const [ directory, setDirectory ] = useState([]);
  const [ publicInfo, setPublicInfo ] = useState(null);
  const [tab, setTab] = React.useState(0);
  const [ title, setTitle ] = useState(params.domain);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    getPublicData();
    removeQuestionForm();
  },[]);

  const getPublicData = async () => {
    let options = {
      method: 'Post',
      body: JSON.stringify({
        domain: params.domain
      })
    }

    try {

      let publicInfoUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/getPublicInfo";

      let getPublicInfo = await fetch(publicInfoUrl,options);

      if(getPublicInfo.ok) {
        let publicJson = await getPublicInfo.json();
        setPublicInfo(publicJson);
        setTitle(publicJson.orgName);
      }

      let appsUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/getPublicApps";

      let getApps = await fetch(appsUrl,options);

      if(getApps.ok) {
        let appsJson = await getApps.json();
        setApps(appsJson);
      }

      setLoadingApps(false);

      // let resourcesUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/getPublicResources";

      // let getResources = await fetch(resourcesUrl,options);

      // if(getResources.ok) {
      //   let resourcesJson = await getResources.json();
      //   setResources(resourcesJson);
      // }

      // setLoadingResources(false);

      // let directoryUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/getPublicDirectory";

      // let getDirectory = await fetch(directoryUrl, options);

      // if(getDirectory.ok) {
      //   let directoryJson = await getDirectory.json();
      //   setDirectory(directoryJson);
      // }

      // setLoadingDirectory(false);

    } catch (e) {
      console.log(e.message);
      setLoadingApps(false);
      // setLoadingResources(false);
      // setLoadingDirectory(false);
    }

  }

  const removeQuestionForm = () => {
    document.getElementById('inquiry').style.display = 'none';
  }

  return (
    <div className={classes.root}>
      <HeaderComponent title={ publicInfo ? publicInfo.orgName : '' } />

      <div className={classes.header}>

        <div className={classes.descriptionsCont}>

          {
            publicInfo && publicInfo.hasOwnProperty('image') && publicInfo.image && (
              <img src={publicInfo.image} className={classes.image} />
            )
          }

        </div>

        <div className={classes.paperTabs}>
          <Paper square
            className={classes.paper}
          >
          {/*
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              centered
            >
              <Tab label="Apps Disclosure" />
              <Tab label="Resources" />
              <Tab label="Directory" />
            </Tabs>
          */}
          </Paper>
        </div>

        <div className={classes.emptyRight}></div>
      </div>

      <div className={classes.descriptions}>

        {
          publicInfo && publicInfo.hasOwnProperty('desc1') && publicInfo.desc1 && (
            <Typography
              variant='body1'
              component='h1'
              className={classes.desc1}
            >
            { publicInfo.desc1 }
            </Typography>
          )
        }

        {
          publicInfo && publicInfo.hasOwnProperty('desc2') && publicInfo.desc2  && (
            <Typography
              variant='body1'
              component='h1'
              className={classes.desc2}
            >
            { publicInfo.desc2 }
            </Typography>
          )
        }
      </div>

      {
        (apps && tab === 0) && (
          <Apps
            apps={apps}
            domain={params.domain}
          />
        )
      }

      {
        (resources && tab === 1) && (
          <Resources
            resources={resources}
            domain={params.domain}
            loading={loadingResources}
          />
        )
      }

      {
        (directory && tab === 2) && (
          <Directory
            directory={directory}
            domain={params.domain}
            loading={loadingDirectory}
          />
        )
      }

      <BackDrop loading={loadingApps} />
    </div>
  )
}

export default PublicComponent
