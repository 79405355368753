import React, { useEffect, useState, useContext } from 'react';
import HeaderComponent from './header.component';
import MainContext from '../state/main.context';
import { fade,makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';

import BackDrop from '../backdrop.component';
import ConfirmDialog from '../confirm.dialog.component';
import { firebaseApp } from '../../firebase';

import AppsComponent from './apps.component';
import DomainsComponent from './domains/domains.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    backgroundColor: fade(theme.palette.primary.main,0.15),
    minHeight: '100vh',
    paddingBottom: '5vh'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2)
  },
}));

const ApproveItComponent = props => {
  const classes = useStyles();
  let { history } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    if(!mainState.authUser){
      history.push('/approve-it/login');
      return;
    }

    verifyApproveItUser(mainState.authUser);

  },[]);

  const verifyApproveItUser = async (user) => {

    console.log('verifying super admin...;');

    mainDispatch({ type: 'setLoading', payload: true });

    let options = {
      method: 'Post',
      body: JSON.stringify({
        user: user
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/verifyApproveItUser";

    try {

      let initVerification = await fetch(url,options);

      if(initVerification.ok) {
        let jsonRes = await initVerification.json();
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setIsAdmin', payload: jsonRes.isAdmin });
        return;
      }

      history.push('/home');

    } catch (e) {
      console.dir(e);
      history.push('/home');
    }

  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className={classes.root}>
      <HeaderComponent />

      <div className={classes.paperTabs,'apps-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <Tab label="Apps" className="apps-table-tab" />
            <Tab label="Domains Info" className="domains-table-tab" />
          </Tabs>
        </Paper>
      </div>

      {
        tab === 0 && (
          <AppsComponent />
        )
      }

      {
        tab === 1 && (
          <DomainsComponent />
        )
      }


      <BackDrop loading={mainState.isLoading} />
    </div>
  )
}

export default ApproveItComponent;
