import React, { useEffect, useState, useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

import MainContext from './state/main.context';

const ConfirmDialog = ({ message, toOpen }) => {
  const context = useContext(MainContext);

  const [ loading, setLoading ] = useState(false);
  const [ open, setOpen ] = useState(toOpen);

  useEffect(() => {
    if(toOpen) {
      setOpen(toOpen);
    }
  },[ toOpen ])

  const handleClose = () => {
    setOpen(false);
    context.mainDispatch({ type: 'setConfirm', payload: false });
    context.mainDispatch({ type: 'setConfirmMessage', payload: '' });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
