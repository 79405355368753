import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';
import ConfirmDialog from './confirm.dialog.component';
import PricingInquiryComponent from './inquiry_forms/pricing_inquiry.component';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '1.5em',
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  dialog: {
    padding: theme.spacing(3)
  },
  dialogMessage: {
    margin: theme.spacing(2)
  },
  dialogBtn: {
    margin: theme.spacing(2)
  },
  googleBtn: {
    height: '100px',
    margin: theme.spacing(5),
    cursor: 'pointer'
  }
}))

const PricingComponent = props => {

  useEffect(() => {
    document.getElementById('inquiry').style.display = 'none';
  })

  return (
    <div>

      <CustomHeader title='Pricing' />

      <PricingInquiryComponent />

    </div>
  )
}

export default PricingComponent;
