import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import { getDomainData, getOrgUnits } from '../getters/getDomainData';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		padding: theme.spacing(5)
	},
	importantMessage: {
		fontSize: '1.5em',
		margin: theme.spacing(2)
	},
	important: {
		color: 'red',
		fontWeight: 'bold'
	},
	checkboxes: {
		display: 'flex',
		flexDirection: 'column'
	}
}));

const GoogleSyncComponent = () => {

	const classes = useStyles();
	const context = useContext(MainContext);
  	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);
	const [ delegationComplete, setDelegationComplete ] = useState(false);
	const [ orgUnits, setOrgUnits ] = useState([]);
	const [ selectedUnits, setSelectedUnits ] = useState([]);
	const [ domainData, setDomainData ] = useState(null);
	const [ confirmSync, setConfirmSync ] = useState(false);

	useEffect(() => {
		
		if(!mainState.authUser) {
			return;
		}

		if(mainState.orgUnits) {
			setOrgUnits(mainState.orgUnits);
		}
		
	},[]); // useEffect


	const initGetDomainData = async (domain,adminEmail) => {

	    mainDispatch({ type: 'setLoading', payload: true });

	    let receivedData = await getDomainData(domain,adminEmail);

	    if(!receivedData) {
	      mainDispatch({ type: 'setLoading', payload: false });
	      alert('There was an error trying to retrieve your domain information.');
	      return;
	    }

	    mainDispatch({ type: 'setDomainData', payload: receivedData });

	    setDomainData(receivedData);

	    mainDispatch({ type: 'setLoading', payload: false });

	}

	const initSycnUsers = async () => {

		if(!mainState.authUser) {
			alert('There is no authorized user.  Please log out, log back in and try again.  Or contact support@approveit.app.');
			return;
		}

	    let options = {
	      method: 'POST',
	      body:JSON.stringify({
	        email: mainState.authUser.email,
	        selectedOrgUnits: selectedUnits
	      })
	    }

	    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getOrgUsers";

	    mainDispatch({ type: 'setLoading', payload: true });

	    try {

	      let initSync = await fetch(url,options);
	      let textRes = await initSync.text();

	      mainDispatch({ type: 'setLoading', payload: false });

	      setOrgUnits([]);
	      setSelectedUnits([]);

	      alert(textRes);

	    } catch (e) {
	      console.log(e);
	      mainDispatch({ type: 'setLoading', payload: false });
	      alert("There was an error with Google Sync.  Please contact support@approveit.app or try again later.  We apologize for the inconvenience.");
	    }

	}

	const initGetOrgUnits = async () => {

		// if(!mainState.credentials || !mainState.adminData) {
		// 	alert('You do not have proper credentials.  Please log out, sign back in, and try again.  If the issue persists, please contact support@approveit.app.');
		// 	return;
		// }

		mainDispatch({ type: 'setLoading', payload: true });

		let { email } = mainState.authUser;

		// let units = await getOrgUnits(email,customerId, accessToken);
		let units = await getOrgUnits(email);

		setOrgUnits(units);

	    	
	    mainDispatch({ type: 'setLoading', payload: false });
	    mainDispatch({ type: 'setOrgUnits', payload: units });

		if(units.length <= 0) {
    		alert("Organization units were not found.  Please ensure that you have domain wide delegation configured or try again later.  If the issue persists, please contact support@approveit.app.");
    	}
	    
	}

	return (
		<div>
			<Typography component="h1" className={classes.importantMessage}>
		  		<span className={classes.important}>IMPORTANT! </span> Please ensure that you have setup domain wide delegation before initiating Google Sync.  It will not work if delegation is not properly configured.  See <a href="https://docs.google.com/document/d/e/2PACX-1vTWli82yfCsBOrkbmvXwRhqi5eU0GiD1QZH8N0f0peTy0TrMmJtwaYyQ0sYwZXb4YIU7rCmz2b3gwLO/pub" target="_blank"> instructions here</a>.
		  	</Typography>

		  	<ol>
		  		<li>
		  			<Typography>Select ALL Organizational Units to Sync</Typography>

		  			{
				  		orgUnits.length <= 0 ?
				  			<Button
						  		className={classes.btn}
					            color="secondary"
					            variant="contained"
					            onClick={initGetOrgUnits}
					        >
					          Retrieve Organizational Units
					        </Button>
				  		
				  		:  
				  			<div className={classes.checkboxes}>
				  				{
				  					orgUnits.map(item => 
							  			<FormControlLabel
							  				key={item}
									        control={
									        	<Checkbox 
									        		checked={selectedUnits.includes(item)} 
									        		onChange={() => {
									        			if(selectedUnits.includes(item)) {
									        				let tempUnits = selectedUnits;
									        				tempUnits = tempUnits.filter(unit => unit !== item);
									        				setSelectedUnits([...tempUnits]);
									        			} else {
									        				let tempUnits = selectedUnits;
									        				tempUnits.push(item);
									        				setSelectedUnits([...tempUnits]);
									        			}
									        		}}
									        	/>
									        }
									        label={item}
									     />

							  		)
				  				}
				  			</div>

				  	}

		  		</li>

		  		{
		  			selectedUnits.length > 0 && (
		  				<li>
		  					<Button
						  		className={classes.btn}
					            color="secondary"
					            variant="contained"
					            onClick={() => setConfirmSync(true)}
					        >
					          Initiate Google User Sync
					        </Button>
		  				</li>
		  			)
		  		}
		  	</ol>

			<Dialog
		  		open={confirmSync}
		  		onClose={()=> console.log('dialog closed...')}
		  	>
		  		<DialogTitle>
		  		ATTENTION!!!
		  		</DialogTitle>

		  		<DialogContent>
		  			<DialogContentText>
		  			If this is a re-sync, please be advised that any current user that is in our system that have been suspended or archived on your Google Domain system will be deleted along with the data collected about that user.  If you are concerned about losing user data, we advise that you do the following:
		  			</DialogContentText>
		            <ul>
		              <li>
		                Review any suspended user whose data you would like to preserve in our system.  If this is the case, temporarily enable the account before syncing.
		              </li>
		              <li>
		                Use our <a href="https://docs.google.com/spreadsheets/d/1wLVKbYVMNcXMzlws6EdotUueJOihURXgwbraldpQkV8/edit#gid=0" target="_blank">template</a> to batch upload users if you are concerned about losing user data.
		              </li>
		              <li>
		                Add users individually if there are only a few of them.
		              </li>
		            </ul>
				  		</DialogContent>

					<DialogActions>
						<Button 
						  onClick={() => setConfirmSync(false)} 
						  color="secondary"
						>
						  Cancel Sync
						</Button>
						<Button 
							onClick={() => {
							  setConfirmSync(false);
							  initSycnUsers();
							}} 
							color="primary" 
							variant="contained"
							autoFocus
						>
						  Proceed to Sync Users
						</Button>
					</DialogActions>
		  	</Dialog>
		</div>
	)
}

export default GoogleSyncComponent
