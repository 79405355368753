import { firebaseApp, provider } from '../../../firebase';

const getUserData = async (domain,email) => {
  return new Promise( async (resolve,reject) => {

    firebaseApp.firestore().collection('domains').doc(domain)
      .collection('users').doc(email)
      .onSnapshot(userSnap => {

        if(!userSnap.exists) {
          return resolve(null);
        }

        return resolve(userSnap.data());
      });

  })
}

const getUserCredentials = async () => {
  return new Promise( async (resolve,reject) => {
    provider.addScope("https://www.googleapis.com/auth/admin.directory.orgunit.readonly");
    provider.addScope("https://www.googleapis.com/auth/admin.directory.user.readonly");

    firebaseApp.auth().signInWithPopup(provider).then(function(result) {

      return resolve(result.credential);

    }).catch(function(error) {

      console.dir(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;

      alert(errorMessage);

      return resolve(null);
    });
  });
}

export {
  getUserData,
  getUserCredentials
}