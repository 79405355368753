import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Grid
}  from '@material-ui/core/';
import { firebaseApp, provider } from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';
import HeaderComponent from './header.component';
import BackDrop from '../backdrop.component';
import MainContext from '../state/main.context';
import GoogleBtn from '../images/google_signin_buttons/web/2x/btn_google_signin_dark_focus_web@2x.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    justifyContent: 'center',
    paddingTop: '20vh',
    backgroundColor: fade(theme.palette.primary.main,0.20),
    minHeight: '100vh'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  google_btn: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(15)
  }
}));

const TeacherLoginComponent = props => {
  let { history } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  //  AUTH LISTENER
  useEffect(() => {

    const authListener = firebaseApp.auth().onAuthStateChanged( (user) => {

      setLoading(true);

      if (user) {
        
        mainDispatch({ type: 'setAuthUser', payload: user });
        goToDashboard();

      } else {

        console.log('THERE IS NO USER.');
        setLoading(false);

      }

    });


      return () => authListener();
  }, [])

  //*/

  const goToDashboard = () => {
    setTimeout(() => {
      history.push('/teacher-dashboard');
    }, 500)
  }

  const initSignIn = () => {

    setLoading(true);

    provider.addScope('https://www.googleapis.com/auth/classroom.courses.readonly');
    provider.addScope('https://www.googleapis.com/auth/classroom.rosters.readonly');
    provider.addScope('https://www.googleapis.com/auth/classroom.profile.emails');

    firebaseApp.auth().signInWithPopup(provider).then(function(result) {
      
      mainDispatch({ type: 'setCredentials', payload: result.credential });

    }).catch(function(error) {

      console.dir(error);
      // Handle Errors here.
      setLoading(false);
    });
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <HeaderComponent title="Teacher Dashboard Login" />

      <div className={classes.formContainer}>
        <img
          src={GoogleBtn}
          onClick={initSignIn}
          className={classes.google_btn}
          alt="Google Signin Button"
        />
      </div>

      <BackDrop loading={loading}/>
    </Grid>
  )
}

export default TeacherLoginComponent
