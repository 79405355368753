import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
  Tooltip,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable, { MTableEditField, MTableEditRow, MTableToolbar } from "material-table";
import { firebaseApp } from '../../../firebase';
import MainContext from '../../state/main.context';
import moment from 'moment';
import BackDrop from '../../backdrop.component';
import { RecordActions } from '../admin_actions';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex'
	},
	formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
	requestDetails: {
		display: 'flex',
		padding: theme.spacing(2)
	},
	leftDetailPanel: {
		flexGrow: 1
	},
	detailItem: {
		margin: theme.spacing(1)
	},
	rightDetailPanel: {
		flexGrow: 1
	},
	requestAction: {
		borderBottom: '1px solid lightgrey',
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	}
}));

const AppRequestsComponent = () => {

	const classes = useStyles();
	const context = useContext(MainContext);
  	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);
	const [ requests, setRequests ] = useState([]);

	useEffect(() => {
		
		if(!mainState.domain) {
			return;
		}

		getAppRequests();

	},[]);

	const getAppRequests = async () => {

		try {

			setLoading(true);

			let snapshot = await firebaseApp.firestore().collection('domains').doc(mainState.domain)
				.collection('appRequests').get();

			if(snapshot.docs.length > 0) {
				let tempDocs = snapshot.docs.map(doc => {
					let obj = doc.data();
					obj.id = doc.id;
					obj.requestDate = obj.requestActions[0].timestamp;
					return obj;
				});
				setRequests([...tempDocs]);
			}

		} catch(e) {
			// statements
			console.log(`ERROR: ${ e.message }`);
		} finally {
			setLoading(false);
		}
	}

	const updateCurrentStatus = async (rowData,status) => {

		setLoading(true);

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/updateCurrentStatus";

		let options = {
			method: 'Post',
			body: JSON.stringify({
				domain: mainState.domain,
				requestId: rowData.id,
				currentStatus: status
			})
		}

		try {

			let initFinalized = await fetch(url,options);

			setLoading(false);

			getAppRequests();

		} catch(e) {
			// statements
			console.log(e);
			setLoading(false);
			window.alert('There was an error.  Please try again later.');
		}

	}

	const RequestDetails = app => (
		<div className={classes.requestDetails}>
			<div className={classes.leftDetailPanel}>
				
				<Typography className={classes.detailLabel} variant='h6'>Software Information</Typography>
				<Typography className={classes.detailItem}><b>Company Name:</b> {app.companyName}</Typography>
				<Typography className={classes.detailItem}><b>Software Status:</b> {app.softwareStatus}</Typography>
				<Typography className={classes.detailItem}><b>Site:</b> {app.softwareSite}</Typography>
				<Typography className={classes.detailItem}><b>Phone:</b> {app.vendorPhone}</Typography>
				<Typography className={classes.detailItem}><b>Email:</b> {app.vendorEmail}</Typography>
				<Typography className={classes.detailItem}><b>Anticipated Cost:</b> {app.anticipatedCost}</Typography>
				<Typography className={classes.detailItem}><b>Licensing:</b> {app.licenseType.join(', ')}</Typography>
				<Typography className={classes.detailItem}><b>System Installs:</b> {app.systemInstalls}</Typography>

				<Typography className={classes.detailLabel} variant='h6'>Requestor Information</Typography>
				<Typography className={classes.detailItem}><b>Requestor:</b> {app.requestor}</Typography>
				<Typography className={classes.detailItem}><b>Email:</b> {app.user}</Typography>
				<Typography className={classes.detailItem}><b>Department:</b> {app.department}</Typography>
				<Typography className={classes.detailItem}><b>Course | Team:</b> {app.courseTeam}</Typography>

				<Typography className={classes.detailLabel} variant='h6'>Curriculum/Usage Information</Typography>
				<Typography className={classes.detailItem}><b>District/School/Standards Alignment:</b> <br/>
					{app.standards}
				</Typography>
				<Typography className={classes.detailItem}><b>Usage Description:</b> <br/>
					{app.useDescription}
				</Typography>
				<Typography className={classes.detailItem}><b>Grade Levels:</b> <br/>
					{app.gradeLevels.join(', ')}
				</Typography>


			</div>

			<div className={classes.rightDetailPanel}>
				<Typography variant="h6">Actions</Typography>
				{
					app.requestActions.map(action => <RequestActions key={action.timestamp} {...action} />)
				}
			</div>
		</div>
	)

	const RequestActions = action => (
		<div className={classes.requestAction}>
			<Typography><b>Timestamp:</b> {moment(action.timestamp).format('MMM DD YYYY, h:mm a')} </Typography>
			<Typography><b>Action:</b> {action.actionTaken} </Typography>
			<Typography><b>Recipient:</b> {action.recipient} </Typography>
			<Typography><b>Status:</b> {action.status} </Typography>
			<Typography><b>Comments:</b> {action.comment} </Typography>
		</div>
	)

	const columns = [
		{
			title: 'Request Date',
			field: 'requestDate',
			render: rowData => (
				<Typography>{moment(rowData.requestDate).format('MMM DD YYYY')}</Typography>
			)

		},
		{
			title: 'Date Needed',
			field: 'dateNeeded',
			render: rowData => (
				<Typography>{moment(rowData.dateNeeded).format('MMM DD YYYY')}</Typography>
			)
		},
		{
			title: 'Requested by',
			field: 'requestor'
		},
		{
			title: 'App',
			field: 'softwareName'
		},
		{
			title: 'Status',
			render: rowData => {
				let currentStatus = rowData.hasOwnProperty("currentStatus") ?
														  rowData.currentStatus
														: "Incomplete"
				return (
					<FormControl className={classes.formControl}>
		        <Select
		          labelId="demo-simple-select-label"
		          id="demo-simple-select"
		          value={currentStatus}
		          onChange={(e) => updateCurrentStatus(rowData,e.target.value)}
		        >
		          <MenuItem value="Incomplete">Incomplete</MenuItem>
		          <MenuItem value="Seeking Agreement">Seeking Agreement</MenuItem>
		          <MenuItem value="Awaiting Signature">Awaiting Signature</MenuItem>
		          <MenuItem value="Complete">Complete</MenuItem>
		          <MenuItem value="Approved">Approved</MenuItem>
		          <MenuItem value="Denied">Denied</MenuItem>
		        </Select>
		      </FormControl>				)
			},
			sorting: false
		},
		{
			render: rowData => (
				<Button color='primary'
					onClick={() => window.open(`https://manage-it.app/request-admin/${mainState.domain}/${rowData.id}`)}
				>Take Action</Button>
			)
		}
	];

	const deleteRequest = rowData => {

    setLoading(true);

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('appRequests').doc(rowData.id)
      .delete()
      .then(() => {

        RecordActions(mainState.domain,mainState.authUser.email,`Deleted AppRequest: ${ rowData.softwareName }`);

				getAppRequests();

      })
      .catch(err => {
        console.log('Error deleting app.');
        setLoading(false);
      });

  }

	return (
		<div>

			<MaterialTable
		        title="App Requests"
		        columns={columns}
		        data={requests}
		        style={{
		          width: window.innerWidth * 0.9,
		          border: '1px solid lightgrey',
		          boderRadius: '5px'
		        }}
		        options={{
		          pageSize: 50,
		          headerStyle:{
		            backgroundColor: '#66549a',
		            color: '#ffffff'
		          },
		          exportButton: true,
		          exportAllData: true
		        }}
		        detailPanel={ rowData => {
		          return (<RequestDetails {...rowData} />)
		        }}
		        actions={[
		          {
		            icon: () =>
		            <DeleteIcon
		              color='secondary'
		            />,
		            tooltip: 'Delete',
		            onClick: (event, rowData) => {
		              let c = window.confirm('Are you sure?');
		              if(c){
		                deleteRequest(rowData);
		              }
		            }
		          }
		        ]}
						//onRowClick={(event, rowData, togglePanel) => togglePanel()}
		      />

			<BackDrop loading={loading} />
		</div>
	)
}

export default AppRequestsComponent
