import React, { useEffect, useState, useContext } from 'react';
import MainContext from '../../state/main.context';
import { fade,makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BackDrop from '../../backdrop.component';
import MaterialTable, { MTableEditField, MTableToolbar } from "material-table";
import { firebaseApp } from '../../../firebase';
import DomainDetailsComponent from './domains.details.component';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center'
  },
  imgUrl: {
    maxHeight: 80,
    maxWidth: 80
  },
  editField: {
    width: 200,
    height: 100
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  url: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
}));

const DomainsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ allDomains, setAllDomains ] = useState([]);

  useEffect(() => {

    if(!mainState.authUser) {
      return;
    }

    if(mainState.allDomains.length <= 0) {
      getAllDomainsAndAdmins();
      return;
    }

    setAllDomains(mainState.allDomains);

  },[]);

  const getAllDomainsAndAdmins = async () => {
    mainDispatch({ type: 'setLoading', payload: true });

    let initGetData = await firebaseApp.firestore().collection('userRefs').get();

    if(initGetData.docs.length <= 0) {
      alert('Unable to get domains');
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    let uniqueDomains = [];

    initGetData.docs.forEach(item => {
      let domain = item.data().domain;

      if(uniqueDomains.length <= 0) {
        uniqueDomains.push({
          domain: domain
        })
      } else {

        let exists = false;

        uniqueDomains.some(item => {
          if(item.domain == domain) {
            exists = true;
            return true;
          }
          return false;
        });

        if(!exists) {
          uniqueDomains.push({
            domain: domain
          });
        }

      }

    });

    mainDispatch({ type: 'setAllDomains', payload: uniqueDomains });
    setAllDomains(uniqueDomains);
    mainDispatch({ type: 'setLoading', payload: false });

  }

  const columns = [
    {
      title: 'Domain',
      field: 'domain'
    }
  ];


  return (
    <div className={classes.root}>

      <MaterialTable
        title=""
        columns={columns}
        data={allDomains}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        detailPanel={rowData => <DomainDetailsComponent domain={rowData.domain} />}
      />

      <BackDrop loading={mainState.isLoading} />
    </div>
  )
}

export default DomainsComponent
