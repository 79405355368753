import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  CardActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import moment from 'moment';
import { firebaseApp } from '../../../firebase';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	    display: 'flex',
	    flexDirection: 'column',
	    justifyContent: 'center',
	    minWidth: 500
	},
	card: {
		minWidth: 500,
		maxWidth: 650,
		margin: theme.spacing(2),
		wordWrap: 'break-word'
	},
	link: {
		margin: theme.spacing(1)
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(1)
	},
	checkboxes: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
	},
	textField: {
		margin: theme.spacing(1)
	},
	actions: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	deleteIcon: {
		color: 'red'
	},
	links: {
		margin: theme.spacing(1)
	}
}));

const AnnouncementCard = ({ announcement, updateAnnouncements, deleteScheduledEvent }) => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ edit, setEdit ] = useState(false);

	// FORM ITEMS
	const [ title, setTitle ] = useState(announcement.title);
	const [ desc, setDesc ] = useState(announcement.desc);
	const [ url1, setUrl1 ] = useState(announcement.url1);
	const [ url2, setUrl2 ] = useState(announcement.url2);
	const [ url3, setUrl3 ] = useState(announcement.url3);
	const [ announcementDate, setAnnouncementDate ] = useState(new Date(announcement.announcementDate));
	const [ groups, setGroups ] = useState(announcement.groups);

	const handleDateChange = (date) => {
    	setAnnouncementDate(date);
    };

	const updateAnnouncement = async (e) => {

		mainDispatch({ type: 'setLoading', payload: true });

		e.preventDefault();
		e.persist();

		var formObj = {};
		formObj.title = title;
		formObj.desc = desc;
		formObj.url1 = url1;
		formObj.url2 = url2;
		formObj.url3 = url3;
		formObj.announcementDate = moment(announcementDate).format('MM/DD/YYYY');
		formObj.groups = groups;
		formObj.updatedOn = Date.now();
		formObj.updatedBy = mainState.authUser.email;

		await firebaseApp.firestore().collection('domains').doc(mainState.domainData.domain)
				.collection('scheduledAnnouncements').doc(announcement.id)
				.update(formObj);

		e.target.reset();
		updateAnnouncements();
		setEdit(false);
	}

	const handleUrl = e => {
		e.persist();
		let id = e.target.id;
		switch(id) {
			case 'announcement-url1':
				setUrl1(e.target.value);
				break;

			case 'announcement-url2':
				setUrl2(e.target.value);
				break;

			case 'announcement-url3':
				setUrl3(e.target.value);
				break;
		}
	}

	const handleDescPaste = e => {
		e.persist();
		setDesc(e.target.value);
	}

	const handleTitlePaste = e => {
		e.persist();
		setTitle(e.target.value);
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Card className={classes.card}>
				<CardHeader
					action={
						<IconButton
							onClick={() => setEdit(!edit)}
						>
							<EditIcon />
						</IconButton>
					}
					title={announcement.title}
					subheader={announcement.announcementDate}
				/>

				{
					edit ?

					<CardContent>
						<form 
							onSubmit={updateAnnouncement}
							className={classes.form}
						>
							<TextField 
								label="Title"
								id='announcement-title'
								onChange={e => setTitle(e.target.value)}
								onPaste={handleTitlePaste}
								placeholder={announcement.title}
								InputLabelProps={{
					            	shrink: true,
					          	}}
					          	className={classes.textField}
							/>
							<TextField 
								label="Description"
								id='announcement-desc'
								multiline
								rows={5}
								onChange={e => setDesc(e.target.value)}
								onPaste={handleDescPaste}
								placeholder={announcement.desc}
								InputLabelProps={{
					            	shrink: true,
					          	}}
					          	className={classes.textField}
							/>
							<TextField
								label="Resource URL 1 (optional)"
								id='announcement-url1'
								onPaste={handleUrl}
								onChange= {e => handleUrl(e)}
								placeholder={announcement.url1}
								InputLabelProps={{
					            	shrink: true,
					          	}}
					          	className={classes.textField}
							/>
							<TextField 
								label="Resource URL 2 (optional)"
								id='announcement-url2'
								onPaste={handleUrl}
								onChange= {e => handleUrl(e)}
								placeholder={announcement.url2}
								InputLabelProps={{
					            	shrink: true,
					          	}}
					          	className={classes.textField}
							/>
							<TextField 
								label="Resource URL 3 (optional)"
								id='announcement-url3'
								onPaste={handleUrl}
								onChange= {e => handleUrl(e)}
								placeholder={announcement.url3}
								InputLabelProps={{
					            	shrink: true,
					          	}}
					          	className={classes.textField}
							/>
							<KeyboardDatePicker
				        disableToolbar
				        variant="inline"
				        format="MM/dd/yyyy"
				        margin="normal"
				        id="announcement-date"
				        label="Announcement Date"
				        value={announcementDate}
				        onChange={handleDateChange}
				        KeyboardButtonProps={{
				        	'aria-label': 'change date',
				        }}
				        InputLabelProps={{
			            	shrink: true,
			          	}}
			        />
							
							<FormLabel>
								Update intended group recipient(s).
							</FormLabel>
			        <FormGroup className={classes.checkboxes}>
		            {
		              (mainState && mainState.hasOwnProperty('domainData') && 
		              	mainState.domainData &&
		              	mainState.domainData.hasOwnProperty('orgUnits') && 
		              	mainState.domainData.orgUnits.length > 0)
		                
		                ?  mainState.domainData.orgUnits.map(ou => 

		                    <FormControlLabel
		                      key={ou} 
		                      control={
		                        <Checkbox  
		                          checked={groups.includes(ou)}
		                          onChange={(e) => {
		                            if(groups.includes(ou)){
		                            	let tempGroups = groups.filter(i => i !== ou);
		                            	setGroups(tempGroups);
		                            } else {
		                            	setGroups([...groups,ou]);
		                            }
		                          }}
		                        />
		                      } 
		                      label={ou} 
		                    />

		                  )

		                : <p>You do not have any organizational units listed.  Please ensure that you run Google Sync with our system at least one time.</p>
		            }
		          </FormGroup>

			        <Button
			        	type='submit'
			        	variant='contained'
			        	color='primary'
			        	className={classes.submitBtn}
			        	disabled={ !title || !desc || !announcementDate }
			        >
			        	Update Announcement
			        </Button>
						</form> 
					</CardContent>

					: <CardContent>
							<Typography
								component="h1"
								variant="h6"
							>Message</Typography>

							<Typography>
								{announcement.desc}
							</Typography>

							<Typography
								component="h1"
								variant="h6"
							>Linked Resources</Typography>

							<Typography className={classes.links}>
								{
									announcement.url1 ?
									<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url1} 
												target="_blank">Resource Link 1</a>
									</React.Fragment>
									: ""
								}
							</Typography>

							<Typography className={classes.links}>
								{
									announcement.url2 ?
										<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url2} 
												target="_blank">Resource Link 2</a>
										</React.Fragment>
									: ""
								}
							</Typography>

							<Typography className={classes.links}>
								{
									announcement.url3 ?
										<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url3} 
												target="_blank">Resource Link 3</a>
										</React.Fragment>
									: ""
								}
							</Typography>

							<Typography
								component="h1"
								variant="h6"
							>Recipient Group(s)</Typography>

							<Typography>
								{announcement.groups.join(', ')}
							</Typography>

							<Typography className={classes.link}>
								<b>Submitted By:</b> {announcement.submittedBy} on {moment(new Date(announcement.createdAt)).format('ddd, MMM Do YYYY')}
							</Typography>

							
							{  //  IF THE EVENT WAS UPDATED BY ANYONE
								(announcement.hasOwnProperty('updatedOn') && 
								announcement.hasOwnProperty('updatedBy')) ?
									<Typography className={classes.link}>
									<b>Updated By:</b> {announcement.updatedBy} on {moment(new Date(announcement.updatedOn)).format('ddd, MMM Do YYYY')}
									</Typography>
								: null
							}
							

						</CardContent>
				}

				<CardActions disableSpacing
					className={classes.actions}
				>	<IconButton
						onClick={() => deleteScheduledEvent(announcement.id,announcement.title)}
					>
						<DeleteIcon className={classes.deleteIcon} />
					</IconButton>
				</CardActions>

			</Card>
		</MuiPickersUtilsProvider>
	)
}

const ScheduledAnnouncementsComponent = () => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ scheduledAnnouncements, setAnnouncements ] =  useState([]);

	useEffect(() => {
		mainDispatch({ type: 'setLoading', payload: true });
		initGetAnnouncements();
	},[]);

	const initGetAnnouncements = async () => {
		let snapshot = await firebaseApp.firestore()
			.collection('domains').doc(mainState.domainData.domain)
			.collection('scheduledAnnouncements')
			.get();

		if(snapshot.docs.length <= 0) {
			mainDispatch({ type: 'setLoading', payload: false });
			setAnnouncements([]);
			return;
		}

		let tempArr = snapshot.docs.map(each => {
		let data = each.data();
		data.id = each.id;
		data.milli = new Date(data.announcementDate).getTime();
			return data;
		});

		tempArr.sort((a,b) => b.createdAt - a.createdAt);

		setAnnouncements(tempArr);
		mainDispatch({ type: 'setLoading', payload: false });
	}

	const deleteScheduledEvent = async (id,title) => {

		mainDispatch({ type: 'setLoading', payload: true });

		let snapshot = await firebaseApp.firestore()
			.collection('domains').doc(mainState.domainData.domain)
			.collection('scheduledAnnouncements')
			.doc(id).delete();

		initGetAnnouncements();

		RecordActions(mainState.domain,mainState.authUser.email,`Deleted announcement ${title}`);

	}



	if(scheduledAnnouncements.length <= 0 ) {
		return (
			<div className={classes.root}>
				<Typography
					variant="h6"
					component="h1"
					align="center"
				>There are no scheduled announcements.</Typography>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{
				scheduledAnnouncements.map(announcement => 
					<AnnouncementCard 
						key={announcement.id} 
						announcement={announcement}
						updateAnnouncements={initGetAnnouncements}
						deleteScheduledEvent={deleteScheduledEvent}
					/>
				)
			}
		</div>
	)
}

export default ScheduledAnnouncementsComponent
