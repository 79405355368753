export const initialState = {
  isLoading: false,
  authUser: null,
  userType: null,
  confirm: false,
  confirmMessage: '',
  groups: [],
  domain: null,
  subscription: 'basic',
  apps: [],
  isAdmin: false,
  credentials: null,
  classroomKey: null,
  appInsights: null,
  chartData: null,
  domainData: null,
  userData: null,
  adminData: null,
  orgUnits: null,
  domainUsers: null,
  //  APPROVE-IT GLOBALS
  allDomains: [],
  termsPrivacyAgreement: false
}

export const mainReducer = (state,action) => {

  switch(action.type) {

    case 'setChartData':
      return {
        ...state,
        chartData: action.payload
      }

    case 'setShowTermsPrivacyAgreement':
      return {
        ...state,
        termsPrivacyAgreement: action.payload
      }

    case 'setAllDomains':
      return {
        ...state,
        allDomains: action.payload
      }

    case 'setDomainUsers':
      return {
        ...state,
        domainUsers: action.payload
      }

    case 'setOrgUnits':
    
      return {
        ...state,
        orgUnits: action.payload
      }

    case 'setAdminData':
      return {
        ...state,
        adminData: action.payload
      }

    case 'setDomainData':
      return {
        ...state,
        domainData: action.payload
      }

    case 'setUserData':
      return {
        ...state,
        userData: action.payload
      }

    case 'setAppInsights':
      return {
        ...state,
        appInsights: action.payload
      }

    case 'setApps':
      return {
        ...state,
        apps: [...action.payload]
      }

    case 'setIsAdmin':
      return {
        ...state,
        isAdmin: action.payload
      }

    case 'setDomain':
      // let tempArr = action.payload.split('.');
      // let domain = tempArr[tempArr.length -2] + '.' + tempArr[tempArr.length - 1]
      return {
        ...state,
        domain: action.payload
      }

    case 'setLoading':
      return {
        ...state,
        isLoading: action.payload
      }

    case 'setConfirm':
      return {
        ...state,
        confirm: action.payload
      }

    case 'setConfirmMessage':
      return {
        ...state,
        confirmMessage: action.payload
      }

    case 'setAuthUser':
      return {
        ...state,
        authUser: action.payload
      }

    case 'setUserType':
      return {
        ...state,
        userType: action.payload
      }

    case 'setSubscription':
      return {
        ...state,
        subscription: action.payload
      }

    case 'setGroups':
      return {
        ...state,
        groups: action.payload
      }

    case 'setAdminUsers':
      return {
        ...state,
        adminUsers: action.payload
      }

    case 'reset':
      return {
        ...initialState
      }

    case 'setCredentials':
      return {
        ...state,
        credentials: action.payload
      }

    case 'setClassroomKey':
      return {
        ...state,
        classroomKey: action.payload
      }

    default:
      return;

  }

}
