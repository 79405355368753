import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Typography
} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import CalendarHeatmap from 'react-calendar-heatmap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import '../../css/heat-map.css';
import { functions } from '../../../firebase';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex'
	},
	heatCont: {
		minWidth: '100%'
	},
	heatmapRoot: {
    position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 750,
		margin: theme.spacing(2),
		padding: theme.spacing(2)
	},
	heatmap: {
		maxWidth: 350,
		minWidth: 250
	},
	loadMoreContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(1)
	},
	loadMore: {
		width: 200
	},
	selectContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	formControl: {
		minWidth: 100,
		margin: theme.spacing(1)
	},
	form: {
		display: 'flex'
	},
	calendarLabel: {
		margin: theme.spacing(1),
		fontSize: '1.5em'
	},
  loadingText: {
    textAlign: 'center',
    margin: theme.spacing(1)
  }
}));

const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
const currentYear = moment().format('YYYY');
const currentMonth = new Date().getMonth();
const startDate = moment().startOf('month').subtract(10,'days').format('YYYY-MM-DD');
const endDate = moment().endOf('month').add(15,'days').format('YYYY-MM-DD');
const previousYear = moment().subtract(1,'year').format('YYYY');

const HeatMapComponent = ({ id, domain, dispatch, email }) => {

  const classes = useStyles();
  const [ data, setData ] = useState([]);
  const [ monthTotal, setMonthTotal ] = useState(0);
  const [ loading, setLoading ] = useState(false);
  const [ selectedYear, setSelectedYear ] = useState(currentYear);
  const [ selectedMonth, setSelectedMonth ] = useState(months[currentMonth]);
  const [ monthLabel, setMonthLabel ] = useState(months[currentMonth]);
  const [ yearLabel, setYearLabel ] = useState(selectedYear);
  const [ availableYears, setAvailableYears ] = useState([ currentYear, previousYear ]);
  const [ heatMapStartDate, setStartDate ] = useState(startDate);
  const [ heatMapEndDate, setEndDate ] = useState(endDate);
  const [ btnDisabled, setBtnDisabled ] = useState(true);

  useEffect(() => {
    getTimeVisits();
  },[]);

  const getTimeVisits = async (month=months.indexOf(selectedMonth) + 1,year=selectedYear) =>
   {

    // dispatch({ type: 'setLoading', payload: true });
    setLoading(true);

    //  MAKE SURE THAT MONTH AND YEAR ARE INT TYPES
    month = Number(month);
    year = Number(year);
    
    let options = {
      method: 'Post',
      body: JSON.stringify({
        email: email,
        websiteDomain: id,
        year: year,
        month: month
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getWebsiteVisits";

    try {

      let fetchData = await fetch(url,options);

      if(fetchData.ok) {
        let json = await fetchData.json();
        let tempMonthTotal = json.reduce((acc,current) => {
          acc += current.count;
          return acc;
        },0);
        setData(json);
        setMonthTotal(tempMonthTotal);
      }
      setMonthLabel(selectedMonth);
      setYearLabel(selectedYear);
      // dispatch({ type: 'setLoading', payload: false });  
      setLoading(false);
    } catch(e) {
      // statements
      console.log(e);
      setMonthLabel(selectedMonth);
      setYearLabel(selectedYear);
      setData([]);
      setMonthTotal(0);
      // dispatch({ type: 'setLoading', payload: false });
      setLoading(false);
    }
  }

  const handleYearChange = e => {
    let tempYear = e.target.value;
    if(tempYear == selectedYear) {
      return;
    }
    setSelectedYear(tempYear);
    setBtnDisabled(false);
  }

  const handleMonthChange = e => {
    let tempMonth = e.target.value;
    if(tempMonth == selectedMonth) {
      return;
    }
    setSelectedMonth(tempMonth);
    setBtnDisabled(false);
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    e.persist();

    const monthNum = months.indexOf(selectedMonth) + 1;

    const tempDate = moment(new Date(selectedYear + "-0" + monthNum + '-05').toISOString());

    let tempStarDate = moment(tempDate).clone().startOf('month').subtract(10,'days').format('YYYY-MM-DD');
    let tempEndDate = moment(tempDate).clone().endOf('month').add(15,'days').format('YYYY-MM-DD');

    setStartDate(tempStarDate);
    setEndDate(tempEndDate);

    getTimeVisits(monthNum,selectedYear);

    setBtnDisabled(true);

  }

  return (
    <div className={classes.heatmapRoot}>
      <form 
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <FormControl className={classes.formControl}>
          <InputLabel>Select Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={handleYearChange}
          >
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            <MenuItem value={previousYear}>{previousYear}</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>Select Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {
              months.map((month) => 
                <MenuItem value={month} key={month}>{month}</MenuItem>
              )
            }
          </Select>
        </FormControl>
        <Button color="primary" disabled={btnDisabled} type='submit'>Submit</Button>
      </form>   

      {
          loading && (
            <Typography className={classes.loadingText}>Loading...</Typography>
          )
        }

      <div className={classes.heatmap}>
        <Typography
          align='center'
          component='h1'
          className={classes.calendarLabel}
        >{monthLabel} {yearLabel}</Typography>
        <CalendarHeatmap
          showMonthLabels={false}
          startDate={heatMapStartDate}
          endDate={heatMapEndDate}
          values={data}
          classForValue={value => {
            if (!value) {
              return 'color-empty';
            }
            return `color-github-${value.count}`;
          }}
          tooltipDataAttrs={value => {

            if(!value.date) {
              return {
                'data-tip': 'No data.'
              }
            } else {
              return {
                'data-tip': `${value.date} | Visits: ${value.count}`
              }
            }

          }}
          showWeekdayLabels={true}
        />
        <ReactTooltip
          effect='solid'
          delayHide={500}
          delayShow={500}
          delayUpdate={500}
          place={'right'}
          border={true}
        />

        <Typography variant="h6"><b>Total Month Visits:</b> {monthTotal}</Typography>
      </div>
    </div>
  )
}

export default HeatMapComponent;
