import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { firebaseApp } from '../../../firebase';
import { getUserData } from '../getters/getUserData';
import { getDomainData } from '../getters/getDomainData';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import moment from 'moment';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    width: '90vw',
    minHeight: '100vh',
    borderRadius: theme.spacing(3)
  },
  divConts: {
    flexGrow: 1,
    justifySelf: 'center',
    // minWidth: 450,
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 450
    }
  },
  heatMapCont: {
    // flexGrow: 1,
    minWidth: '100%',
    margin: theme.spacing(2)
  },
  titleIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  title: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(2)
  },
  currentInfo: {
    margin: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(2)
  },
  menuBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(1)
  },
  imgCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  img: {
    height: 200,
    width: 200
  },
  heatmap: {
    minWidth: 800,
    margin: theme.spacing(2)
  },
  configTour: {
    width: 1000
  }
}));

const ProfileComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ domainData, setDomainData ] = useState({
    orgName: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    domains: [],
    defaultGroup: '',
    orgUnits: []
  });

  const [ userData, setUserData ] = useState({});
  const [ userFormDisabled, setUserDisabled ] = useState(true);
  const [ helpers, setHelpers ] = useState(null);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    if(!mainState.domainData) {
      initGetDomainData(mainState.domain,mainState.authUser.email);
    } else {

      setDomainData(mainState.domainData);

      mainDispatch({ type: 'setLoading', payload: false });
    }

    if(!mainState.userData) {
      initGetUserData(mainState.domain,mainState.authUser.email);
    } else {
      setUserData(mainState.userData);
      mainDispatch({ type: 'setLoading', payload: false });
    }

  },[]);

  const initGetDomainData = async (domain,adminEmail) => {

    mainDispatch({ type: 'setLoading', payload: true });

    let receivedData = await getDomainData(domain,adminEmail);

    if(!receivedData) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setDomainData', payload: receivedData });
 
    setDomainData(receivedData);

    mainDispatch({ type: 'setLoading', payload: false });

  }

  const initGetUserData = async (domain,email) => {

    mainDispatch({ type: 'setLoading', payload: true });


    let receivedData = await getUserData(domain,email);

    if(!receivedData) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    setUserData(receivedData);
    mainDispatch({ type: 'setLoading', payload: false });

  }

  const updateUserKey = _.debounce((key,val) => {

    let tempData = userData;

    tempData[key] = val;
    tempData.displayName = tempData.firstName + " " + tempData.lastName;

    setUserData(tempData);

  },500);

  const updateProfile = e => {

    e.preventDefault();

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .collection('users').doc(userData.email)
      .set(userData)
      .then(() => {
        initGetUserData(domainData.domain,mainState.authUser.email);
        setUserDisabled(true);
        RecordActions(domainData.domain,mainState.authUser.email,"Updated Their Own Profile Information");
      })
      .catch(err => {
        mainDispatch({ type:'setLoading', payload: false });
        setUserDisabled(true);
      })

  }


  return (
    <div className={`${classes.root} config-root`}>

    {/*  User Profile  */}
    <Paper className={`${classes.divConts}`} elevation={3}>

      <form className={classes.form} onSubmit={updateProfile}>

      <div className={classes.titleIcon}>

        <Typography
          variant="h6"
          align="center"
          className={classes.title}
        >User Profile</Typography>

        <EditIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => setUserDisabled(!userFormDisabled)}
        />

      </div>

        {
          userFormDisabled ? null

          : <TextField
              className={classes.textField}
              label="First Name"
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.firstName : '' 
              }
              onChange={e => {
                updateUserKey('firstName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ? null

          : <TextField
              className={classes.textField}
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.lastName : '' 
              }
              label="Last Name"
              onChange={e => {
                updateUserKey('lastName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.displayName : '' }
            </Typography>

          : null
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.position : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Position"
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.position : '' 
              }
              onChange={e => {
                updateUserKey('position',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.phone : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Phone"
              placeholder={ Object.keys(userData).length > 0 ? userData.phone : '' }
              onChange={e => {
                updateUserKey('phone',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }

      </form>

    </Paper>

    </div>
  )
}

export default ProfileComponent;
