import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeaderComponent from '../header.component';
import BackDrop from '../../backdrop.component';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '10vh'
	},
	requestStatusContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	actions: {
		border: '1px solid grey',
		borderRadius: 5,
		padding: theme.spacing(2),
		maxWidth: 700,
		minWidth: 500,
		alignSelf: 'center'
	},
	actionsLabel: {
		textAlign: 'center',
		fontWeight: 'bold'
	},
	request: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		maxWidth: 750,
		alignSelf: 'center'
	},
	notFound: {
		textAlign: 'center',
		marginTop: '25vh'
	}
}));

const RequestStatusComponent = () => {

	const params = useParams();
  	let { domain, requestId } = params;
	const classes = useStyles();
	const [ loading, setLoading ] = useState(true);
	const [ requestInfo, setRequestInfo ] = useState(null);
	const [ notFound, setNotFound ] = useState(false);
	const [ currentStatus, setCurrentStatus ] = useState('Incomplete');

	useEffect(() => {
		document.getElementById('inquiry').style.display = 'none';
		getRequestInfo();
	},[]);

	const getRequestInfo = async () => {
		let options = {
			method: 'Post',
			body: JSON.stringify({
				domain: domain,
				requestId: requestId
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getAppRequestStatus";

		try {
			
			let initGetStatus = await fetch(url,options);

			if(initGetStatus.ok) {
				let json = await initGetStatus.json();
				json.requestActions.sort((a,b) => a.timestamp - b.timestamp);
				if(json.hasOwnProperty('currentStatus')) {
					setCurrentStatus(json.currentStatus);
				}
				setRequestInfo(json);
				setLoading(false);
				return;
			}

			setLoading(false);
			setNotFound(true);

		} catch(e) {

			// statements
			console.log(e.message);
			setLoading(false);
		}

	}

	return (
		<div className={classes.root}>
			<HeaderComponent title={ domain.toUpperCase() + " | App Request Status" || "" } />

			{
				requestInfo && (

					<div className={classes.requestStatusContainer}>

						{
							requestInfo.requestActions.length > 0 && (
								<div className={classes.actions}>
									<Typography 
										className={classes.actionsLabel}
									>
										ACTIONS TAKEN
									</Typography>
								{
									requestInfo.requestActions.map(action => (
											<div key={action.timestamp}>
												<Typography><b>Status:</b> { action.status }</Typography>
												<Typography>
													<b>Action Taken:</b> { action.actionTaken }
												</Typography>
												<Typography>
													<b>Comments:</b> { action.comment }
												</Typography>
												<Typography>
													<b>Timestamp:</b> { moment(action.timestamp).format("MMM Do YYYY, h:mm a") }
												</Typography>
												<hr />
											</div>
										)
									)
								}
								</div>
							)
						}

						<div className={classes.request}>
							 <Typography align='center' variant='h6'>Request Information</Typography>
							 <Typography>
								<b>Current Status:</b> { currentStatus }
							 </Typography>
							 <Typography>
							 	<b>Software/Web Service:</b> { requestInfo.softwareName }
							 </Typography>
							 <Typography>
							 	<b>Company:</b> { requestInfo.companyName }
							 </Typography>
							 <Typography>
							 	<b>Vendor Phone:</b> { requestInfo.vendorPhone || 'NA' }
							 </Typography>
							 <Typography>
							 	<b>Vendor Email:</b> { requestInfo.vendorEmail || 'NA' }
							 </Typography>
							 <Typography>
							 	<b>Purchase Type:</b> { requestInfo.softwareStatus }
							 </Typography>
							 <Typography>
							 	<b>Type of License(s):</b> { requestInfo.licenseType.join(', ') }
							 </Typography>
							 <Typography>
							 	<b>Anticipated Cost:</b> { requestInfo.anticipatedCost || 'NA' }
							 </Typography>
							 <Typography>
							 	<b>Software Website:</b> { requestInfo.softwareSite || 'NA' }
							 </Typography>
							 <Typography>
							 	<b>Date Needed:</b> { requestInfo.dateNeeded }
							 </Typography>
							 <Typography>
							 	<b>Anticipated Users:</b> { requestInfo.users }
							 </Typography>
							 <Typography>
							 	<b>Software Installations:</b> { requestInfo.softwareInstalls }
							 </Typography>
							 <Typography>
							 	<b>Curriculum Standars:</b> { requestInfo.standards }
							 </Typography>
							 <Typography>
							 	<b>Software Use:</b> { requestInfo.useDescription }
							 </Typography>
							 <Typography>
							 	<b>Requestor:</b> { requestInfo.requestor }
							 </Typography>
							 <Typography>
							 	<b>Department:</b> { requestInfo.department }
							 </Typography>
							 <Typography>
							 	<b>Department (Other):</b> { requestInfo.otherDepartment }
							 </Typography>
							 <Typography>
							 	<b>Course Team:</b> { requestInfo.courseTeam }
							 </Typography>
							 <Typography>
							 	<b>Grade Level(s):</b> { requestInfo.gradeLevels.join(', ') }
							 </Typography>
						</div>

					</div>

				)
			}

			{  //  REQUEST NOT FOUND
				notFound && (
					<Typography variant="h6" className={classes.notFound}>
					Request Information was not found.  Please contact your administrator.
					</Typography>
				)
			}

			<BackDrop loading={loading} />
		</div>
	)
}

export default RequestStatusComponent
