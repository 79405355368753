import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minWidth: 700,
		minHeight: 300
	},
	textField: {
		margin: theme.spacing(1)
	},
	listItem: {
		backgroundColor: 'lightgrey',
		borderRadius: 5,
		minWidth: 400,
		maxWidth: 500,
		margin: theme.spacing(1)
	},
	addTeacherContainer: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 450,
		marginTop: theme.spacing(2),
		border: '1px solid lightgrey',
		borderRadius: 10,
		padding: theme.spacing(1)
	}
}));

const CourseTeachersComponent = ({ teachers, courseId, addTeacher, removeTeacher, ownerId }) => {

	const classes = useStyles();
	const [ loading, setLoading ] = useState(false);
	const [ teacherToAddEmail, setTeacherToAdd ] = useState(null);

	useEffect(() => {},[]);

	const initAddTeacher = () => {
		if(!teacherToAddEmail) {
			return;
		}

		addTeacher(teacherToAddEmail);

		document.getElementById('add-teacher-field').value = "";
	}

	return (
		<Paper className={classes.root}>

			<List>
				{
					teachers.length > 0 && (
						teachers.map(teacher => 
							<ListItem 
								key={teacher.userId}
								className={classes.listItem}
							>
								<ListItemAvatar>
									<Avatar alt={teacher.profile.name.fullName} src={teacher.profile.photoUrl} />
								</ListItemAvatar>

								<ListItemText
									primary={teacher.profile.name.fullName}
								/>

								<ListItemSecondaryAction>
									<Button
										disabled={teacher.userId === ownerId}
										onClick={() => {
											let prompt = window.prompt("Are you sure? (Enter yes or cancel)");
											if(prompt && prompt.toLowerCase() == 'yes') {
												removeTeacher(teacher.profile.emailAddress);
											}
										}}
									>remove</Button>
								</ListItemSecondaryAction>
							</ListItem>
						)
					)
				}
			</List>


			<div className={classes.addTeacherContainer}>
				<Typography align="center">Add a Teacher</Typography>
				<TextField
					onChange={e => setTeacherToAdd(e.target.value)}
					label="Enter Email"
					className={classes.textField}
					id="add-teacher-field"
				/>

				<Button variant="contained" color="primary"
					onClick={initAddTeacher}
				>Submit</Button>
			</div>

		</Paper>
	)
}

export default CourseTeachersComponent
