import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeaderComponent from '../header.component';
import BackDrop from '../../backdrop.component';
import moment from 'moment';
import MainContext from '../../state/main.context';
import { SignOut } from '../../utilities/firebase.actions';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '10vh',
		marginBottom: '10vh'
	},
	requestStatusContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	actionsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center'
	},
	actions: {
		border: '1px solid grey',
		borderRadius: 5,
		padding: theme.spacing(2),
		margin: theme.spacing(1),
		maxWidth: 400,
		minWidth: 400
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid grey',
		borderRadius: 5,
		padding: theme.spacing(2),
		margin: theme.spacing(1),
		maxWidth: 400,
		minWidth: 400
	},
	formItem: {
		marginBottom: theme.spacing(2)
	},
	actionsLabel: {
		textAlign: 'center',
		fontWeight: 'bold'
	},
	request: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(2),
		maxWidth: 750,
		alignSelf: 'center'
	},
	notFound: {
		textAlign: 'center',
		marginTop: '25vh'
	}
}));

const RequestAdminComponent = props => {

	const context = useContext(MainContext);
  	let { mainState, mainDispatch } = context;
	const params = useParams();
  	let { domain, requestId } = params;
	const classes = useStyles();
	const [ loading, setLoading ] = useState(true);
	const [ requestInfo, setRequestInfo ] = useState(null);
	const [ notFound, setNotFound ] = useState(false);

	//  FORM
	const [ status, setStatus ] = useState('');
	const [ comment, setComment ] = useState('');
	const [ recipient, setRecipient ] = useState('');

	const [ currentStatus, setCurrentStatus ] = useState('Incomplete');
	const [ statusUpdated, setStatusUpdated ] = useState(false);

	useEffect(() => {
		if(!mainState.authUser) {
			return props.history.push(`/login?redirect=request-admin/${domain}/${requestId}`);
		}
		getRequestInfo();
	},[]);

	const getRequestInfo = async () => {
		let options = {
			method: 'Post',
			body: JSON.stringify({
				domain: domain,
				requestId: requestId
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getAppRequestStatus";

		try {
			
			let initGetStatus = await fetch(url,options);

			if(initGetStatus.ok) {
				let json = await initGetStatus.json();
				json.requestActions.sort((a,b) => a.timestamp - b.timestamp);
				if(json.hasOwnProperty('currentStatus')) {
					setCurrentStatus(json.currentStatus);
				}
				setRequestInfo(json);
				setLoading(false);
				return;
			}

			setLoading(false);
			setNotFound(true);

		} catch(e) {

			// statements
			console.log(e.message);
			setLoading(false);
		}

	}

	const handleActionSubmit = async (e) => {
		e.preventDefault();
		e.persist();

		setLoading(true);

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/updateAppRequestStatus";
		// let url = "http://localhost:5000/manage-it-extension/us-central1/updateAppRequestStatus";

		let options = {
			method: 'Post',
			body: JSON.stringify({
				requestInfo: requestInfo,
				domain: domain,
				requestId: requestId,
				recipient: recipient,
				comment: comment,
				status: status,
				adminEmail: mainState.authUser.email
			})
		}

		console.dir(JSON.parse(options.body));

		try {

			let initUpdate = await fetch(url, options);

			e.target.reset();
			getRequestInfo();
			
		} catch(e) {
			e.target.reset();
			// statements
			console.log(e.message);
			setLoading(false);
		}

	}

	const finalizeRequest = async () => {

		let confirm = window.confirm("This will complete the request process and email the requestor.");

		if(!confirm) {
			console.log('Cancelled.');
			return;
		}

		setLoading(true);

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/finalizeAppRequest"
		// let url = "http://localhost:5000/manage-it-extension/us-central1/finalizeAppRequest";

		let options = {
			method: 'Post',
			body: JSON.stringify({
				domain: domain,
				requestId: requestId
			})
		}

		try {

			let initFinalized = await fetch(url,options);

			if(initFinalized.ok) {
				setLoading(false);
				return getRequestInfo();
			}

			setLoading(false);
			window.alert('There was an error.  Please try again later.');
			
		} catch(e) {
			// statements
			console.log(e);
			setLoading(false);
			window.alert('There was an error.  Please try again later.');
		}

	}

	const updateCurrentStatus = async (val) => {

		setLoading(true);

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/updateCurrentStatus";
		// let url = "http://localhost:5000/manage-it-extension/us-central1/updateCurrentStatus";

		let options = {
			method: 'Post',
			body: JSON.stringify({
				domain: domain,
				requestId: requestId,
				currentStatus: val
			})
		}

		try {

			let initFinalized = await fetch(url,options);

			setLoading(false);

			setStatusUpdated(true);
			setTimeout(() => { setStatusUpdated(false); }, 5000);

			getRequestInfo();

		} catch(e) {
			// statements
			console.log(e);
			setLoading(false);
			window.alert('There was an error.  Please try again later.');
		}

	}

	return (
		<div className={classes.root}>
			<HeaderComponent 
				title={ domain.toUpperCase() + " | App Request Admin" || "" } 
			/>

		{
			requestInfo && (
				<div className={classes.requestStatusContainer}>

					<div className={classes.request}>
						 <Typography align='center' variant='h6'>Request Information</Typography>
						 <Typography>
							<b>Current Status:</b> { currentStatus }
						 </Typography>
						 <Typography>
						 	<b>Software/Web Service:</b> { requestInfo.softwareName }
						 </Typography>
						 <Typography>
						 	<b>Company:</b> { requestInfo.companyName }
						 </Typography>
						 <Typography>
						 	<b>Vendor Phone:</b> { requestInfo.vendorPhone || 'NA' }
						 </Typography>
						 <Typography>
						 	<b>Vendor Email:</b> { requestInfo.vendorEmail || 'NA' }
						 </Typography>
						 <Typography>
						 	<b>Purchase Type:</b> { requestInfo.softwareStatus }
						 </Typography>
						 <Typography>
						 	<b>Type of License(s):</b> { requestInfo.licenseType.join(', ') }
						 </Typography>
						 <Typography>
						 	<b>Anticipated Cost:</b> { requestInfo.anticipatedCost || 'NA' }
						 </Typography>
						 <Typography>
						 	<b>Software Website:</b> { requestInfo.softwareSite || 'NA' }
						 </Typography>
						 <Typography>
						 	<b>Date Needed:</b> { requestInfo.dateNeeded }
						 </Typography>
						 <Typography>
						 	<b>Anticipated Users:</b> { requestInfo.users }
						 </Typography>
						 <Typography>
						 	<b>Software Installations:</b> { requestInfo.softwareInstalls }
						 </Typography>
						 <Typography>
						 	<b>Curriculum Standars:</b> { requestInfo.standards }
						 </Typography>
						 <Typography>
						 	<b>Software Use:</b> { requestInfo.useDescription }
						 </Typography>
						 <Typography>
						 	<b>Requestor:</b> { requestInfo.requestor }
						 </Typography>
						 <Typography>
						 	<b>Department:</b> { requestInfo.department }
						 </Typography>
						 <Typography>
						 	<b>Department (Other):</b> { requestInfo.otherDepartment }
						 </Typography>
						 <Typography>
						 	<b>Course Team:</b> { requestInfo.courseTeam }
						 </Typography>
						 <Typography>
						 	<b>Grade Level(s):</b> { requestInfo.gradeLevels.join(', ') }
						 </Typography>
					</div>

					<div className={classes.actionsContainer}>
					{
						requestInfo.requestActions.length > 0 && (
							<div className={classes.actions}>
								<Typography 
									className={classes.actionsLabel}
								>
									ACTIONS TAKEN
								</Typography>
							{
								requestInfo.requestActions.map(action => (
										<div key={action.timestamp}>
											<Typography><b>Status:</b> { action.status }</Typography>
											<Typography>
												<b>Action Taken:</b> { action.actionTaken }
											</Typography>
											<Typography>
												<b>Comments:</b> { action.comment }
											</Typography>
											<Typography>
												<b>Timestamp:</b> { moment(action.timestamp).format("MMM Do YYYY, h:mm a") }
											</Typography>
											<hr />
										</div>
									)
								)
							}
							</div>
						)
					}
					<form
						className={classes.form}
						onSubmit={handleActionSubmit}
					>

						<Typography 
							className={classes.actionsLabel}
						>
							TAKE ACTION
						</Typography>

						<TextField
							label="Update Status (e.g. Complete, Denied, etc.)"
							variant='outlined'
							className={classes.formItem}
							type='text'
							onChange={e => setStatus(e.target.value)}
						/>

						<TextField
							multiline
							rows={3}
							label="Enter Comment (optional)"
							variant='outlined'
							className={classes.formItem}
							onChange={e => setComment(e.target.value)}
						/>

						<TextField
							label="Enter email of next recipient (optional)"
							helperText='OPTIONAL:  This is if a different person needs to take action in the process.'
							variant='outlined'
							className={classes.formItem}
							type='email'
							onChange={e => setRecipient(e.target.value)}
						/>

						<Button
							required
							type='submit'
							variant='contained'
							className={classes.formItem}
						>
							Submit Action Update
						</Button>						

					</form>

					<div
						className={classes.form}
					>
						<Typography 
							className={classes.actionsLabel}
						>
							UPDATE STATUS
						</Typography>

						{
							statusUpdated ?
								<Typography align='center' style={{color: 'red'}}>Status Updated</Typography>
							: null
						}

						<FormControl component="fieldset">
						  <FormLabel component="legend">Status</FormLabel>
						  <RadioGroup aria-label="gender" name="gender1" value={currentStatus} 
						  	onChange={e => updateCurrentStatus(e.target.value)}
						  >
						    <FormControlLabel value="Incomplete" control={<Radio />} label="Incomplete" />
						    <FormControlLabel value="Seeking Agreement" control={<Radio />} label="Seeking Agreement" />
						    <FormControlLabel value="Awaiting Signature" control={<Radio />} label="Awaiting Signature" />
						    <FormControlLabel value="Complete" control={<Radio />} label="Complete" />
						    <FormControlLabel value="Approved" control={<Radio />} label="Approved" />
						    <FormControlLabel value="Denied" control={<Radio />} label="Denied" />
						  </RadioGroup>
						</FormControl>

					</div>

					</div>

				</div>
			)
		}
			
			{  //  REQUEST NOT FOUND
				notFound && (
					<Typography variant="h6" className={classes.notFound}>
					Request Information was not found.
					</Typography>
				)
			}
			<BackDrop loading={loading} />
		</div>
	)
}

export default RequestAdminComponent
