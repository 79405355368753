import React, { useEffect, useState, useContext } from 'react';
import { fade,makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardHeader
} from '@material-ui/core';
import { firebaseApp } from '../../../firebase';
import { RecordActions } from '../admin_actions';
import _ from 'lodash';
import algoliasearch from 'algoliasearch';
import MainContext from '../../state/main.context';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_ID,process.env.REACT_APP_ALGOLIA_APIKEY);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  list: {

  },
  inline: {
    display: 'inline'
  },
  searchField: {
    minWidth: 450,
    maxWidth: 500,
    margin: theme.spacing(1),
    alignSelf: 'center'
  },
  results: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  card: {
    margin: theme.spacing(1),
    maxWidth: 250,
    minWidth: 200,
  }
}));

const SearchAppsComponent = () => {
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { apps } = mainState;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState([]);
  const [ appTitles, setAppTitles ] = useState([]);

  const appsRef = firebaseApp.firestore().collection('publicApps');

  //  TODO:  LOAD MOST COMMONLY SEARCHED APPS


  useEffect(() => {
      if(apps.length <= 0) {
        return
      }
    //  CREATE APP TITLES ARRAY
    let titles = apps.map(app => app.title.toLowerCase());
    setAppTitles([...titles]);
  },[ apps ]);



  const debouncedSearch = _.debounce( async (val) => {

    console.log("Searching for: " + val)
    setLoading(true);

    index.search(val,{
      queryType: 'prefixNone',
      restrictSearchableAttributes: ['title'],
      hitsPerPage: 50
    })
    .then(({ hits }) => {

      let searchResults = hits.map(hit => {
        return {
          title: hit.title,
          imgUrl: hit.imgUrl,
          id: hit.objectID
        }
      })
      setResults([...searchResults])
      setLoading(false);
    })
    .catch(err => {
      console.dir(err);
      setLoading(false);
    })

  },1000);

  const addAppToDomain = async (appId) => {

    let domainRef = await firebaseApp.firestore()
                    .collection('domains')
                    .doc(mainState.domain);

    mainDispatch({ type: 'setLoading', payload: true });

    try {

      //  GET APP INFO
      let snapshot = await appsRef.doc(appId).get();

      if(!snapshot.exists) {
        console.log('App is not available.');
        mainDispatch({ type: 'setLoading', payload: false });
        return;
      }
      let appData = snapshot.data();

      let obj = {};

      obj.title = appData.title;
      obj.imgUrl = appData.imgUrl;
      obj.url = appData.url;
      obj.desc = appData.desc;
      obj.gradeLevel = appData.grades;
      obj.approvalDate = "";
      obj.expirationDate = "";
      obj.agreementStatus = "";
      obj.agreementType = [];
      obj.category = [];
      obj.company = appData.title;
      obj.contentArea = "";
      obj.groups = [];
      obj.isPublic = false;
      obj.notes = "";
      obj.timestamp = new Date().getTime();

      let initAddApp = await domainRef.collection('apps').add(obj);

      mainDispatch({ type: 'setLoading', payload: false });

      RecordActions(mainState.domain,mainState.authUser.email,`Added App from Search: ${ appData.title }`);

    } catch (e) {
      console.dir(e);
      alert("There was an error.  Please try again later.");
      mainDispatch({ type: 'setLoading', payload: false });
    }

  }

  return (
    <div className={classes.root}>

      <TextField
        variant="outlined"
        placeholder="Start typing..."
        onChange={e => debouncedSearch(e.target.value)}
        className={classes.searchField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        disabled={loading}
      />

      <div className={classes.results}>
        {
          results.length > 0 && (

                results.map((app) =>
                  <Card key={app.id} className={classes.card}>
                    <CardHeader
                      avatar={
                        <Avatar src={app.imgUrl} />
                      }
                      action={
                        appTitles.includes(app.title.toLowerCase())
                          ? <IconButton>
                              <CheckIcon />
                            </IconButton>
                          : <IconButton onClick={() => addAppToDomain(app.id)}>
                              <AddIcon />
                            </IconButton>
                      }
                      title={app.title}
                    />
                  </Card>
                )

          )
        }
      </div>

    </div>
  )
}

export default SearchAppsComponent
