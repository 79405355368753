import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Modal,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppsTableComponent from './apps.table.component';
import { firebaseApp, provider } from '../../../../firebase';
import { getUserCredentials } from '../../getters/getUserData';
import { RecordActions } from '../../admin_actions';
import MainContext from '../../../state/main.context';
import _ from 'lodash';
import Papa from 'papaparse';
import UsageTableComponent from './usage.table.component';
import InstalledChromeApps from './chrome.apps.component';
import UserUsageComponent from './user_usage.component';
import BackDrop from '../../../backdrop.component';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const test_app_data = require('./test_app_data.json');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    paddingBottom: '5vh',
    width: '90vw'
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  formTitle: {
    textAlign: 'center'
  },
  form1: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  form2: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(1)
  },
  menuBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  addBtns: {
    display: 'flex',
    flexDirection: 'row'
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
}));

const AppsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ addApp, setAddApp ] = useState(false);
  const [ searchForApps, setSearchForApps ] = useState(false);
  const [tab, setTab] = React.useState(0);

  //  FORM ITEMS
  const [ appTitle, setAppTitle ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ url, setUrl ] = useState("");
  const [ groups, setGroups ] = useState("");
  const [ categories, setCategories ] = useState([]);
  const [ isPublic, setIsPublic ] = useState(false);
  const [ companyName, setCompanyName ] = useState("");
  const [ agreementStatus, setAgreementStatus ] = useState('Approved');
  const [ agreementType, setAgreementType ] = useState("");
  const [ approvalDate, setApprovalDate ] = useState("");
  const [ expirationDate, setExpirationDate ] = useState("");
  const [ agreementDates, setAgreementDates ] = useState("");
  const [ gradeLevel, setGradeLevel ] = useState("");
  const [ contentArea, setContentArea ] = useState("");
  const [ showTour, setShowTour ] = useState(true);

  const steps = [
    {
      target: '.apps-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.apps-table-tab',
      content: 'Access apps listing and edit app information.'
    },
    {
      target: '.apps-data-tab',
      content: 'View usage data about apps that are being used in your district.'
    },
    {
      target: '.chrome-apps-data-tab',
      content: 'A list of Chrome Apps and Extensions used in your district.  It includes information about the app and/or extension as well as the number of users using them and whot the users are.'
    },
    {
      target: '.apps-user-tab',
      content: 'Query user app activity in details.  The data provides time spent on specific URLs.'
    },
    {
      target: '.apps-table',
      content: 'This is where you manage your information about your apps.  Every column item is editable.  Simply click on the dashed line.  You can use the actions column to delete an app or see app details such as usage statistics.',
      placement: 'center'
    }
  ]

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    //  USER DUMMY DATA
    if(window.location.hostname === "localhost") {
      mainDispatch({ type: 'setLoading', payload: false });
      return mainDispatch({ type: 'setApps', payload: test_app_data });
    }

    const appsListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').onSnapshot(snapshot => {
        if(snapshot.length <= 0 ) {
          return;
        }
        let tempApps = [];
        snapshot.forEach(snap => {
          let tempObj = snap.data();
          tempObj._id = snap.id;
          tempApps.push(tempObj);
        });
        mainDispatch({ type: 'setApps', payload: tempApps });

      });

      return () => appsListener();
  },[]) //  useEffect

  const initGetCredentials = async () => {

     console.log('Getting credential...');
    
    mainDispatch({ type: 'setLoading', payload: true });

    let credentials = await getUserCredentials();

    if(!credentials) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setCredentials', payload: credentials });

    mainDispatch({ type: 'setLoading', payload: false });

    // getUserInfo(mainState.authUser.email,credentials.accessToken);

  }

  const getUserInfo = async (email,accessToken) => {

    console.log('getting user info....');

    let options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      }
    }

    let url = `https://admin.googleapis.com/admin/directory/v1/users/${email}`;

    try {
      let initGetUserInfo = await fetch(url,options);
      let json = await initGetUserInfo.json();

      if(initGetUserInfo.ok) {
        mainDispatch({ type: 'setAdminData', payload: json });
      }

      mainDispatch({ type: 'setLoading', payload: false });

    } catch(e) {
      // statements
      console.log(e);
      mainDispatch({ type: 'setLoading', payload: false });
    }


  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }

  }

  return (
    <Paper className={`${classes.root} apps-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <div className={classes.paperTabs,'apps-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <Tab label="Apps" className="apps-table-tab" />
            <Tab label="Web App Insights" className="apps-data-tab" />
            <Tab label="Chrome Apps | Extensions" className="chrome-apps-data-tab" />
            <Tab label="User Insights" className="apps-user-tab" />
          </Tabs>
        </Paper>
      </div>

      { //  APPS TABLE
        tab === 0 && (
          <AppsTableComponent />
        )
      }

      { //  APP USAGE
        tab === 1 && (
          <UsageTableComponent />
        )
      }

      { //  CHROME APPS
        tab === 2 && (
          <InstalledChromeApps />
        )
      }

      { // USER INSIGHT
        tab === 3 && (
          <UserUsageComponent />
        )
      }

      <BackDrop loading={mainState.isLoading}/>
    </Paper>
  )
}

export default AppsComponent;
