import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from '../state/main.context';
import { SignOut } from '../utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  headerTitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    fontWeight: 'bolder'
  },
  btnGroup: {
    flexGrow: 1,
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(10)
  },
  menuButton: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: theme.spacing(2)
  },
  logo: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    maxHeight: 75,
    maxWidth: 75
  }
}))


const CustomHeader = ({ title }) => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);
  const [ pageTitle, setPageTitle ] = useState(title);
  const [ showMenu, setShowMenu ] = useState(true);
  const [ open, setOpen ] = useState(false);
  const anchorRef = React.useRef(null);

  let { mainState, mainDispatch } = context;

  useEffect(() => {

    if(title === 'Teacher Dashboard Login') {
      setShowBtn(false);
    }

  },[])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClick = () => {
    if(!mainState.authUser || title === 'Home') {
      history.push('/teacher-login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/teacher-login');
    SignOut();
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

    return (
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerTitles}>

            <img
              onClick={() => history.push('/home')}
              className={classes.logo}
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2FApproveItLogoTrans%20WHITE.png?alt=media&token=662d8e7e-62fa-4e26-a30f-1536e510f5f8"}
            />

            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            Manage iT  |
            </Typography>
            <Typography
              className={classes.pageLocation}
            >
            { showMenu ? pageTitle : null }
            </Typography>
          </div>

          {
            showBtn ?

              <Button
                color='inherit'
                className={classes.loginBtn}
                onClick={handleClick}
              >
                {(mainState.authUser && title !== 'Home') ? 'Logout' : 'Login'}
              </Button>

            : null

          }

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
