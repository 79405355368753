/* global gapi */
import React, { useEffect, useState, useContext } from 'react';
import HeaderComponent from '../header.component';
import MainContext from '../../state/main.context';
import { fade,makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

// COMPONENTS
import ResourcesComponent from '../resources/resources.component';
import AppsComponent from './apps/apps.component';
import ProfileComponent from './supervisor-profile.component';
import ActionRecordsComponent from '../records/action_records.component';
import GettingStartedComponent from '../getting_started.component';
import GClassroomComponent from '../gclassroom/gclassroom.component';
import TermsPrivacyAgreement from '../../agreements/termsPrivacyAgreement.component';
import AnnouncementsComponent from '../announcements/announcements.component';
import BackDrop from '../../backdrop.component';
import ConfirmDialog from '../../confirm.dialog.component';
import { SignOut } from '../../utilities/firebase.actions';
import { firebaseApp } from '../../../firebase';

import { getDomainData } from '../getters/getDomainData';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    backgroundColor: fade(theme.palette.primary.main,0.15),
    minHeight: '100vh',
    paddingBottom: '5vh'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  title: {
    flexGrow: 1,
  },
  error: {
    color: 'red',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}));

const SupervisorDashboardComponent = props => {
  const classes = useStyles();
  let { history } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ menuOpen, toggleMenu ] = useState(false);
  const [ page, showPage ] = useState('Apps');
  const menuOptions = [
    'Profile',
    'Announcements',
    'Apps',
    'Resources',
    'Google Classroom Admin',
    'Getting Started'
  ];

  useEffect(() => {
    if(!mainState.authUser){
      history.push('/login');
      return;
    }

    // INIT GET DOMAIN DATA
    initGetDomainData(mainState.authUser.email.split('@')[1]);

  },[]);

  const initGetDomainData = async (domain) => {
    let domainData = await getDomainData(domain);

    if(domainData) {
      mainDispatch({ type: 'setDomainData', payload: domainData });
    }

    //  CHECK AGREEMENT EXIST FOR USER
    initCheckAgreement();

  }

  const initCheckAgreement = async () => {

    let email = mainState.authUser.email;

    try {
      
      let agreementStatus = await firebaseApp.firestore()
      .collection('termsPrivacyAgreement').doc(email).get();

      if(!agreementStatus.exists) {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: true });
        return;
      }

    } catch(e) {
      // statements
      console.log(e);
    }

  }

  const agreementResponse = agreed => {

    if(!agreed) {
      SignOut();
      mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      history.push('/home');
      return;
    }

    let email = mainState.authUser.email;

    firebaseApp.firestore().collection('termsPrivacyAgreement').doc(email)
      .set({
        agreed: Date.now()
      })
      .then(() => {
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })
      .catch(err => {
        console.log(err);
        mainDispatch({ type: 'setShowTermsPrivacyAgreement', payload: false });
      })

  }

  const doToggle = () => {
    toggleMenu(!menuOpen);
  }

  const toggleDrawer = () => event => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    toggleMenu(!menuOpen)
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem button
              onClick={() => showPage(text)}
            key={text}>
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  return (
    <div className={classes.root}>
      <HeaderComponent
        title={page}
        toggleMenu={doToggle}
      />

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      {
        page === 'Profile' && (
          <ProfileComponent />
        )
      }

      {
        page === 'Announcements' && (
          <AnnouncementsComponent />
        )
      }

      {
        page === 'Resources' && (
          <ResourcesComponent />
        )
      }

      {
        page === 'Apps' && (
          <AppsComponent />
        )
      }

      {
        page === 'Google Classroom Admin' && (
          <GClassroomComponent />
        )
      }

      {
        page === 'Action History' && (
          <ActionRecordsComponent />
        )
      }

      {
        page === 'Getting Started' && (
          <GettingStartedComponent />
        )
      }


      <TermsPrivacyAgreement
        visible={mainState.termsPrivacyAgreement}
        agreementResponse={agreementResponse}
      />
      <ConfirmDialog toOpen={mainState.confirm} message={mainState.confirmMessage} />
      <BackDrop loading={mainState.isLoading} />
    </div>
  )
}

export default SupervisorDashboardComponent;
