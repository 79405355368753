import { firebaseApp } from '../../../firebase';

const getDomainData = async (domain,email, accessToken) => {
  return new Promise( async (resolve,reject) => {

    let snapshot = await firebaseApp.firestore().collection('domains').doc(domain).get();

    if(!snapshot.exists) {
      return resolve(null);
    }

    let data = snapshot.data();

    return resolve(data);

  });

}

const getOrgUnits = async (email) => {
  return new Promise( async (resolve,reject) => {

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getOrgUnits";

    let options = {
      method: "Post",
      body: JSON.stringify({
        email: email
      })
    }

    let initFetch = await fetch(url,options);

    if(initFetch.ok) {
      let json = await initFetch.json();
      return resolve(json);
    }

    return resolve([]);

  }); //  PROMISE
}

export {
  getDomainData,
  getOrgUnits
}
