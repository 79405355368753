import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Checkbox,
  Box,
  Typography,
  TextField,
  Paper,
  Button,
  Tooltip,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import MaterialTable, { MTableEditField, MTableEditRow, MTableToolbar } from "material-table";
import { firebaseApp } from '../../../firebase';
import MainContext from '../../state/main.context';
import { fade, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import BackDrop from '../../backdrop.component';
import HeatMapComponent from './heatmap.component';
import { downloadCSV } from '../../utilities/csv_downloader';
import GetAppIcon from '@material-ui/icons/GetApp';

import Chart from "chart.js";
// import lineClasses from "./LineGraph.module.css";

const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 150,
    maxHeight: 150,
    overflow: 'scroll'
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  visits: {
    maxWidth: 100,
    minWidth: 50,
    overflow: 'scroll'
  },
  url: {
    minWidth: 300,
    maxWidth: 500,
    overflow: 'scroll'
  },
  imgDiv: {
    maxWidth: 60,
  },
  img: {
    width: 50,
    height: 50
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  loadMore: {
    width: 200,
    margin: theme.spacing(1)
  },
  chartContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  innerChartContainer: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartTitle: {
    margin: theme.spacing(1)
  },
  chart: {
    display: 'flex',
    height: 400,
    padding: 5
  },
  siteList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 500,
    minWidth: 250,
    maxWidth: 400,
    overflow: 'auto',
    padding: theme.spacing(1)
  },
  labelList: {
    listStyleType: 'none',
    margin: theme.spacing(1),
    fontSize: '1.25em'
  },
  table: {
    width: '100%'
  },
  cell1: {
    // border: '1px solid red'
  },
  cell2: {
    // border: '1px solid blue'
  }
}));

const UsageTableComponent = () => {
  const chartRef = useRef();
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const approvedApps = mainState.apps || [];

  const [ loading, setLoading ] = useState(true);
  const [ apps, setApps ] = useState([]);
  const [ lastSnapshot, setLastSnapshot ] = useState(null);
  const [ page, setPage ] = useState(0);
  const [ chartData, setChartData ] = useState(null);

  //  LISTEN AND ADJUST TO APPS CHANGES
  useEffect(() => {
    if(!mainState.domain) {
      return;
    }

    if(mainState.appInsights) {
      let timeDiff = Date.now() - mainState.appInsights.last_checked;

      if(timeDiff < 84400000) {
        console.log('App Insights list refreshes every 24 hours.');
        setApps(mainState.appInsights.apps);
        setChartData(mainState.chartData);
        setLoading(false);
        return;
      }

      getAppInsights();

      return;
    }  //  THERE IS APP INSIGHTS

    getAppInsights();
  },[mainState.domain]);

  useEffect(() => {
    if(chartData) {
      const myChartRef = chartRef.current.getContext('2d');

      new Chart(myChartRef, {
        type: 'doughnut',
        data: chartData,
        options: {
          startAngle: Math.PI * 1.166666666667,
          layout: {
            // padding: 10
          },
          legend: {
            display: false
          }
        }
      })

    }
  },[chartData]);

  const random_rgba = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ')';
  }

  const isApproved = (id) => {

    let approved = false;

    approvedApps.some(app => {
      
      let d = app.url.split('/')[2];

      if(d == id ) {
        approved = true;
        return true;
      }

      return false;
    });

    return approved;

  }

  const getAppInsights = () => {
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('visited_sites').orderBy('num_visits','desc').limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0){
          return setLoading(false);
        }

        let tempChartData = {
          datasets: [{
            data:[],
            backgroundColor: []
          }],
          labels: []
        }

        let tempData = snapshot.docs.map(doc => {

          let label = doc.id;
          let datum = doc.data().num_visits;
          let appIsApproved = isApproved(doc.id);


          if(!chartData) {
            tempChartData.datasets[0].data.push(datum);
            tempChartData.labels.push(label);
            tempChartData.datasets[0].backgroundColor.push(random_rgba());
          }

          let obj = {
            id: label,
            ref: doc,
            num_visits: datum,
            favicon: doc.data().favicon,
            isApproved: appIsApproved
          }

          return obj;

        });

        let payload = {
          apps: tempData,
          last_checked: Date.now()
        }

        if(!chartData) {
          setChartData(tempChartData);
          mainDispatch({ type: 'setChartData', payload: tempChartData });
        }

        setLastSnapshot(snapshot.docs[snapshot.docs.length-1]);
        setApps(tempData);
        mainDispatch({ type: 'setAppInsights', payload: payload });
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  const getMoreAppInsights = () => {

    setLoading(true);
    
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('visited_sites').orderBy('num_visits','desc')
      .startAt(lastSnapshot).limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0){
          setLoading(false);
          return;
        }

        let tempData = snapshot.docs.map(doc => {

          let label = doc.id;
          let datum = doc.data().num_visits;
          let appIsApproved = isApproved(doc.id);

          let obj = {
            id: label,
            ref: doc,
            num_visits: datum,
            favicon: doc.data().favicon,
            isApproved: appIsApproved
          }
          return obj;

        });

        let combinedData = [...apps,...tempData];

        let payload = {
          apps: combinedData,
          last_checked: Date.now()
        }

        setLastSnapshot(snapshot.docs[snapshot.docs.length-1]);
        setApps(combinedData);
        mainDispatch({ type: 'setAppInsights', payload: payload })
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  const getRemainingAppInsights = () => {

    setLoading(true);
    
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('visited_sites').orderBy('num_visits','desc')
      .startAt(lastSnapshot).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0){
          setLoading(false);
          return;
        }

        let tempData = snapshot.docs.map(doc => {

          let label = doc.id;
          let datum = doc.data().num_visits;
          let appIsApproved = isApproved(doc.id);

          let obj = {
            id: label,
            ref: doc,
            num_visits: datum,
            favicon: doc.data().favicon,
            isApproved: appIsApproved
          }
          return obj;

        });

        let combinedData = [...apps,...tempData];

        let payload = {
          apps: combinedData,
          last_checked: Date.now()
        }

        setLastSnapshot(snapshot.docs[snapshot.docs.length-1]);
        setApps(combinedData);
        mainDispatch({ type: 'setAppInsights', payload: payload })
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  const initDownloadData = async () => {

    mainDispatch({ type: 'setLoading', payload: true });

    let data = chartData.labels.map((label,i) => {

      var obj = {
        website: label,
        num_visits: chartData.datasets[0].data[i]
      }

      return obj;
    })

    await downloadCSV(data,'top_websites.csv');

    mainDispatch({ type: 'setLoading', payload: false });
  }

  const columns = [
    {
      title: 'Image',
      field: 'favicon',
      export: false,
      render: rowData => {
        if(rowData.hasOwnProperty('favicon') && rowData.favicon) {
          return (
            <div className={classes.imgDiv}>
              <img src={rowData.favicon} className={classes.img} />
            </div>
          )
        }
        return (
          <div></div>
        )
      }
    },
    {
      title: 'Domain',
      field: 'id',
      render: rowData =>
        <p className={classes.url}>
          { rowData.id }
        </p>
    },
    {
      title: 'Visits',
      field: 'num_visits',
      render: rowData =>
        <p className={classes.visits}>
        { rowData.hasOwnProperty('num_visits') ? rowData.num_visits : 0 }
      </p>
    },
    {
      title: 'Is Approved',
      field: 'isApproved',
      render: rowData => 
        <p>
          { rowData.isApproved ? "TRUE" : 'FALSE' }
        </p>
    }
  ];

  return (
    <div>

      {
        chartData && (

          <Paper 
            className={classes.chartContainer}
            elevation={10}
          >

            <Typography
              component="h1"
              align="center"
              variant="h3"
              className={classes.chartTitle}
            >TOP {chartData.labels.length } WEBSITES</Typography>

            <div className={classes.innerChartContainer}>

              <div
                className={classes.chartPaper}
              >
                <canvas 
                  className={classes.chart}
                  ref={chartRef}
                />
              </div>

              <Paper 
                className={classes.siteList}
                elevation={5}
              >

                <Table stickyHeader
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Button
                          color="primary"
                          className={classes.button}
                          startIcon={<GetAppIcon />}
                          onClick={initDownloadData}
                        >
                          Download Data
                        </Button>                        
                      </TableCell>
                      <TableCell>Visits</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      chartData.labels.map((label,i) =>
                        <TableRow key={label}>
                          <TableCell className={classes.cell1}>
                            {label}
                          </TableCell>
                          <TableCell className={classes.cell2}>
                            { chartData.datasets[0].data[i] }
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </Paper>

        )
      }

      <MaterialTable
        title="SITE VISITS TABLE"
        columns={columns}
        data={apps}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        options={{
          pageSize: 50,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          },
          exportButton: true,
          exportAllData: true
        }}
        detailPanel={ rowData => {
          return (
            <div className={classes.heatCont}>
              <HeatMapComponent 
                id={rowData.id}
                dispatch={mainDispatch}
                domain={mainState.domain}
                email={mainState.authUser.email}
              />
            </div>
          )
        }}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              
              <div  style={{ padding: 25 }}>
                <Typography component='h1'>
                NOTE:  The table is designed to load 100 items at a time for performance purposes.  If you would like to export all of the data, do the following:
                </Typography>
                <ol>
                    <li>
                      <Typography component='h1'>
                      Scroll to the bottom and click on Load Remaining Data
                      </Typography>
                    </li>
                    <li>
                      <Typography component='h1'>
                      Click on the export icon on the top-right corner of the table and select the format that you want.
                      </Typography>
                    </li>
                </ol>
              </div>
            </div>
          )
        }}

      />

      <div className={classes.loadMoreContainer}>
        <Tooltip
          title="100 apps at a time = faster loads"
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.loadMore}
            onClick={getMoreAppInsights}
          >
            Load More Data
          </Button>
        </Tooltip>
        <Tooltip
          title="Use for Exporting all Data"
        >
          <Button
            color="secondary"
            variant="contained"
            className={classes.loadMore}
            onClick={getRemainingAppInsights}
          >
            Load Remaining Data
          </Button>
        </Tooltip>
      </div>
      <BackDrop loading={loading} />
    </div>
  )
}

export default UsageTableComponent;
