import { firebaseApp } from '../../firebase';

export const RecordActions = (domain,email,action) => {

  let collectionRef = firebaseApp.firestore()
    .collection('domains').doc(domain)
    .collection('action_records');

  collectionRef.add({
    email: email,
    action: action,
    timestamp: new Date().getTime()
  })
  .then(() => console.log(`Successfully recorded action...`))
  .catch(err => {
    console.log('Error recording admin actions');
    console.dir(err);
  })

}
