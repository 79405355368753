import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';

import { firebaseApp } from '../../../../firebase';
import { RecordActions } from '../../admin_actions';
import MainContext from '../../../state/main.context';
import BackDrop from '../../../backdrop.component';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable, { MTableToolbar } from "material-table";
import { downloadCSV } from '../../../utilities/csv_downloader';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  noDataText: {
    marginTop: '10vh'
  },
  detailPanel: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row'
  },
  detailLeft: {
    border: '1px solid lightgrey',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 750,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll'
  },
  detailRight: {
    border: '1px solid lightgrey',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 750,
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    maxHeight: 500,
    overflow: 'scroll'
  },
  numUsers: {
    maxWidth: 100,
    minWidth: 50,
    overflow: 'scroll'
  },
  appName: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word',
    overflow: 'auto'
  },
  desc: {
    maxWidth: 500,
    maxHeight: 250,
    minWidth: 200,
    overflow: 'scroll'
  },
  user: {
    marginLeft: theme.spacing(1)
  },
  loadMoreBtn: {
    margin: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}));

const AppDetailPanel = ({ appInfo }) => {

  const classes = useStyles();

  return(
    <div className={classes.detailPanel}>

      <div className={classes.detailLeft}>
        <Typography><b>App ID:</b> {appInfo.id}</Typography>
        <Typography><b>Type:</b> { appInfo.isApp ? "Chrome App" : "Extension" }</Typography>
        <Typography><b>Web Store URL: </b>
          <a href={appInfo.homepageUrl} target="_blank">{appInfo.homepageUrl}</a>
        </Typography>
        <Typography>Permissions: <b>{appInfo.permissions.join(", ")} </b></Typography>
        <Typography>
          <a href="https://developer.chrome.com/docs/extensions/mv2/declare_permissions/#manifest" target="_blank">
            Learn more about permissions
          </a>
        </Typography>
      </div>

      <div className={classes.detailRight}>
        {
          appInfo.users.map(user =>
            <Typography key={user} className={classes.user}><span>&#8226; &nbsp;</span> {user}</Typography>
          )
        }
      </div>

    </div>
  )
}

const InstalledChromeApps = props => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ chromeApps, setChromeApps ] = useState([]);
  const [ lastDoc, setLastDoc ] = useState('');

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('appsExtensions').orderBy('total_users','desc')
      .limit(50).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0) {
          setLoading(false);
          alert('No data found.');
          return;
        }

        let tempLastDoc = snapshot.docs[snapshot.docs.length-1];
        let tempChromeApps = [];

        snapshot.docs.forEach(doc => tempChromeApps.push(doc.data()));

        setChromeApps([...tempChromeApps]);
        setLastDoc(tempLastDoc);

        setLoading(false);

      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
        alert('There was an error.  Please try again.');
      })

  },[]);

  const getMoreData = () => {

    setLoading(true);

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('appsExtensions').orderBy('total_users','desc')
      .startAt(lastDoc).limit(50).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0) {
          setLoading(false);
          alert('No more data left.');
          return;
        }

        let tempLastDoc = snapshot.docs[snapshot.docs.length-1];
        let tempChromeApps = [];

        snapshot.docs.forEach(doc => tempChromeApps.push(doc.data()));

        //  REMOVE FIRST ITEM BECAUSE IT AS IN LAST QUERY
        tempChromeApps.shift();

        if(tempChromeApps.length <= 0) {
          setLoading(false);
          alert('No more data left.');
          return;
        }

        setChromeApps([...chromeApps,...tempChromeApps]);
        setLastDoc(tempLastDoc);

        setLoading(false);

      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
        alert('There was an error.  Please try again.');
      })

  }

  const getPermissions = (appInfo) => {
    return appInfo.permissions.join(',');
  }

  const initDownloadData = async () => {

    mainDispatch({ type: 'setLoading', payload: true });

    let data = chromeApps.map(item => {

      var obj = {
        title: item.name || "",
        id: item.id || "",
        desc: item.description || "",
        url: item.homepageUrl || "",
        permissions: getPermissions(item)
      }

      return obj;
    })

    await downloadCSV(data);

    mainDispatch({ type: 'setLoading', payload: false });
  }

  const columns = [
    {
      title: "# Users",
      field: 'total_users',
      defaultSort: 'desc',
      render: rowData => (
        <Typography className={classes.numUsers}>{ rowData.total_users }</Typography>
      ),
      width: 150
    },
    {
      title: "App Name",
      field: 'name',
      render: rowData => (
        <Typography className={classes.appName}>{ rowData.hasOwnProperty('name') ? rowData.name.toUpperCase() : '' }</Typography>
      ),
      width: 250
    },
    {
      title: "Description",
      field: 'description',
      render: rowData => (
        <Typography className={classes.desc}>{ rowData.description }</Typography>
      )
    }
  ]

  return (
    <div className={classes.root}>

      {
        chromeApps.length <= 0 && (
          <Typography align='center'
            component="h1"
            variant="subtitle1"
            className={classes.noDataText}
          >No apps data collected yet.  Come back later :)</Typography>
        )
      }

      {
        chromeApps.length > 0 && (
          <div className={classes.mainContainer}>
            <MaterialTable
              title="Chrome Apps & Extensions"
              data={chromeApps}
              columns={columns}
              style={{
                minWidth: '90vw',
                margin: '10px',
                marginTop: '30px'
              }}
              options={{
                search: true,
                sorting: true,
                pageSize: 50
              }}
              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    <div className={classes.toolbar}>
                      <Button
                        // variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                        onClick={initDownloadData}
                      >
                        Download Data
                      </Button>
                    </div>
                  </div>
                ),
              }}
              detailPanel={ rowData => {
                  return (
                    <AppDetailPanel
                      appInfo={rowData}
                    />
                  )
              }}

              onRowClick={(event,rowData,togglePanel) => togglePanel() }

            />
            <Button
              variant="contained"
              color='primary'
              onClick={getMoreData}
              className={`${classes.loadMoreBtn} load-more-records`}
            >
              Load More
            </Button>
          </div>
        )
      }

      <BackDrop loading={loading} />

    </div>
  )
}

export default InstalledChromeApps
