/* global gapi */
import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UsersTableComponent from './users.table.component';
import GoogleSyncComponent from './user.google_sync.component';
import { firebaseApp, provider } from '../../../firebase';
import { getUserCredentials } from '../getters/getUserData';
import { getDomainUsers } from '../getters/getDomainUsers';
import { RecordActions } from '../admin_actions';
import { getDomainData } from '../getters/getDomainData';
import MainContext from '../../state/main.context';
import Papa from 'papaparse';
import { SignOut } from '../../utilities/firebase.actions';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const clientId = "908528604003-gg7rbepq8m7lj2pceclvnntbe4hmol36.apps.googleusercontent.com";
const apiKey = "AIzaSyAtq7gBnYOZqTn3PKZUDLKDnMS4dc59V8o";
const apiKey2 = process.env.REACT_APP_API_KEY

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    paddingBottom: '5vh',
    width: '90vw'
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  formTitle: {
    textAlign: 'center'
  },
  form1: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  form2: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(1)
  },
  btns: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  menuBtn: {

    margin: theme.spacing(1)
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },

}));

const UsersComponent = props => {
  let { history } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ addUser, setAddUser ] = useState(false);
  const [ isAdmin, setIsAdmin ] = useState(false);
  const [ showTour, setShowTour ] = useState(true);
  const [ selectedOrgUnits, setSelectedOrgUnits ] = useState([]);
  const [ domainData, setDomainData ] = useState(null);
  const [tab, setTab] = React.useState(0);

  const steps = [
    {
      target: '.users-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.users-table',
      content: 'This is where you manage your users.  You can designate them as admins, supevisors or remove them from either roles.  Click one of the action buttons to delete or begin updating a user.',
      placement: 'center'
    },
    {
      target: '.users-add',
      content: 'Add individual users.  Click on the button to get started.'
    },
    {
      target: '.users-sync',
      content: <div style={{ textAlign: 'left' }}>
        <p>
          Google Directory Sync allows you to automatically sync your users and assign their groups based on the organizational unit that they belong to.  The user's parent OU will be their default group but will retain their OU path as well as their sub OU grouping.  For example, if a student falls under <b>/students/freshmen</b>, then the student will be given the <b>students</b> parent group and will have membership under <b>students</b> and <b>freshmen</b>.
        </p>
        <p>
        <span style={{color: 'red', fontWeight: 'bold' }}>NOTE: </span>In order to take advantage of Google Sync, you must enable domain wide delegation to our application as well as enable API Access Control.  Head on over to the <b>Menu</b> and select <b>Getting Started</b> to learn more or click <a href="https://docs.google.com/document/d/1hCYGmAzK6THn8Ec9ca5fGgUXwLHvjRpdMzqWs7QbqSM/preview" target="_blank">HERE</a>. </p>
      </div>
    }
  ]

  //
    useEffect(() => {

      // setCourses(testCourses);
      // setLoading(false);

      if(!mainState.authUser) {
        history.push('/login');
        return;
      }

      // if(!mainState.credentials) {

      //   initGetCredentials();

      // }

      if(!mainState.domainData) {
        initGetDomainData(mainState.domain,mainState.authUser.email);
      } else {
        setDomainData(mainState.domainData);
        mainDispatch({ type: 'setLoading', payload: false });
      }

      // verifyToken();

    }, []);
  //*/
  
  const initGetCredentials = async () => {

     console.log('Getting credential...');
    
    mainDispatch({ type: 'setLoading', payload: true });

    let credentials = await getUserCredentials();

    if(!credentials) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setCredentials', payload: credentials });

    // getUserInfo(mainState.authUser.email,credentials.accessToken);

  }

  const getUserInfo = async (email,accessToken) => {

    console.log('getting user info....');

    let options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      }
    }

    let url = `https://admin.googleapis.com/admin/directory/v1/users/${email}`;

    try {
      let initGetUserInfo = await fetch(url,options);
      let json = await initGetUserInfo.json();

      if(initGetUserInfo.ok) {
        mainDispatch({ type: 'setAdminData', payload: json });
      }

      mainDispatch({ type: 'setLoading', payload: false });

    } catch(e) {
      // statements
      console.log(e);
      mainDispatch({ type: 'setLoading', payload: false });
    }
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const initGetDomainData = async (domain,adminEmail) => {

    mainDispatch({ type: 'setLoading', payload: true });

    let receivedData = await getDomainData(domain,adminEmail);

    if(!receivedData) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setDomainData', payload: receivedData });
    setDomainData(receivedData);
    mainDispatch({ type: 'setLoading', payload: false });
  }

  const initGetDomainUsers = async (domain) => {
    
    mainDispatch({ type: 'setLoading', payload: true });

    let usersData = await getDomainUsers(domain);

    mainDispatch({ type: 'setLoading', payload: false });
    mainDispatch({ type: 'setConfirm', payload: true });
    mainDispatch({ type: 'setConfirmMessage', payload: "Successfully added user(s)." });
    mainDispatch({ type: 'setDomainUsers', payload: usersData });
  }

  const submitUser = e => {
    e.preventDefault();
    e.persist();

    mainDispatch({ type: 'setLoading', payload: true });

    let email = e.target[0].value;
    let group = e.target[1].value;

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(email).set({
        timestamp: new Date().getTime(),
        email: email.toLowerCase(),
        roles: {
          admin: isAdmin
        },
        group: group.toLowerCase(),
        notes: ''
      })
      . then(() => {
        console.log('User saved...');
        e.target.reset();
        setAddUser(false);
        initGetDomainUsers(mainState.domain);
        RecordActions(mainState.domain,mainState.authUser.email,`Added User: ${ email }`);
      })
      .catch(err => {
        console.dir(err);
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setConfirm', payload: true });
        mainDispatch({ type: 'setConfirmMessage', payload: "Error saving user.  Please try again later." });
      })
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    mainDispatch({ type: 'setLoading', payload: true });
    e.persist();

    let file = e.target[0].files[0];

    Papa.parse(file, {
      complete: function (results) {

        let data = results.data || [];

        if(data.length <= 0) {
          mainDispatch({ type: 'setLoading', payload: false });
          return;
        }

        //  UPLOAD TO FIRESTORE
        let domain = mainState.authUser.email.split('@')[1];
        let domainUsersRef = firebaseApp.firestore().collection('domains').doc(mainState.domain)
          .collection('users');
        let batch = firebaseApp.firestore().batch();

        data.forEach(item => {
          if((item[0]).toLowerCase() === 'email') {
            return;
          }
          if(item[0] && item[1] && item[2]) {
            batch.set(domainUsersRef.doc(item[0]),{
              email: (item[0]).toLowerCase(),
              role: {
                admin: item[1] === "TRUE" ? true : false
              },
              group: (item[2]).toLowerCase(),
              timestamp: new Date().getTime(),
              notes: ''
            })
          }
        });

        batch.commit()
          .then(() => {
            initGetDomainUsers(mainState.domain);
            RecordActions(mainState.domain,mainState.authUser.email,`Uploaded Users`);
          })
          .catch(err => {
            console.log(err);
            mainDispatch({ type: 'setLoading', payload: false });
            mainDispatch({ type: 'setConfirm', payload: true });
            mainDispatch({ type: 'setConfirmMessage', payload: "Error saving users.  Please try again later." });
          });

      }
    });//  PAPA PARSE
  }

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }
  }

  return (
    <Paper className={`${classes.root} users-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <div className={classes.paperTabs,'apps-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <Tab label="Users" className="users-table" />
            <Tab label="Add Users" className="users-add" />
            <Tab label="Google Sync" className="users-sync" />
          </Tabs>
        </Paper>
      </div>


      {
        tab == 0 && (
          <UsersTableComponent />
        )
      }

      {
        tab === 1 && (

          <Grid
            container
            justify="space-evenly"
            spacing={2}
            className={classes.forms}
          >
            <Grid item xs={8}>
              <form className={classes.form1}
                onSubmit={submitUser}
              >

              <h3 className={classes.formTitle}>Add Admin User</h3>
              <p style={{ textAlign: 'center' }}>NOTE:  Users that are synced from your directory can be designated as admins.</p>

                <TextField
                  type="text"
                  label='Email'
                />

                <TextField
                  type="text"
                  label='Group'
                />

                <FormControlLabel
                  control={
                    <Checkbox checked={true} disabled
                      onChange={() => {
                        setIsAdmin(!isAdmin);
                      }}
                      name="isAdmin"
                      />
                  }
                  label="Admin User"
                />

                <Button
                  className={classes.submitBtn}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={mainState.isLoading}
                >
                  Submit
                </Button>

              </form>
            </Grid>

            
            {/*
            <Grid item xs={4}>
              <form
                className={classes.form2}
                onSubmit={handleUpload}
              >

              <h3 className={classes.formTitle}>Add Multiple Users</h3>

                <p>
                  A .csv file with THREE columns is required. &nbsp; &nbsp;
                  <a href="https://docs.google.com/spreadsheets/d/1wLVKbYVMNcXMzlws6EdotUueJOihURXgwbraldpQkV8/edit?usp=sharing" target="_blank">
                    Template Sheet
                  </a>
                </p>
                <TextField type='file' required />
                <Button
                  style={{marginTop: '10px',marginBottom: '10px'}}
                  variant='contained'
                  color='secondary'
                  type='submit'
                >
                  Upload Users
                </Button>
              </form>
            </Grid>
          */}

          </Grid>

        )
      }

      {
        tab === 2 && (
          <GoogleSyncComponent />
        )
      }

    </Paper>
  )
}

export default UsersComponent;
