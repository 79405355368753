import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Switch,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import MaterialTable, { MTableEditField } from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import { firebaseApp } from '../../../firebase';
import { getDomainUsers } from '../getters/getDomainUsers';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 200,
    maxHeight: 150,
    overflow: 'scroll'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  ur: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  notes: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  groups: {
    maxWidth: 200,
    maxHeight: 200,
    overflowWrap: 'normal'
  },
  editField: {
    width: 200,
    height: 100
  }
}));

const UsersTableComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ users, setUsers ] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const [ userToDelete, setUserToDelete ] = useState(null);
  const [ loading, setLoading ] = useState(false);

//
  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    if(!mainState.domainUsers) {
      initGetDomainUsers(mainState.domain);
    } else {
      setUsers(mainState.domainUsers);
    }

  },[])
  //*/

  const initGetDomainUsers = async (domain) => {
    
    setLoading(true);

    let usersData = await getDomainUsers(domain);

    setUsers(usersData);

    setLoading(false);
    mainDispatch({ type: 'setDomainUsers', payload: usersData });
  }

  const handleChange = (rowData,role) => {
    mainDispatch({type: 'setLoading', payload: true });

    let permission = false;

    switch(role) {
      case 'admin':

        if(rowData.hasOwnProperty('roles') && rowData.roles.hasOwnProperty('admin')) {
          permission = !rowData.roles.admin;
        }

        break;

      case 'supervisor':

        if(rowData.hasOwnProperty('roles') && rowData.roles.hasOwnProperty('supervisor')) {
          permission = !rowData.roles.supervisor;
        } else {
          //  IF ADDING ROLE FOR THE FIRST TIME
          permission = true;
        }

        break;
    }
    
    let toUpdate = `roles.${role}`;

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(rowData.email)
      .update({
        [`${toUpdate}`]: permission
      })
      .then(() => {
        console.log('changed admin status.');
        mainDispatch({type: 'setLoading', payload: false });
        initGetDomainUsers(mainState.domain);
        RecordActions(mainState.domain,mainState.authUser.email,`Updated User ${role} Status: ${ rowData.email }`);
      })
      .catch(err => console.dir(err));
  }

  const columns = [
    {
      title: 'email',
      field: 'email',
      editable: 'never',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('email') ? rowData.email.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Admin',
      field: 'admin',
      editable: 'never',
      render: rowData => {
        return (
          <Switch
            checked={ rowData.hasOwnProperty('roles') ? rowData.roles.admin : false }
            onChange={() => handleChange(rowData,'admin')}
            disabled={mainState.authUser.email.toLowerCase() === rowData.email.toLowerCase()}
          />
        )
      }
    },
    {
      title: 'Supervisor',
      field: 'supervisor',
      editable: 'never',
      render: rowData => {
        return (
          <Switch
            checked={
              (rowData.hasOwnProperty('roles') && rowData.roles.hasOwnProperty('supervisor')) ? 
                rowData.roles.supervisor : false 
            }
            onChange={() => handleChange(rowData,'supervisor')}
            disabled={mainState.authUser.email.toLowerCase() === rowData.email.toLowerCase()}
          />
        )
      }
    },
    {
      title: 'OU Path',
      field: 'orgUnitPath',
      editable: 'never',
      render: rowData =>
        <p className={classes.title}>
          {
            rowData.hasOwnProperty('orgUnitPath') ?    rowData.orgUnitPath
              : 'NA'
          }
        </p>
    },
    {
      title: 'Notes',
      field: 'notes',
      render: rowData =>
        <p className={classes.notes}>
          { rowData.hasOwnProperty('notes') ?  rowData.notes : '' }
        </p>
    }
  ];

  const deleteUser = async () => {

    mainDispatch({ type: 'setLoading', payload: true });

    if(!userToDelete) {
      return;
    }

    let options = {
      method: 'Post',
      body: JSON.stringify({
        domain: mainState.domain,
        adminEmail: mainState.authUser.email,
        userEmail: userToDelete
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/deleteUserRecursively";

    try {

       let initDeleteUser = await fetch(url,options);

       if(initDeleteUser.ok) {
          
          let tempUsers = mainState.domainUsers;

          let filteredUsers = tempUsers.filter(a => a.email.toLowerCase() != userToDelete.toLowerCase());

          setUsers(filteredUsers);
          mainDispatch({ type:'setDomainUsers', payload: filteredUsers });
          mainDispatch({ type: 'setLoading', payload: false });

          return;
       }

       mainDispatch({ type: 'setLoading', payload: false });
       setUserToDelete(null);
       alert('There was an error.  Please try again later.  If the issue persists, please contact support@approveit.app.');
     
    } catch(e) {

      mainDispatch({ type: 'setLoading', payload: false });
      setUserToDelete(null);
      alert(`ERROR: ${e.message}`);

    } 
    

  }

  const updateUser = newData => {

    mainDispatch({type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(newData.email)
      .set(newData)
      .then(() => {
        console.log('Updated resource.');
        mainDispatch({type: 'setLoading', payload: false });
        initGetDomainUsers(mainState.domain);
        RecordActions(mainState.domain,mainState.authUser.email,`Updated User: ${ newData.email }`);
      })
      .catch(err => {
        console.log('Error updating resource.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  return (
    <div className='users-table'>
      <MaterialTable
        title="Users"
        columns={columns}
        data={users}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          },
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          exportButton:true,
          exportAllData: true
        }}
        actions={[
          {
            icon: () =>
            <DeleteIcon
              color='secondary'
            />,
            tooltip: 'Delete',
            onClick: (event, rowData) => {

              if(rowData.email.toLowerCase() === mainState.authUser.email.toLowerCase()) {
                alert('You cannot delete your own account.');
                return;
              }

              setUserToDelete(rowData.email);
              setConfirmDelete(true);

            }
          }
        ]}
        components={{
          Container: Box,
          EditField: props => (
            <MTableEditField {...props} className={classes.editField} />
          )
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              newData.group = newData.group.toLowerCase();
              updateUser(newData);
              resolve();
            })
        }}
      />

      <Dialog
          open={confirmDelete}
          onClose={()=> console.log('dialog closed...')}
        >
          <DialogTitle>
          ATTENTION!!!
          </DialogTitle>

          <DialogContent>
            
            <DialogContentText>
            Deleting a user account will result in deleting all of their data.  If you want a copy of their data before deleting their account, please contact <b>support@approveit.app</b>.  It is imperative that the email comes from an admin account holder.
            </DialogContentText>

          </DialogContent>

          <DialogActions>
            <Button 
              onClick={() => setConfirmDelete(false)} 
              color="secondary"
            >
              Cancel
            </Button>
            <Button 
              onClick={() => {
                setConfirmDelete(false);
                deleteUser();
              }} 
              color="primary" 
              variant="contained"
              autoFocus
            >
              Delete User and Their Data
            </Button>
          </DialogActions>
        </Dialog>

    </div>
  )
}

export default UsersTableComponent;
