import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormGroup,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeaderComponent from '../header.component';
import BackDrop from '../../backdrop.component';
import ReCAPTCHA from "react-google-recaptcha";
import _ from 'lodash';

const useStyles = makeStyles( theme => ({
  root: {
    marginTop: '10vh'
  },
  formDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(5),
    padding: theme.spacing(2)
  },
  formPaper: {
    padding: theme.spacing(2)
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(5),
    maxWidth: 800,
    minWidth: 500
  },
  formControl: {
    margin: theme.spacing(1)
  },
  textField: {
    maxWidth: 600,
    margin: theme.spacing(1)
  },
  checkBoxes: {
    maxWidth: 600,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  submitBtn: {
    margin: theme.spacing(2)
  }
}))

const RequestFormComponent = props => {
  const [ loading, setLoading ] = useState(false);
  const params = useParams();
  let { domain, user } = params;
  const history = useHistory();
  const classes = useStyles();
  const [ captcha_val, setCaptcha ] = useState(null);
  const RECAPTCHA_SERVER_KEY = process.env.REACT_APP_RECAPTCHA_SERVER_KEY;
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  //  FORM ITEMS
  const [ softwareName, setName ] = useState('');
  const [ companyName, setCompany ] = useState('');
  const [ vendorPhone, setPhone ] = useState('');
  const [ vendorEmail, setEmail ] = useState('');
  const [ softwareStatus, setStatus ] = useState('New');
  const [ licenseType, setLicenseType ] = useState([]);
  const [ anticipatedCost, setAnticipatedCost ] = useState('');
  const [ softwareSite, setSite ] = useState('');
  const [ dateNeeded, setDateNeeded ] = useState('');
  const [ users, setUsers ] = useState('');
  const [ systemInstalls, setSystemInstalls ] = useState('');
  const [ standards, setStandards ] = useState('');
  const [ useDescription, setUseDescription ] = useState('');
  const [ requestor, setRequestor ] = useState('');
  const [ department, setDepartment ] = useState("");
  const [ otherDepartment, setOtherDepartment ] = useState ('');
  const [ courseTeam, setCourseTeam ] = useState('');
  const [ gradeLevels, setGradeLevels ] = useState([]);

  const handleCaptcha = val => setCaptcha(val);

  const handleChange = _.debounce((element,value,checked) => {

    switch(element) {

      case 'softwareName':
        setName(value);
        break;

      case 'companyName':
        setCompany(value);
        break;

      case 'vendorPhone':
        setPhone(value);
        break;

      case 'vendorEmail':
        setEmail(value);
        break;

      case 'softwareStatus':
        setStatus(value);
        break;

      //  DEAL WITH ARRAY
      case 'licenseType':

        let licenseArr = licenseType;

        if(!checked) {
          let filteredLicenses = licenseArr.filter(item => item != value);
          setLicenseType([ ...filteredLicenses ]);
          console.dir(filteredLicenses);
          return;
        }

        if(licenseArr.includes(value)) {
          return;
        }

        licenseArr.push(value);

        setLicenseType([ ...licenseArr ]);

        break;

      case 'anticipatedCost':
        setAnticipatedCost(value);
        break;

      case 'softwareSite':
        setSite(value);
        break;

      case 'dateNeeded':
        setDateNeeded(value);
        break;

      case 'users':
        setUsers(value);
        break;

      case 'systemInstalls':
        setSystemInstalls(value);
        break;

      case 'standards':
        setStandards(value);
        break;

      case 'useDescription':
        setUseDescription(value);
        break;

      case 'requestor':
        setRequestor(value);
        break;

      case 'department':
        setDepartment(value);
        break;

      case 'otherDepartment':
        setOtherDepartment(value);
        setDepartment(value);
        break;

      case 'courseTeam':
        setCourseTeam(value);
        break;

      //  DEAL WITH ARRAY
      case 'gradeLevel':

        let gradesArr = gradeLevels;

        if(!checked) {
          let filteredGrades = gradesArr.filter(item => item != value);
          setGradeLevels([ ...filteredGrades ]);
          console.dir(filteredGrades);
          return;
        }

        if(gradesArr.includes(value)) {
          return;
        }

        gradesArr.push(value);

        setGradeLevels([ ...gradesArr ]);

        break;

    }

  },600)

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    if(!user) {
      return;
    }

    if( !department || gradeLevels.length <= 0 || licenseType.length <= 0) {

      alert('The form is not complete.')

      return;

    }

    //  VERIFY CAPTCHA RESULT
    let captcha_options = {
      method: 'Post',
      body: JSON.stringify({
        client_response: captcha_val,
        server_key: RECAPTCHA_SERVER_KEY
      })
    }
    let captcha_url = "https://us-central1-manage-it-extension.cloudfunctions.net/verifyReCaptcha";

    setLoading(true);

    let verify_response = await fetch(captcha_url,captcha_options);

    let json = await verify_response.json();

    if(!json.success) {
      console.log('You failed the captcha.');
      setLoading(false);
      return;
    }

    let formObj = {};

    formObj.softwareName = softwareName;
    formObj.companyName = companyName;
    formObj.vendorPhone = vendorPhone;
    formObj.vendorEmail = vendorEmail;
    formObj.softwareStatus = softwareStatus;
    formObj.licenseType = licenseType;
    formObj.anticipatedCost = anticipatedCost;
    formObj.softwareSite = softwareSite;
    formObj.dateNeeded = dateNeeded;
    formObj.users = users;
    formObj.systemInstalls = systemInstalls;
    formObj.standards = standards;
    formObj.useDescription = useDescription;
    formObj.requestor = requestor;
    formObj.department = department;
    formObj.otherDepartment = otherDepartment;
    formObj.courseTeam = courseTeam;
    formObj.gradeLevels = gradeLevels;
    formObj.user = user;

    console.dir(formObj);

    let options = {
      method: 'Post',
      body: JSON.stringify(formObj)
    }

    //  SUBMIT FORM REQUEST
    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/submitSoftwareRequest";

    try {

      let initSend = await fetch(url,options);
      let textRes = await initSend.text();
      if(initSend.ok) {
        setLoading(false);
        e.target.reset();
        alert(textRes);
        return;
      }
      setLoading(false);
      alert('There was an issue.  Please try again later.');

    } catch (e) {
      e.target.reset();
      console.log('ERROR',e);
      setLoading(false);
      alert('There was an error.  Please try again later.');
    }

  }

  return (
    <div className={classes.root}>
      <HeaderComponent title={ domain.toUpperCase() + " | App Request Form" || "" } />

      <div className={classes.formDiv}>

        <Paper className={classes.formPaper} elevation={3}>
        <Typography
          variant="h4"
          component='h1'
          align="center"
        >Software Request Form</Typography>

        {
          user && (
            <Typography
              style={{ marginTop: '20px' }}
              align='center'
              variant="subtitle2"
              component="h1"
            ><span style={{color: 'red'}}>Submitting as</span>: <b>{ user.toUpperCase() }</b></Typography>
          )
        }


          <form
            onSubmit={handleSubmit}
            className={classes.form}
          >

            <TextField
              label="Name of the software/Web Service"
              name="softwareName"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Name of Company"
              name="companyName"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Vendor Phone (if applicable)"
              name="vendorPhone"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
            />

            <TextField
              label="Vendor Email (if applicable)"
              name="vendorEmail"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
            />

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Choose One Item Below</FormLabel>
              <RadioGroup aria-label="software-status" name="softwareStatus" value={softwareStatus} onChange={e => {
                    handleChange(e.target.name,e.target.value)
                }}
              >
                <FormControlLabel value="Upgrade" control={<Radio />} label="Upgrade" />
                <FormControlLabel value="New" control={<Radio />} label="New" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Type of license to be purchased.</FormLabel>
              <FormGroup>

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('Single CD-ROM')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="Single CD-ROM" />}
                  label="Single CD-ROM"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('Multiple CD-ROM')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="Multiple CD-ROM" />}
                  label="Single CD-ROM"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('One-time Single Computer Download/Installation')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="One-time Single Computer Download/Installation" />}
                  label="One-time Single Computer Download/Installation"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('One-time Multi-Computer Download/Installation')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="One-time Multi-Computer Download/Installation" />}
                  label="One-time Multi-Computer Download/Installation"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('Single Site License')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="Single Site License" />}
                  label="Single Site License"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('Per Student Site License')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="Per Student Site License" />}
                  label="Per Student Site License"
                />

                <FormControlLabel
                  control={<Checkbox checked={licenseType.includes('Other')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="licenseType" value="Other" />}
                  label="Other"
                />

              </FormGroup>
            </FormControl>

            <TextField
              label="Anticipated Cost (e.g. $1.99/student, $100, etc.)"
              name="anticipatedCost"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
            />

            <TextField
              label="Software website."
              name="softwareSite"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Date needed by."
              name="dateNeeded"
              helperText="MM/DD/YYYY"
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Who will be using this software? (e.g. Teachers, Students, and/or Admins)"
              name="users"
              multiline
              rows={2}
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Where will it need to be installed? (e.g. Chromebooks, teacher laptops, etc.)"
              name="systemInstalls"
              multiline
              rows={3}
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="List any and all Curriculum Standards and/or School/District Improvement Goals that this software supports."
              name="standards"
              multiline
              rows={3}
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="How will the software be used?"
              name="useDescription"
              multiline
              rows={3}
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Name of Requestor"
              name='requestor'
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
              required
            />

            <TextField
              label="Email Address"
              name="email"
              value={user}
              disabled
              className={classes.textField}
            />

            <FormControl component="fieldset">
              <InputLabel>Select Department</InputLabel>
              <Select
                name="department"
                onChange={e => {
                    handleChange(e.target.name,e.target.value)
                }}
                value={department}
              >
                <MenuItem value="Applied Technology">Applied Technology</MenuItem>
                <MenuItem value="Art and Music">Art</MenuItem>
                <MenuItem value="Business Education">Business Education</MenuItem>
                <MenuItem value="Computer Science">Computer Science</MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Family and Consumer Science">Family and Consumer Science</MenuItem>
                <MenuItem value="Math">Math</MenuItem>
                <MenuItem value="Physical Education">Physical Education</MenuItem>
                <MenuItem value="Science">Science</MenuItem>
                <MenuItem value="Social Studies">Social Studies</MenuItem>
                <MenuItem value="Special Education">Special Education</MenuItem>
                <MenuItem value="World Language">World Language</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>


            <TextField
              label="If you indicated OTHER in the previous question, please enter your department."
              name="otherDepartment"
              className={classes.textField}
              onChange={e => {
                handleChange(e.target.name,e.target.value)
              }}
            />

            <TextField
              label="If intended for a particular course/team, please enter the course/team."
              name='courseTeam'
              onChange={e => {
                  handleChange(e.target.name,e.target.value)
              }}
              className={classes.textField}
            />

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Grade Level</FormLabel>
              <FormGroup className={classes.checkBoxes}>
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('Kindergarten')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="Kindergarten" />}
                  label="Kindergarten"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('1st Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="1st Grade" />}
                  label="1st Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('2nd Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="2nd Grade" />}
                  label="2nd Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('3rd Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="3rd grade" />}
                  label="3rd Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('4th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="4th Grade" />}
                  label="4th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('5th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="5th Grade" />}
                  label="5th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('6th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="6th Grade" />}
                  label="6th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('7th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="7th Grade" />}
                  label="7th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('8th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="8th Grade" />}
                  label="8th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('9th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="9th Grade" />}
                  label="9th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('10th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="10th Grade" />}
                  label="10th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('11th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="11th Grade" />}
                  label="11th Grade"
                />
                <FormControlLabel
                  control={<Checkbox checked={gradeLevels.includes('12th Grade')}
                  onChange={e => {
                      handleChange(e.target.name,e.target.value,e.target.checked)
                  }}
                  name="gradeLevel" value="12th Grade" />}
                  label="12th Grade"
                />
              </FormGroup>
            </FormControl>

            <ReCAPTCHA
              sitekey={ RECAPTCHA_SITE_KEY }
              onChange={handleCaptcha}
            />

            <Button
              variant='contained'
              color='secondary'
              type='submit'
              className={ classes.submitBtn }
            >
              Submit
            </Button>
          </form>
        </Paper>

      </div>

      <BackDrop loading={loading} />
    </div>
  )
}

export default RequestFormComponent
