import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Divider,
  CheckBox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';
import ConfirmDialog from './confirm.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '10vh',
    paddingBottom: '10vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(5),
    margin: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(2)
  },
  videoDesc: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  templateLink: {
    margin: theme.spacing(5)
  }
}))

const GettingStartedComponent = props => {

  const classes = useStyles();

  const [ loading, setLoading ] = useState(false);

  return (
    <div className={classes.root}>

    <CustomHeader title='Getting Started' />

    <Paper className={classes.paper} elevation={3}>

      <Typography
        variant="h3" align="center"
        className={classes.title}
      >
        UPLOAD USERS
      </Typography>

      <div className={classes.videoDesc}>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/vaDhSZoU6_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <a
        href='https://docs.google.com/spreadsheets/d/1wLVKbYVMNcXMzlws6EdotUueJOihURXgwbraldpQkV8/edit#gid=0'
        target="_blank"
      >
        <Typography variant='h6' component='h1' className={classes.templateLink}>
          Upload Users Template
        </Typography>
      </a>

      </div>

    </Paper>

    <Paper className={classes.paper} elevation={3}>

      <Typography
        variant="h3" align="center"
        className={classes.title}
      >
        UPLOAD APPS
      </Typography>

      <div className={classes.videoDesc}>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/vaDhSZoU6_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <a
        href='https://docs.google.com/spreadsheets/d/1wR719yBEPl34txcrNgiTtcAoTK-Ycbc41exu9noagbQ/edit#gid=0'
        target="_blank"
      >
        <Typography variant='h6' component='h1' className={classes.templateLink}>
          Upload Apps Template
        </Typography>
      </a>

      </div>

    </Paper>

    <Paper className={classes.paper} elevation={3}>

      <Typography
        variant="h3" align="center"
        className={classes.title}
      >
        UPLOAD RESOURCES
      </Typography>

      <div className={classes.videoDesc}>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/vaDhSZoU6_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <a
        href='https://docs.google.com/spreadsheets/d/1wYjSb09F89I-He4o9BhzfCvHcsj3MtSK1O3Hlm0k-k8/edit#gid=0'
        target="_blank"
      >
        <Typography variant='h6' component='h1' className={classes.templateLink}>
          Upload Resources Template
        </Typography>
      </a>

      </div>

    </Paper>

    <Paper className={classes.paper} elevation={3}>

      <Typography
        variant="h3" align="center"
        className={classes.title}
      >
        UPLOAD DIRECTORY
      </Typography>

      <div className={classes.videoDesc}>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/vaDhSZoU6_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <a
        href='https://docs.google.com/spreadsheets/d/1fJcPqKmbbZT-xciKpOJpcVlMV_DDhirxAofvP_GPx6w/edit#gid=0'
        target="_blank"
      >
        <Typography variant='h6' component='h1' className={classes.templateLink}>
          Upload Directory Template
        </Typography>
      </a>

      </div>

    </Paper>

    <Paper className={classes.paper} elevation={3}>

      <Typography
        variant="h3" align="center"
        className={classes.title}
      >
        INSTALL MANAGE IT EXTENSION
      </Typography>

      <div className={classes.videoDesc}>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/vaDhSZoU6_Y" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <a
        href='https://docs.google.com/spreadsheets/d/1fJcPqKmbbZT-xciKpOJpcVlMV_DDhirxAofvP_GPx6w/edit#gid=0'
        target="_blank"
      >
        <Typography variant='h6' component='h1' className={classes.templateLink}>
          Manage iT Extension
        </Typography>
      </a>

      </div>

      <Typography variant="h6" component="h1" align='center' className={classes.title}>
        Here is a <a href="https://support.google.com/chrome/a/answer/6306504?hl=en" target="_blank">link to Google's documentation</a> on how to install apps and extension for your domain.
      </Typography>

    </Paper>

    </div>
  )
}

export default GettingStartedComponent
