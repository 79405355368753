import React, { useReducer } from 'react';

import MainContext from './main.context';
import userReducer from '../reducers/user.reducer';
import { mainReducer, initialState } from '../reducers/main.reducer';


import { firebaseApp } from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';

import '../../index.css';

const GlobalState = props => {

  const [ userState, userDispatch ] = useReducer(userReducer,{
    authUser: null
  });
  const [ mainState, mainDispatch ] = useReducer(mainReducer,initialState);

  return (
    <MainContext.Provider
      value={{
        userState:userState,
        userDispatch: userDispatch,
        mainState: mainState,
        mainDispatch: mainDispatch
      }}
    >
      { props.children }
    </MainContext.Provider>
  )
}

export default GlobalState
