import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  policyframe: {
    marginTop: '10vh',
    flexGrow: 1,
    height: 1500,
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: 1000
    },
    [theme.breakpoints.down('sm')]: {
      width: 900
    },
    [theme.breakpoints.down('xs')]: {
      width: 600
    },
  }

}));

const ManageUsersComponent = props => {
  const [ loading, setLoading ] = useState(false);
  const classes = useStyles();
  return (
    <iframe
      className={classes.policyframe}
      frameBorder={0}
      src="https://docs.google.com/document/d/e/2PACX-1vRUQxxCQ-X9wmVepMHZMktIpOreeKG-zcYaUAp4mtFIAh2DtWaMdMEPUg4GXm0AmN56DmgkyfYc_PCU/pub?embedded=true"></iframe>
  )
}

export default ManageUsersComponent
