import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import moment from 'moment';
import { firebaseApp } from '../../../firebase';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	    display: 'flex',
	    flexDirection: 'column',
	    justifyContent: 'center',
	    minWidth: 500
	},
	card: {
		minWidth: 500,
		maxWidth: 650,
		margin: theme.spacing(2),
		wordWrap: 'break-word'
	},
	link: {
		margin: theme.spacing(1)
	},
	hr: {
		margin: theme.spacing(2)
	}

}));

const SentAnnouncementsComponent = () => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ sentAnnouncements, setAnnouncements ] =  useState([]);

	useEffect(() => {
		mainDispatch({ type: 'setLoading', payload: true });

		firebaseApp.firestore()
			.collection('domains').doc(mainState.domainData.domain)
			.collection('announcements')
			.get()
			.then(snapshot => {
				if(snapshot.docs.length <= 0) {
					mainDispatch({ type: 'setLoading', payload: false });
					return;
				}
				let tempArr = snapshot.docs.map(each => {
					let data = each.data();
					data.id = each.id;
					data.milli = new Date(data.announcementDate).getTime();
					return data;
				});

				tempArr.sort((a,b) => b.milli - a.milli);

				setAnnouncements(tempArr);
				mainDispatch({ type: 'setLoading', payload: false });
			})
			.catch(err => {
				console.log(err);
				mainDispatch({ type: 'setLoading', payload: false });
			});

	},[]);



	if(sentAnnouncements.length <= 0 ) {
		return (
			<div className={classes.root}>
				<Typography
					variant="h6"
					component="h1"
					align="center"
				>There are no announcements.</Typography>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{
				sentAnnouncements.map(announcement => 
					<Card className={classes.card} key={announcement.title}>
						<CardHeader
							title={announcement.title}
							subheader={announcement.announcementDate}
						/>
						<CardContent>
							<Typography
								component="h1"
								variant="h6"
							>Message</Typography>
							<Typography>
								{announcement.desc}
							</Typography>

							<Typography
								component="h1"
								variant="h6"
							>Linked Resources</Typography>
							<Typography>
								{
									announcement.url1 ?
										<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url1} 
												target="_blank">Resource Link 1</a>
										</React.Fragment>
									: ""
								}
							</Typography>
							<Typography>
								{
									announcement.url2 ?
										<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url2} 
												target="_blank">Resource Link 2</a>
										</React.Fragment>
									: ""
								}
							</Typography>
							<Typography>
								{
									announcement.url3 ?
										<React.Fragment>
											<span> - </span>
											<a 
												className={classes.link} 
												href={announcement.url3} 
												target="_blank">Resource Link 3</a>
										</React.Fragment>
									: ""
								}
							</Typography>
							<hr className={classes.hr}/>
							<Typography className={classes.link}><b>Submitted By:</b> {announcement.submittedBy} on {moment(new Date(announcement.createdAt)).format('ddd, MMM Do YYYY')}</Typography>
							<Typography className={classes.link}>
								<b>Opened:</b> {announcement.hasOwnProperty('clickCount') ? announcement.clickCount : 0}
							</Typography>
						</CardContent>
					</Card>
				)
			}
		</div>
	)
}

export default SentAnnouncementsComponent
