/*

	NOTE:  When the SDPC API is functional, the following needs to happen:
		1.  Have users select state
		2.  Need to check all of the SDPC API components and methods that they are all re-enabled.

 */

import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Snackbar,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import MainContext from '../../state/main.context';
import Backdrop from '../../backdrop.component';
import CloseIcon from '@material-ui/icons/Close';
import { firebaseApp } from '../../../firebase';

const states = ['IL','CA','FL','MD','ME','MO','MT','OH','OR','RI','TX','UT','VA','VT','WA','WI'];

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: theme.spacing(5),
		paddingBottom: theme.spacing(5)
	},
	dialog: {
		padding: theme.spacing(2)
	},
	form: {
		border: '1px solid lightgrey',
		borderRadius: '5px',
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing(1)
	},
	formItem: {
		margin: theme.spacing(1),
		fontSize: '1.25em'
	},
	btn: {
		margin: theme.spacing(1)
	},
	btn2: {
		margin: theme.spacing(1),
		width: '50px'
	},
	learnBtn: {
	    display: 'flex',
	    justifyContent: 'center',
	    margin: theme.spacing(1)
	},
	select: {
		fontSize: '1.25em',
		minWidth: 200
	},
	formControl: {
	    margin: theme.spacing(1),
	    minWidth: 120,
	},
	selectEmpty: {
	    marginTop: theme.spacing(2),
	}
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const ActionItems = ({rowData, addAgreement, toggleMergeConfirm}) => {
  
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ added, setAdded ] = useState(false);
  const [ merged, setMerged ] = useState(false);
  const [ showAppList, setShowList ] = useState(false);
  const [ appToMerge, setAppToMerge ] = useState(null);

  const initAddAgreement = () => {

    let proceed = window.confirm(`Agreement information for ${rowData.softwareName} along with other information will be added to your apps list.  Proceed?`);

    if(proceed) {
      addAgreement(rowData);
      setAdded(true);
    }
  
  }

  const initMergeAgreement = () => {
    setShowList(true);
  }

  const handleClose = () => {
    setShowList(false);
  }

  const handleSubmit = async (e) => {

	e.preventDefault();
	let selectedAppId = e.target[0].value;

	let appData = null;

	mainState.apps.some(app => {
	  if(app._id == selectedAppId) {
	    appData = app;
	    return true;
	  }
	  return false;
	})

	let tempObj = {};
	tempObj.approvalDate = appData.approvalDate;
	tempObj.expirationDate = appData.expirationDate;

	if(appToMerge.approvalDate) {
		tempObj.approvalDate = appToMerge.approvalDate;
	}

	if(appToMerge.expirationDate) {
		tempObj.expirationDate = appToMerge.expirationDate;
	}

	//  NOTE:  Need to use when API is back.
	//  IF THERE IS AN AGREEMENT DATE
	// if(appToMerge.agreementDates) {
	//   //  PARSE DATES
	//   let splitDate = appToMerge.agreementDates.split('Approved:').join("").split("Expires:") || null;
	//   if(splitDate && splitDate.length > 1) {
	//     tempObj.approvalDate = splitDate[0].trim();
	//     tempObj.expirationDate = splitDate[1].trim();
	//   }
	// }

	tempObj.contentArea = appToMerge.contentArea;
	tempObj.gradeLevel = appToMerge.gradeLevel;
	tempObj.agreementType = [];

	if(appToMerge.agreementType.length > 0) {
		for(const agreement of appToMerge.agreementType) {
			tempObj.agreementType.push({
				title: agreement.agreementTitle,
				url: agreement.agreementLink
			})
		}
	}

	//  UPDATE THE APP USING THE TEMPOBJ CREATED
	await firebaseApp.firestore()
		.collection('domains').doc(mainState.domain)
		.collection('apps').doc(selectedAppId)
		.update(tempObj);

	handleClose();
	toggleMergeConfirm();

  }

  return (
    <div>
		
		<HtmlTooltip
			placement='left'
			title={
				<React.Fragment>
					<Typography>
					Add app to your Apps Dashboard along with agreement information.
					</Typography>
				</React.Fragment>
			}
		>
			<Button
				className={classes.btn2}
				color='primary'
				variant='contained'
				disabled={added}
				onClick={initAddAgreement}
			>
			Add
			</Button>
		</HtmlTooltip>

		<HtmlTooltip
			placement='left'
			title={
				<React.Fragment>
					<Typography>
					Merge app's <i>Agreement Type, Agreement Dates, Grade Level </i> and <i> Content Area</i> to an existing app in your management dashboard.
					</Typography>
				</React.Fragment>
			}
		>
			<Button
				className={classes.btn2}
				color='secondary'
				variant='contained'
				disabled={merged}
				onClick={() => {
				  setAppToMerge(rowData);
				  initMergeAgreement();
				}}
			>Merge</Button>
		</HtmlTooltip>

		<Dialog 
			className={classes.dialog}
			open={showAppList} 
			onClose={handleClose}
		>
		<DialogTitle>Merging SDPC Data</DialogTitle>
		<DialogContent>
		  <Typography>
		    Merging SDPC data to an app that you have on your dashboard means that the following information from SDPC will be added to the selected app.
		  </Typography>
		  <ul>
		    <li>Agreement Type</li>
		    <li>Agreement Dates</li>
		    <li>Grade Level</li>
		    <li>Content Area</li>
		  </ul>
		  <Typography>NOTE: </Typography> 
		  <ul>
		    <li>You will be able to update any of the information that is merged from the Apps Dashboard.</li>
		    <li>You can always merge again later if your data in SDPC has updated.</li>

		  </ul>


		  <form 
		  	className={classes.form}
		  	onSubmit={handleSubmit} 
		  >
		    <select 
		    	className={classes.formItem}
		    	required
		    >
		      <option value="">Select App</option>
		      {
		        mainState.apps.map(app => {
		          return <option value={app._id} key={app._id}>{app.company}</option>
		        })
		      }
		    </select>
		    <Button 
		    	color='primary'
		    	variant='contained'
		    	className={classes.btn}
		    	type='submit'
		    >Merge App</Button>
		    <Button 
				className={classes.btn}
				variant='contained'
		    	type='cancel' 
				onClick={() => {
					setAppToMerge(null);
					setShowList(false);
				}}
		    >Cancel</Button>
		  </form>
		</DialogContent>
		</Dialog>

    </div>
  )
}

const AgreeementsComponent = props => {

	const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
	const classes = useStyles();
	const [ loading, setLoading ] = useState(false);
	const [ data, setData ] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [appToAdd, setAppToAdd] = useState(null);
	const [addedApps, setAddedApps] = useState([]);
	const [ confirmMerge, setConfirmMerge ] = useState(false);
	const [ selectedState, setSelectedState ] = useState(true); 

	const [ showInfo, setShowInfo ] = useState(false);

	useEffect(() => {
		
		// getAgreements();
		getDistrictAgreements();

	},[]);

	const getAgreements = async (state) => {

		setLoading(true);

		let storageRef = firebaseApp.storage().ref(`sdpc/${state}`);

		try {
			
			let url = await storageRef.getDownloadURL();

			let res = await fetch(url);
			let json = await res.json();

			console.dir(json);
			setData(json);

		} catch(e) {
			console.log(`ERROR: ${ e.message }`);
		} finally {
			setLoading(false);
		}

		

		/*  USE THIS WHEN SDPC GETS THEIR API WORKING PROPERLY

			setLoading(true);
			
			let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getSDPCFile";

			let options = {
				method: 'Post',
				body: JSON.stringify({ state: state })
			}

			try {

				let response = await fetch(url,options);

				console.dir(response);

				if(response.ok) {
					let json = await response.json();
					console.dir(json);
					setData(json);
				}
				
			} catch(e) {
				// statements
				
				console.log(`ERROR: ${e.message}`);
			} finally {
				setLoading(false);
			}
		//*/

	}  //  GET AGREEMENT

	const getDistrictAgreements = async () => {
		setLoading(true);

		console.dir(props);
		
		let { domain } = props.domainData;
		
		if(!domain) {
			console.log('There is no domain...');
			setLoading(false);
			return;
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/SDPC_Scrape_District";

		let options = {
			method: 'Post',
			body: JSON.stringify({ domain: domain })
		}

		try {
			let initFetch = await fetch(url,options);
			if(initFetch.ok) {
				let json = await initFetch.json();
				console.dir(json);
				setData(json);
			}
			setLoading(false);
		} catch(e) {
			// statements
			console.log(e.message);
			setLoading(false);
		}

	}


	const addAgreement = async (rowData) => {

		let obj = {};

		//  PARSE AGREEMENT DATES
		/*  WHEN API WORKS
		let splitDates = rowData.agreementDates.split('Approved:').join("").split("Expires:");
		obj.approvalDate = splitDates[0].trim();
		obj.expirationDate = splitDates[1].trim();
		*/
	
		obj.approvalDate = rowData.approvalDate;
		obj.expirationDate = rowData.expirationDate;
		obj.company = rowData.softwareName;
		obj.agreementType = rowData.agreementType;
		obj.agreementStatus = rowData.status == 'Active' ? "Approved" : "";
		obj.contentArea = rowData.contentArea;
		obj.gradeLevel = rowData.gradeLevel;
		obj.groups = [];
		obj.imgUrl = "";
		obj.isPublic = false;
		obj.notes = "";
		obj.title = rowData.softwareName;
		obj.url = rowData.softwareLink;
		obj.data = "";
		obj.desc = "";
		obj.clicks = [];
		obj.category = [];
		obj.agreementType = [];

		if(rowData.agreementType.length > 0) {
			for(const agreement of rowData.agreementType) {
				obj.agreementType.push({
					title: agreement.agreementTitle,
					url: agreement.agreementLink
				})
			}
		}

		//  CODE TO ADD TO DATABASE
		await firebaseApp.firestore()
    	.collection('domains').doc(mainState.domain)
    	.collection('apps').add(obj);

    	toggleMergeConfirm();

	}

	const toggleMergeConfirm = () => {
		setConfirmMerge(true);
	}

	const handleClose = (event,reason) => {
		if(reason === 'clickaway') {
			return;
		}

		setConfirmMerge(false);
	}

	const handleSelectedState = e => {
		setSelectedState(e.target.value);
		getAgreements(e.target.value);
	}

	const SelectStateForm = () => (
		<FormControl className={classes.formControl} variant="outlined">
	        <InputLabel>State</InputLabel>
	        <Select
	          value={selectedState}
	          onChange={handleSelectedState}
	          className={classes.select}
	          label='State'
	        >
	        	{
	        		states.map(state => 
						<MenuItem key={state} value={state}>{state}</MenuItem>
	        		)
	        	}
	        </Select>
	    </FormControl>
	)

	if(!selectedState) {
		return (
			<div className={classes.root}>

				<SelectStateForm />

			</div>
		)
	}

	const columns = [
		{  /*  NOTE:  NEED TO CHANGE THIS WHEN API IS ACTUALLY FUNCTIONAL */
		  title: "District",
		  field: "districtName",
		  render: (rowData) => (
		    <a href={rowData.districtLink} target="_blank">
		      { //rowData.districtName
		      	props.domainData.orgName || ""
		      }
		    </a>
		  )
		},
		{
		  title: "Software",
		  field: "softwareName",
		  render: (rowData) => (
		    <a href={rowData.softwareLink} target="_blank">
		      {rowData.softwareName}
		    </a>
		  )
		},
		{
		  title: "Agreement Status",
		  field: "status"
		},
		{
		  title: "Agreement Type",
		  render: (rowData) => {
		    if (rowData.agreementType.length <= 0) {
		      return <p></p>;
		    }

		    return (
		      <div>
		        {rowData.agreementType.map((agreement) => {
		          return (
		            <div key={agreement.agreementLink}>
		              {agreement.agreementLink ? (
		                <div>
		                  <a href={agreement.agreementLink} target="_blank">
		                    {agreement.agreementTitle
		                      ? agreement.agreementTitle
		                      : "Agreement"}
		                  </a>
		                  <br />
		                </div>
		              ) : (
		                <div>
		                  <p>{agreement.agreementTitle}</p>
		                  <br />
		                </div>
		              )}
		            </div>
		          );
		        })}
		      </div>
		    );
		  },
		  sorting: false
		},
		{
		  title: "Agreement Dates",
		  field: "agreementDates",
		  sorting: false
		},
		{
		  title: "Grade Level",
		  field: "gradeLevel",
		  sorting: false
		},
		{
		  title: "Content Area",
		  field: "contentArea",
		  sorting: false
		},
		{
		  title: 'Action',
		  sorting: false,
		  render: (rowData) => {
		    return (
		      <ActionItems
		        rowData={rowData} 
		        addAgreement={addAgreement}
		        toggleMergeConfirm={toggleMergeConfirm} 
		      />
		    );
		  }
		}
	];

	return (
		<div className={`sdpc-table ${classes.root}`}>

			{/*<SelectStateForm />*/}

			<MaterialTable
		        title="Student Data Privacy Consortium"
		        columns={columns}
		        data={data}
		        style={{
		          width: window.innerWidth * 0.9,
		          border: '1px solid lightgrey',
		          boderRadius: '5px'
		        }}
		        options={{
		          pageSize: 20,
		          headerStyle:{
		            backgroundColor: '#66549a',
		            color: '#ffffff'
		          },
		          sorting: true
		        }}
		        components={{
		          Toolbar: props => (
		            <div>
		              <MTableToolbar {...props} />

		              <div className={classes.learnBtn}>
		                <Button
		                  color='primary'
		                  onClick={() => setShowInfo(true)}
		                  style={{ fontSize: '1.25em' }}
		                >
		                  Learn More
		                </Button>

		              </div>
		              {
										(!loading && data.length <= 0)  && (
											<Typography style={{ margin: 10, color: 'red' }} align='center'>
											  SDPC agreements were not found on your account.
											</Typography>
										)
									}
		            </div>
		          )
		        }}
		    />
		
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				open={confirmMerge}
				autoHideDuration={5000}
		        onClose={handleClose}
		        message="Process Complete"
		        action={
		          <React.Fragment>
		            <Button color="secondary" size="small" onClick={handleClose}>
		              Close
		            </Button>
		            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
		              <CloseIcon fontSize="small" />
		            </IconButton>
		          </React.Fragment>
		        }
			/>

			<Dialog 
				open={showInfo}
				onClose={() => setShowInfo(false)}
			>
				<DialogTitle>SDPC Data Integration</DialogTitle>

				<DialogContent>

					<DialogContentText>
					The data on this table is the same data that you find on the Student Data Privacy Consortium.  The data is updated every night between 12:00am and 1:00am.	
					</DialogContentText>

					<DialogContentText>
					The action column allows you to ADD or MERGE data into your apps management dashboard.
					</DialogContentText>

					<DialogContentText>
					The <b>ADD</b> action will grab all SDPC data for the particular row and add the information to your apps management dashboard as a new line item.
					</DialogContentText>

					<DialogContentText>
					The <b>MERGE</b> action allows you to select a particular row and merge the information with an existing app that's already in your apps management dashboard.  For example, if you have BrainPop as a listed item on your dashboard and you want to merge agreement information from SDPC to the BrainPop item that you already have, the merge action will allow you to do that.  When merging, the <i>Agreement Type</i>, <i>Agreement Dates</i>, <i>Grade Level</i> and <i>Content Area</i> will be pulled from SDPC and added to the app that you selected.
					</DialogContentText>

				</DialogContent>

			</Dialog>

			<Backdrop loading={loading} />
		</div>
	)
}

export default AgreeementsComponent
