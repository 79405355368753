import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Divider,
  CheckBox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';
import ConfirmDialog from './confirm.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '10vh'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  finalCheckbox: {
    marginRight: theme.spacing(1)
  },
  finalCheckContainer: {
    margin: theme.spacing(2),
    textAlign: 'center'
  }
}))


const SignUpComponent = props => {
  const history = useHistory();
  const classes = useStyles();


  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const [ firstName, setFirstName ] = useState(null);
  const [ lastName, setLastName ] = useState(null);
  const [ showResponse, setShowResponse ] = useState(false);
  const [ response, setResponse ] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    if(e.target[12].value) {
      return;
    }

    setLoading(true);

    let obj = {};

    obj.orgName = e.target[0].value;
    obj.street = e.target[1].value;
    obj.city = e.target[2].value;
    obj.state = e.target[3].value;
    obj.zip = e.target[4].value;
    obj.num_users = e.target[5].value;
    obj.domains = e.target[6].value;
    obj.firstName = e.target[7].value;
    obj.lastName = e.target[8].value;
    obj.displayName = e.target[7].value + " " + e.target[8].value;
    obj.position = e.target[9].value;
    obj.email = e.target[10].value;
    obj.phone = e.target[11].value;
    obj.timestamp = new Date().getTime();

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/submitSignUp";

    try {

      let initSubmitSignUp = await fetch(url,options);
      let textRes = await initSubmitSignUp.text();
      setResponse("Successfully created organization and admin account.  You should receive an email shortly to finalize your account.  Please follow instructions and ensure that you are able to log in.  If you have any issues, please contact SUPPORT@APPROVEIT.APP");

      setShowResponse(true);
      setLoading(false);

    } catch (e) {

      console.log('ERROR',e);
      setLoading(false);
      setResponse('ERROR SIGNING UP.  PLEASE CONTACT SUPPORT@APPROVEIT.APP');
      setShowResponse(true);

    }

    e.target.reset();


  }


  return (
    <div className={classes.root}>

    <CustomHeader title='Sign Up' />

    <div className={classes.formContainer}>

    <Paper className={classes.paper} elevation={3}>

      <Typography variant="h3" align="center">
        Admin | Organization Sign-up
      </Typography>


      <form
        onSubmit={handleSubmit}
        className={classes.form}
      >

        <Typography variant="h6" component="h1" align="center">
          Organization Information
        </Typography>

        <TextField
          id='org-name'
          label='Name of Organization'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='street-addr'
          label='Street Address'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='city-addr'
          label='City'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='state-addr'
          label='State Code (e.g. IL)'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='zip-addr'
          label='Zip Code'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='user-number'
          label='Number of students in entire district.'
          helperText="Teachers and admins are free"
          type='number'
          margin='normal'
          required={true}
        />

        <TextField
          id='domains'
          label="Enter organization's domain and subdomains (separated by a comma)"
          helperText="This will be used for whitelisting purposes."
          type='text'
          margin='normal'
          required={true}
        />

        <Typography variant="h6" component="h1" align="center">
          Admin Information
        </Typography>

        <TextField
          id='admin-first'
          label='First Name'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='admin-last'
          label='Last Name'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='admin-position'
          label='Job Title/Position'
          type='text'
          margin='normal'
          required={true}
        />

        <TextField
          id='admin-email'
          label='Email'
          type='email'
          margin='normal'
          required={true}
        />

        <TextField
          id='admin-phone'
          label='Phone Number'
          type='tel'
          helperText="123-456-7890"
          margin='normal'
          required={true}
        />

        <input
          className={classes.formItem}
          type='text'
          value=''
          id='approve-it' hidden
          onChange={() => console.log('change')}
        />

        <label htmlFor='confirm-admin' className={classes.finalCheckContainer}>
          <input
            className={classes.finalCheckbox}
            id='confirm-admin'
            type='checkbox'
            required
          />
          I certify that I am an authorized user and/or administrator for the organization.
        </label>

        <Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={ loading }
        >
          Submit Information
        </Button>

      </form>
    </Paper>

    </div>

      <ConfirmDialog
        toOpen={showResponse}
        message={response}
      />
      <BackDrop loading={ loading } />
    </div>
  )
}

export default SignUpComponent
