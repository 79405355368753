import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  policyframe: {
    marginTop: '10vh',
    flexGrow: 1,
    height: 2000,
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: 1000
    },
    [theme.breakpoints.down('sm')]: {
      width: 900
    },
    [theme.breakpoints.down('xs')]: {
      width: 600
    },
  }

}));

const ManageDirectoryComponent = props => {
  const [ loading, setLoading ] = useState(false);
  const classes = useStyles();
  return (
    <iframe
      className={classes.policyframe}
      frameBorder={0}
      src="https://docs.google.com/document/d/e/2PACX-1vQPod_Bh2pIM9jOXbinWKyVyXUcXNZ79dyXm6t4O_L6MkLqGCYYAimLDSYfih1ULF4eg4jLw3v7Myu_/pub?embedded=true"></iframe>
  )
}

export default ManageDirectoryComponent
