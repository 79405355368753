import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import _ from 'lodash';
import moment from 'moment';

import { firebaseApp } from '../../../../firebase';
import { RecordActions } from '../../admin_actions';
import MainContext from '../../../state/main.context';
import BackDrop from '../../../backdrop.component';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from "material-table";
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import '../../../css/heat-map.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchField: {
    minWidth: 450
  },
  submitBtn: {
    cursor: 'pointer',
    minHeight: 30,
    minWidth: 30
  },
  noDataText: {
    marginTop: '10vh'
  },
  img: {
    width: 50,
    height: 50
  },
  detailPanel: {
    width: '100%'
  },
  summary: {
    backgroundColor: 'lightgrey',
    flexDirection: 'row-reverse'
  },
  heading: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold'
  },
  subheading: {
    marginLeft: theme.spacing(2)
  },
  recordsNum: {
    marginLeft: theme.spacing(2)
  },
  accordionDetails: {
    minWidth: '100%'
  },
  time: {
    maxWidth: 300,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  toolTipDiv: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    minWidth: 600,
    maxWidth: 600,
    minHeight: 200,
    maxHeight: 500,
    overflow: 'auto'
  },
  time2: {
    maxWidth: 100,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  heatmap: {
    minWidth: 500,
    margin: theme.spacing(2)
  },
}));

const HeatMap = ({records }) => {
  const classes = useStyles();
  const [ data, setData ] = useState([]);

  useEffect(() => {
    if(records.length > 0) {
      let tempData = records.reduce((acc,current) => {
        let date = moment(current).format('YYYY-MM-DD');
        let itemExist = false;

        acc.some(item => {
          if(item.date == date) {
            item.count++;
            item.time.push(current)
            itemExist = true;
            return true;
          }
          return false;
        })

        if(!itemExist){
          acc.push({
            date: date,
            count: 1,
            time: [current]
          })
        }

        return acc;
      },[]);
      setData(tempData);
    }
  },[]);

  return (
    <div className={classes.heatmap}>
      <CalendarHeatmap
        startDate={moment().subtract(90,'days')}
        endDate={new Date()}
        values={data}
        classForValue={value => {
          if (!value) {
            return 'color-empty';
          }
          return `color-github-${value.count}`;
        }}
        tooltipDataAttrs={value => {

          if(!value.date) {
            return {
              'data-tip': ''
            }
          } else {
            return {
              // 'data-tip': `${value.date} has count: ${
              //   value.count
              // }`,
              'data-tip':`${value.date}`
            };
          }

        }}
        showWeekdayLabels={true}
      />
      <ReactTooltip
        getContent={dataTip => {
          if(!dataTip) {
            return <Typography>No data for this day.</Typography>
          }

          let dateData = data.filter(d => d.date == dataTip);

          return (
            <div>

              <Typography align='center'>{moment(dataTip).format("ddd, MMMM Do YYYY")}</Typography>

              <div className={classes.toolTipDiv}>
              {
                dateData[0].time.sort((a,b) => b - a).map((record,i) =>

                    <Typography key={i} className={classes.time2}>
                      { moment(record).format("h:mm a") }
                    </Typography>

                )
              }
              </div>
            </div>
          )
        }}
        effect='solid'
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place={'right'}
        border={true}
      />
    </div>
  )
}

const SiteDetailPanel = ({ email, site, domain }) => {

  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);
  const classes = useStyles();

  useEffect(() => {

    firebaseApp.firestore()
      .collection('domains').doc(domain)
      .collection('users').doc(email)
      .collection('site_visits').doc(site)
      .collection('urls').orderBy('last_updated')
      .limit(50).get()
      .then(urlSnaps => {

        if(urlSnaps.docs.length <= 0) {
          console.log('No Docs...');
          setLoading(false);
          return;
        }

        let tempData = [];

        urlSnaps.docs.forEach(doc => tempData.push(doc.data()));

        setData([ ...tempData ]);

        console.dir(tempData);

        setLoading(false);
      })
      .catch(err => {

        setLoading(false);
      })

  },[])

  return(
    <div className={classes.detailPanel}>

      {
        data.length <= 0 && (
          <Typography>No data available.</Typography>
        )
      }

      {
        data.length > 0 && (
          <div>
            {
              data.map(each =>
                <Accordion key={each.url} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                  >
                    <Typography className={classes.heading}> { each.title } </Typography>

                    <Typography className={classes.subheading}>
                     <a href={each.url} target="_blank">{ each.url }</a>
                    </Typography>

                    <Typography className={classes.recordsNum}>
                     { each.records.length }
                    </Typography>

                  </AccordionSummary>

                  <AccordionDetails
                    className={classes.accordionDetails}
                  >

                    <HeatMap records={each.records} />

                  </AccordionDetails>

                </Accordion>
              )
            }
          </div>
        )
      }

      <BackDrop loading={loading} />
    </div>
  )
}

const UserUsageComponent = props => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ emailToSearch, setEmailToSearch ] = useState('');
  const [ visitedSites, setVisitedSites ] = useState([]);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }
  },[]);

  const searchAccount = () => {

    let email = emailToSearch.toLowerCase().trim();

    if(!email) {
      return;
    }

    setLoading(true);

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('users').doc(email)
      .collection('site_visits').orderBy('last_visit','desc').limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0) {
          setLoading(false);
          alert('No data found.');
          return;
        }

        RecordActions(mainState.domain,mainState.authUser.email,`Search and Reviewed User: ${ email }`);

        let site_visits = [];

        //  GO THROUGH EACH DOC AND CREATE
        //  AN OBJECT ONLY FOR EACH ARRAY
        //  AND PUSH TO SITE VISITS WITH COUNT

        snapshot.docs.forEach(doc => site_visits.push(doc.data()))

        setVisitedSites([...site_visits]);

        setLoading(false);

      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
        alert('There was an error.  Please try again.');
      })

  }

  const keydown = e => {
    if(e.which === 13) {
      setVisitedSites([]);
      searchAccount();
    }
  }

  const columns = [
    {
      render: rowData => {
        if(rowData.hasOwnProperty('favIconUrl') && rowData.favIconUrl) {
          return (
            <img src={rowData.favIconUrl} className={classes.img} />
          )
        }
        return (
          <div></div>
        )
      }
    },
    {
      title: "Sites",
      field: 'title',
      render: rowData => (
        <Typography>{ rowData.hasOwnProperty('title') ? rowData.title.toUpperCase() : '' }</Typography>
      )
    },
    {
      title: "# Visits",
      field:'visits',
      defaultSort: 'desc',
      render: rowData => (
        <Typography>{ rowData.visits }</Typography>
      )
    },
    {
      title: "Last Visited",
      render: rowData => (
        <Typography>{ moment(rowData.last_visit).format("dd, MMM Do YYYY, h:mm a") }</Typography>
      )
    }
  ]

  return (
    <div className={classes.root}>

      <TextField
        onKeyDown={keydown}
        onChange={e => {
          setVisitedSites([]);
          setEmailToSearch(e.target.value)
        }}
        className={classes.searchField}
        variant="outlined"
        placeholder="Search email address..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon
                className={ classes.submitBtn }
                onClick={ searchAccount }
              />
            </InputAdornment>
          )
        }}
      />

      {
        visitedSites.length <= 0 && (
          <Typography align='center'
            component="h1"
            variant="subtitle1"
            className={classes.noDataText}
          >No Data.  Try searching...</Typography>
        )
      }

      {
        visitedSites.length > 0 && (
          <MaterialTable
            title={ emailToSearch ? emailToSearch.toUpperCase() : '' }
            data={visitedSites}
            columns={columns}
            style={{
              minWidth: '90vw',
              margin: '10px',
              marginTop: '30px'
            }}
            options={{
              search: true,
              sorting: true,
              pageSize: 20
            }}

            detailPanel={ rowData => {
                return (
                  <SiteDetailPanel
                    email={emailToSearch.toLowerCase()}
                    site={rowData.title}
                    domain={mainState.domain}
                  />
                )
            }}

            onRowClick={(event,rowData,togglePanel) => togglePanel() }

          />
        )
      }

      <BackDrop loading={loading} />

    </div>
  )
}

export default UserUsageComponent
