import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
}  from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainContext from '../state/main.context';
import CoursesDetailComponent from './course_detail.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100vw',
    // backgroundColor: fade(theme.palette.primary.main,0.40),
  },
  summary: {
    backgroundColor: 'lightgrey',
    flexDirection: 'row-reverse'
  },
  heading: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold'
  },

}));

const CoursesListComponent = ({ courses }) => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);

  return (
    <div className={classes.root}>
      {
        courses.map(course =>
          <Accordion
            key={course.id}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.summary}
            >
              <Typography className={classes.heading}>
              { course.name }
              </Typography>

            </AccordionSummary>

            <AccordionDetails>
              <CoursesDetailComponent courseId={course.id} />
            </AccordionDetails>
          </Accordion>
        )
      }
    </div>
  )
}

export default CoursesListComponent
