import React, { useEffect, useState, useContext } from 'react';
import { ContactSupport } from '@material-ui/icons';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.primary.main,.20),
    minHeight: '100vh',
    paddingTop: '10vh'
  },
  headline: {
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 'bolder',
    textShadow: '5px 5px lightgrey'
  },
  priceTitle: {
    fontSize: '1.25em',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  customText: {
    textAlign: 'center',
    fontSize: '1.5em',
    marginBottom: theme.spacing(2)
  },
  priceDesc: {
    fontSize: '1.25em'
  },
  paperContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw'
  },
  hr: {
    color: 'white',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  paper1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: fade('#e3335a',.75),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(5),
    flexGrow: 1,
    maxWidth: 400,
    minWidth: 350,
    minHeight: 500,
    margin: theme.spacing(5),
    color: '#ffffff'
  },
  paper2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(5),
    flexGrow: 1,
    maxWidth: 400,
    minWidth: 350,
    minHeight: 500,
    margin: theme.spacing(5),
    color: '#ffffff'
  },
  paperForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(5),
    flexGrow: 1,
    maxWidth: 400,
    margin: theme.spacing(5)
  },
  formItem: {
    margin: theme.spacing(1),
    fontSize: '1.5em',
    borderRadius: '5px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: 500,
    width: 350
  },
  confirmSent: {
    color: '#e3335a',
    margin: theme.spacing(1),
    textAlign: 'center',
    fontSize: '0.75em'
  },
  supportBtn: {
    width: '50px',
    height: '50px',
    fontSize: '1.5em',
    color: "#e3325a"
  },
  closeBtn: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: "#e3325a"
  },
  submitBtn: {
    fontSize: '1.5em',
    margin: theme.spacing(1),
    backgroundColor: "#e3325a",
    color: '#fff'
  },
  icon: {
    color: '#ffffff'
  },
  closeIcon: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  closeText: {
    // color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  closeTextSecondary: {
    // color: theme.palette.primary.main,
  },
  checkedIcon: {
    color: '#e3335a',
    fontWeight: 'bold'
  },
  checkedText: {
    // color: '#e3335a',
    fontWeight: 'bold'
  },
  checkedTextSecondary: {
    // color: '#e3335a'
  }
}));

const PricingInquiryComponent = props => {
  const context = useContext(MainContext);
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showForm, setShowForm ] = useState(false);
  const [ confirmSent, setConfirmSent ] = useState(false);
  const [ showError, setShowError ] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    //  CHECK HIDDEN INPUT
    if(e.target[0].value){
      return;
    }

    let obj = {};

    obj.purpose = e.target[1].value;
    obj.comment = e.target[2].value;
    obj.displayName = e.target[3].value;
    obj.email = e.target[4].value;
    obj.phone = e.target[5].value;
    obj.timestamp = new Date().getTime();

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/handleInquiries";

    setLoading(true);

    try {
      let initSendMessage = await fetch(url,options);
      let textRes = await initSendMessage.text();
      if(initSendMessage.ok) {
        setLoading(false);
        initConfirm();
        e.target.reset();
        return;
      }
      setLoading(false);
      initError();
      e.target.reset();
    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
      initError();
    }
  }

  const initConfirm = () => {
    setConfirmSent(true);
    setTimeout(() => {
      setConfirmSent(false);
    },4000);
  }

  const initError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    },4000);
  }

  return (
    <div className={classes.root}>

      <div>
        <Typography className={classes.headline} variant="h4">
          Welcome to Manage iT!  We are here to assist you.
        </Typography>
      </div>

      <div className={classes.paperContainer}>

        <Paper className={classes.paper1}>

          <Typography className={classes.priceTitle}>
            Schools | Districts | Other Organizations
          </Typography>

          <hr className={classes.hr}/>

          <Typography className={classes.customText}>
            <span style={{fontWeight: 'bold'}}>STANDARD</span> | $1/student/year
          </Typography>

          <hr className={classes.hr}/>

          <List component="nav">

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Apps Management Dashboard"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Automated Public Disclosure Site"
                secondary={<Typography>Eg. SOPPA (Illinois), SOPIPA (California)</Typography>}
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Chrome Apps and Extensions Usage Data"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Web Application Usage Data"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Operator Contract Expiration Alerts"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Customizable Chrome Extension"
                secondary={<Typography>Apps, Resources and Directory</Typography>}
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                className={classes.closeText}
                primary="Google Classroom Admin"
                secondary={
                  <Typography>Manage Google Classroom courses</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Teacher Dashboard"
                secondary={
                  <Typography>Access Google Classroom Students' Insight Data</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Send Announcements and Notifications"
                secondary={
                  <Typography>Schedule or send announcements and notify all of your Chrome users.</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CloseIcon className={classes.closeIcon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    className={classes.closeText}
                  >Individual User Insights</Typography>
                }
                secondary={
                  <Typography
                    className={classes.closeTextSecondary}
                  >Website visits, Time spent on websites and more!</Typography>
                }
              />
            </ListItem>

          </List>

        </Paper>

        <Paper className={classes.paper2}>

          <Typography className={classes.priceTitle}>
            Schools | Districts | Other Organizations
          </Typography>

          <hr className={classes.hr}/>

          <Typography className={classes.customText}>
            <span style={{fontWeight: 'bold'}}>PREMIUM</span> | $3/student/year
          </Typography>

          <hr className={classes.hr}/>

          <List component="nav">

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Apps Management Dashboard"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Automated Public Disclosure Site"
                secondary={<Typography>Eg. SOPPA (Illinois), SOPIPA (California)</Typography>}
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Chrome Apps and Extensions Usage Data"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Web Application Usage Data"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Operator Contract Expiration Alerts"
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Customizable Chrome Extension"
                secondary={<Typography>Apps, Resources and Directory</Typography>}
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                className={classes.closeText}
                primary="Google Classroom Admin"
                secondary={
                  <Typography>Manage Google Classroom courses</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Teacher Dashboard"
                secondary={
                  <Typography>Access Google Classroom Students' Insight Data</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText
                primary="Send Announcements and Notifications"
                secondary={
                  <Typography>Schedule or send announcements and notify all of your Chrome users.</Typography>
                }
              />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CheckIcon className={classes.checkedIcon}/>
              </ListItemIcon>
              <ListItemText
                className={classes.checkedText}
                primary={
                  <Typography
                    className={classes.checkedText}
                  >Individual User Insights</Typography>
                }
                secondary={
                  <Typography
                    className={classes.checkedTextSecondary}
                  >Website visits, Time spent on websites and more!</Typography>
                }
              />
            </ListItem>

          </List>

        </Paper>

        <Paper className={classes.paperForm}>

          <form onSubmit={handleSubmit} className={classes.form}>

            <Typography
              align='center'
              component="h1"
              variant="h6"
            >Contact us today!</Typography>

            <input
              className={classes.formItem}
              type='text'
              value=''
              onChange={() => console.log('hello world')}
              id='approve-it' hidden
            />

            <select className={classes.formItem}  required>
              <option value="">Select Purpose</option>
              <option value="Pricing">Pricing</option>
              <option value="Demo Request">Demo Request</option>
              <option value="Feature Request">Feature Request</option>
              <option value="Support">Support</option>
              <option value="Other">Other</option>
            </select>

            <textarea
              className={classes.formItem}
              rows={4}
              placeholder="Enter Comments Here"
              required
            ></textarea>

            <input
               className={classes.formItem}
              type='text'
              placeholder="Enter Your Name"
              required
            />

            <input
              className={classes.formItem}
              type="email"
              placeholder="Enter Email"
              required
            />

            <input
              className={classes.formItem}
              type="phone"
              placeholder="Enter Phone (optional)"
            />

            {
              confirmSent ?
                <p className={classes.confirmSent}>Message Sent!</p>
              : null
            }

            {
              showError ?
                <p className={classes.confirmSent}>Error Sending Message.  Please try again later.</p>
              : null
            }

            <Button
              variant="contained"
              type='submit'
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </form>

        </Paper>

      </div>

    <BackDrop loading={loading} />
    </div>
  )
}

export default PricingInquiryComponent


