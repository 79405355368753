import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import CourseAnnouncements from './course.announcements.component';
import CourseWorkComponent from './course.work.component';
import CourseTeachersComponent from './course.teachers.component';
import CourseStudentsComponent from './course.students.component';
import { RecordActions } from '../admin_actions';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(2)
	},
	paperTabs: {
	    display: 'flex',
	    flexDirection: 'column',
	    justifyContent: 'center',
	    alignItems: 'center',
	    margin: theme.spacing(1)
	},
}));

const CourseComponent = ({ course, teacher }) => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);
	const [ announcements, setAnnouncements ] = useState([]);
	const [ courseWork, setCourseWork ] = useState([]);
	const [ teachers, setTeachers ] = useState([]);
	const [ students, setStudents ] = useState([]);
	const [ tab, setTab ] = useState(0);

	console.dir(course);

	useEffect(() => {
		getAnnouncements();
	},[]);

	const getAnnouncements = async () => {

		mainDispatch({ type: 'setLoading', payload: true });

		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				courseId: course.id,
				teacherEmail: teacher
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseAnnouncements";

		try {
			
			let initFetch = await fetch(url,options);

			if(initFetch.ok) {
				let json = await initFetch.json();
				setAnnouncements(json);
				mainDispatch({ type: 'setLoading', payload: false });
				return;
			}

			mainDispatch({ type: 'setLoading', payload: false });

			setTimeout(() => {
				alert('There was an issue trying to retrieve announcements.  Please try again later.');
			},500);

		} catch(e) {
			// statements
			console.log(e);

			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue trying to retrieve announcements.  Please try again later.');
			},500);
		}

	}

	const getCourseWork = async () => {

		mainDispatch({ type: 'setLoading', payload: true });

		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				courseId: course.id,
				teacherEmail: teacher
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseWork";

		try {
			
			let initFetch = await fetch(url,options);

			if(initFetch.ok) {
				let json = await initFetch.json();
				setCourseWork(json);
				mainDispatch({ type: 'setLoading', payload: false });
				return;
			}

			mainDispatch({ type: 'setLoading', payload: false });

			setTimeout(() => {
				alert('There was an issue trying to retrieve course work.  Please try again later.');
			},500);

		} catch(e) {
			// statements
			console.log(e);

			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue trying to retrieve course work.  Please try again later.');
			},500);
		}
		
	}

	const getTeachers = async () => {

		mainDispatch({ type: 'setLoading', payload: true });

		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				courseId: course.id,
				teacherEmail: teacher
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseTeachers";

		try {
			
			let initFetch = await fetch(url,options);

			if(initFetch.ok) {
				let json = await initFetch.json();
				setTeachers(json);
				mainDispatch({ type: 'setLoading', payload: false });
				return;
			}

			mainDispatch({ type: 'setLoading', payload: false });

			setTimeout(() => {
				alert('There was an issue trying to retrieve course teachers.  Please try again later.');
			},500);

		} catch(e) {
			// statements
			console.log(e);

			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue trying to retrieve course teachers.  Please try again later.');
			},500);
		}
		
	}

	const reLoadTeachers = async () => {

		return new Promise( async (resolve,reject) => {

			let options = {
				method: 'Post',
				body: JSON.stringify({
					adminEmail: mainState.authUser.email,
					courseId: course.id,
					teacherEmail: teacher
				})
			}

			let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseTeachers";

			try {
				
				let initFetch = await fetch(url,options);

				if(initFetch.ok) {
					let json = await initFetch.json();
					setTeachers(json);
					return resolve();
				}

				return resolve();

			} catch(e) {
				// statements
				console.log(e);
				return resolve();
			}

		})
		
	}

	const getStudents = async () => {

		mainDispatch({ type: 'setLoading', payload: true });

		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				courseId: course.id,
				teacherEmail: teacher
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseStudents";

		try {
			
			let initFetch = await fetch(url,options);

			if(initFetch.ok) {
				let json = await initFetch.json();
				setStudents(json);
				mainDispatch({ type: 'setLoading', payload: false });
				return;
			}

			mainDispatch({ type: 'setLoading', payload: false });

			setTimeout(() => {
				alert('There was an issue trying to retrieve course students.  Please try again later.');
			},500);

		} catch(e) {
			// statements
			console.log(e);

			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue trying to retrieve course students.  Please try again later.');
			},500);
		}
		
	}

	const handleChange = (event, newValue) => {

		switch(newValue) {
			case 0:
				if(announcements.length <= 0 ) {
					getAnnouncements();
				}
				break;
			case 1:
				if(courseWork.length <= 0 ) {
					getCourseWork();
				}
				break;
			case 2:
				if(teachers.length <= 0 ) {
					getTeachers();
				}
				break;
			case 3:
				if(students.length <= 0 ) {
					getStudents();
				}
				break;
			default:
		}

	    setTab(newValue);
	};

	const addTeacher = async (teacherEmail) => {

		if(!teacherEmail) {
			return;
		}

		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				teacherEmail: teacherEmail,
				classroomTeacher: teacher,
				courseId: course.id
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseAddTeacher";

		mainDispatch({ type: 'setLoading', payload: true });

		try {
			let initAddTeacher = await fetch(url,options);
			if(initAddTeacher.ok) {
				await reLoadTeachers();
				mainDispatch({ type: 'setLoading', payload: false });
				RecordActions(mainState.domain,mainState.authUser.email,`Added ${ teacherEmail } as teacher to ${course.descriptionHeading}`);
			} else {
				let textRes = await initAddTeacher.text();
				mainDispatch({ type: 'setLoading', payload: false });
				alert(textRes);
			}
			
		} catch(e) {
			console.log(e);
			mainDispatch({ type: 'setLoading', payload: false });
			alert('There was an error adding teacher.  Please try again later or contact support@approveit.app.');
		}
	}

	const removeTeacher = async (teacherEmail) => {

		if(!teacherEmail) {
			return;
		}
		
		let options = {
			method: 'Post',
			body: JSON.stringify({
				adminEmail: mainState.authUser.email,
				teacherEmail: teacherEmail,
				classroomTeacher: teacher,
				courseId: course.id
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourseRemoveTeacher";

		mainDispatch({ type: 'setLoading', payload: true });

		try {
			let initRemoveTeacher = await fetch(url,options);
			if(initRemoveTeacher.ok) {
				await reLoadTeachers();
				mainDispatch({ type: 'setLoading', payload: false });
				RecordActions(mainState.domain,mainState.authUser.email,`Removed ${ teacherEmail } as teacher from ${course.descriptionHeading}`);
			} else {
				let textRes = await initRemoveTeacher.text();
				mainDispatch({ type: 'setLoading', payload: false });
				alert(textRes);
			}

		} catch(e) {
			console.log(e);
			mainDispatch({ type: 'setLoading', payload: false });
			alert('There was an error adding teacher.  Please try again later or contact support@approveit.app.');
		}
	}

	return (
		<div className={classes.root}>

			<div className={classes.paperTabs}>
		        <Paper square>
		          <Tabs
		            value={tab}
		            indicatorColor="primary"
		            textColor="primary"
		            onChange={handleChange}
		            centered
		          >
		            <Tab label="Announcements" />
		            <Tab label="Classwork" />
		            <Tab label="Teachers" />
		            <Tab label="Students" />
		          </Tabs>
		        </Paper>
		    </div>


		    {
		    	tab === 0 && (
		    		<CourseAnnouncements announcements={announcements} />
		    	)
		    }

		    {
		    	tab === 1 && (
		    		<CourseWorkComponent courseWork={courseWork} />
		    	)
		    }

		    {
		    	tab === 2 && (
		    		<CourseTeachersComponent
		    			ownerId={course.ownerId}
		    			teachers={teachers} 
		    			addTeacher={addTeacher}
		    			removeTeacher={removeTeacher}
		    		/>
		    	)
		    }

		    {
		    	tab === 3 && (
		    		<CourseStudentsComponent students={students} />
		    	)
		    }

		</div>
	)
}

export default CourseComponent
