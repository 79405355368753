import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CourseComponent from './course.component.js';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		width: '95vw',
		flexDirection: 'column',
		marginTop: theme.spacing(2)
	},
	summary: {
	    backgroundColor: 'lightgrey',
	    flexDirection: 'row-reverse'
	},
	heading: {
		marginLeft: 50,
	    marginRight: theme.spacing(2),
	    fontWeight: 'bold'
	},
	subheading: {
	    marginLeft: theme.spacing(2)
	},
	recordsNum: {
	    marginLeft: theme.spacing(2)
	},
	accordionDetails: {
	    minWidth: '100%'
	},
}));

const CoursesListsComponent = ({ courses, teacher }) => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		
		

	},[]);

	return (
		<div className={classes.root}>
			{
				courses.map(course => 
					<Accordion key={course.id} TransitionProps={{ unmountOnExit: true }}>
	                  <AccordionSummary
	                    expandIcon={<ExpandMoreIcon />}
	                    className={classes.summary}
	                  >
	                    <Typography className={classes.heading}> { course.descriptionHeading } </Typography>

	                  </AccordionSummary>

	                  <AccordionDetails
	                    className={classes.accordionDetails}
	                  >

	                  	<CourseComponent 
	                  		teacher={teacher}
	                  		course={course} 
	                  	/>

	                  </AccordionDetails>

	                </Accordion>
				)
			}

		</div>
	)
}

export default CoursesListsComponent
