import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
  policyframe: {
    marginTop: '10vh',
    flexGrow: 1,
    height: 6000,
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: 1000
    },
    [theme.breakpoints.down('sm')]: {
      width: 900
    },
    [theme.breakpoints.down('xs')]: {
      width: 600
    },
    // '@media(max-width: 695px)': {
    //   width: 600
    // },
    // '@media(max-width: 525px)': {
    //   width: 550
    // },
    // '@media(max-width: 425px)': {
    //   width: 400
    // }
  }

}));

const ManageAppsComponent = props => {
  const [ loading, setLoading ] = useState(false);
  const classes = useStyles();
  return (
    <iframe
      className={classes.policyframe}
      frameBorder={0}
      src="https://docs.google.com/document/d/e/2PACX-1vS0YQFDVlGQnCoy8_mBHlCVZ5qI24qLyQV7s33JnbPVx8Pg3XppKs20RwgqWUDu9wqsLe6RN2TVSnMS/pub?embedded=true"></iframe>
  )
}

export default ManageAppsComponent
