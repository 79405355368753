import { firebaseApp } from '../../../firebase';

const getDomainUsers = async (domain) => {

	return new Promise( async (resolve,reject) => {

		let snapshot = await firebaseApp.firestore()
			.collection('domains').doc(domain)
			.collection('users').get();

		if(!snapshot || snapshot.length <= 0) {
			return resolve([]);
		}

		let tempUsers = [];
        snapshot.forEach(snap => {
          tempUsers.push(snap.data());
        });

        return resolve(tempUsers);


	}); // PROMISE

}

export {
	getDomainUsers
}