import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
    FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import LockIcon from '@material-ui/icons/Lock';
import GroupIcon from '@material-ui/icons/Group';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SearchIcon from '@material-ui/icons/Search';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import WebIcon from '@material-ui/icons/Web';
import LanguageIcon from '@material-ui/icons/Language';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DomainIcon from '@material-ui/icons/Domain';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';
import SecurityIcon from '@material-ui/icons/Security';
import CodeIcon from '@material-ui/icons/Code';
import BuildIcon from '@material-ui/icons/Build';

import HeaderComponent from './header.component';
import FooterComponent from './footer.component';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '25vh'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10vh'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '8vh'
    }
  },
  paper1: {
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: fade(theme.palette.primary.main,0.75),
    width: '95%'
  },
  paper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    width: '95%'
  },
  descContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    minWidth: 600,
    [theme.breakpoints.down('xs')]: {
      minWidth: 375,
      maxWidth: 450
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 600
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 960
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 500,
      maxWidth: 600
    }
  },
  gif: {
    flex: 1,
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: 450
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 600,
      maxWidth: "100%"
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 650,
      maxWidth: 960
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 600,
      maxWidth: 700
    }

  },
  extPaper: {
    padding: theme.spacing(1)
  },
  iconContainer: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(1)
  },
  icons: {
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
    fontSize: theme.spacing(10)
  },
  contact: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '8vw'
  },
  para: {
    fontSize: '3vw'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignSelf: 'center'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  header1: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    letterSpacing: '0.1em',
    [theme.breakpoints.down('xl')]: {
      fontSize: '5.5em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.0em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5em'
    }
  },
  subheader: {
    width: '100%',
    textAlign: 'center',
    color: "#FFFFFF",
    marginTop: theme.spacing(2),
    // textShadow: '1px 1px lightgrey',
    [theme.breakpoints.down('xl')]: {
      fontSize: '2.5em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5em'
    }
  },
  pink:{
    color: 'rgb(227,50,90)'
  },
  header2: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xl')]: {
      fontSize: '4.5em'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.0em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '4.0em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5em'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.0em',
      alignSelf: 'center'
    }
  },
  anchor: {
    display: 'block',
    visibility: 'hidden',
    transform: 'translateY(-5vh)'
  },
  note: {
    fontSize: '0.75em'
  }
}))

const HomeComponent = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    document.getElementById('inquiry').style.display = 'initial';
  },[]);

  return (
    <div className={classes.root}>

    <Grid
      container
      direction='column'
      justify="center"
      alignItems="center"
    >
      <HeaderComponent title="Home" />

      {/*  FIRST PAPER

        */}
      <Paper
        className={classes.paper1}
        elevation={5}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h2"
            component="h1"
            className={classes.header1}
          >
            HIGH IMPACT SOLUTIONS
          </Typography>

          <Typography
            variant="h4"
            component='h1'
            className={classes.subheader}
          >
            A Google Chrome extension and web service that helps organizations manage their online resources, make data-driven decisions and automagically save everyone time the second the app is installed.
          </Typography>
        </Grid>
      </Paper>


       {/*  SCHOOL ADMINS

        */}
      <Paper className={classes.paper} elevation={5}>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >

          <div className={classes.descContainer}>

            <Typography variant="h2"
              className={classes.header2}
              id="home-header-admins"
            >School Admins...</Typography>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Automate the <a href="https://manage-it.app/public/approveit.app" target="_blank">public disclosure</a> process of applications that are used with students.  
              <br />
              <span className={classes.note}>Note: State-based student privacy laws. (e.g. Illinois SOPPA)</span>
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Clearly communicate to your staff</b> the list of apps that are approved and are safe to use with students via our <a href="#chrome-ext">Chrome extension</a>.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Develop a <b>predictable workflow</b> for students, teachers and your entire organization.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Review, Visit or <b>Manage</b> your teachers' <b>Google Classroom courses</b>.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Send District-Wide Announcements</b> with notification pop-ups to all of your Chrome users' devices.  Scheduled announcements are also available.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Empower Teachers</b> by providing them a tool to review student activities.
              </Typography>
            </ListItem>

          </div>

          <Paper className={classes.extPaper}>
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2FGClassroom.gif?alt=media&token=1df840a7-6191-40ec-a56f-e10a974dcea6"}
              alt="GIF showing user how Google Classroom Admin works"
              className={classes.gif}
            />
          </Paper>

        </Grid>

      </Paper>


      {/*  WHY for IT?

        */}
      <Paper className={classes.paper} elevation={5}>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >

          <div className={classes.descContainer}>

            <Typography variant="h2"
              className={classes.header2}
              id="home-header-it"
            >IT Admins...</Typography>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Manage apps and other online assets with ease.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Receive email notifications when vendor agreements are expiring.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Quick view of <b>app usage data</b>.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Compare app usage</b> on approved and unapproved apps.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              Identify all of the Chrome apps and extensions that are installed by all of your users and <b> review the type of access that the extensions and apps have on your users' accounts and devices.</b>
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Track user activity</b> on all URLs that they visit.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Save money</b> by knowing which apps are being used and the ones that are not.
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
              <b>Manage Google Classroom</b> Courses.
              </Typography>
            </ListItem>

          </div>

          <Paper className={classes.extPaper}>
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2Fmanage-apps.gif?alt=media&token=dd148828-65e7-4a29-a37d-534cf28ee2cc"}
              alt="loading..."
              className={classes.gif}
            />
          </Paper>

        </Grid>
      </Paper>


      {/*  WHY USE THE EXTENSION

        */}
      <span className={classes.anchor} id="chrome-ext"></span>
      <Paper className={classes.paper} elevation={5} >

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.descContainer}>

            <Typography variant="h2"
              className={classes.header2}
              id="home-header-extension"
            >A Chrome Extension for All</Typography>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
                Quickly Access School/District-Approved Applications
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
                Personalized Access to Online Resources
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
                Quickly Access Directory of all School/District Employees
              </Typography>
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
                Dedicated Directory for Student Support
              </Typography>
            </ListItem>

          </div>

          <Paper className={classes.extPaper}>
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2Fextension-gif-2.gif?alt=media&token=6fae81cc-cfef-4c7f-a0ec-70fa1064d44a"}
              alt="loading..."
              className={classes.gif}
            />
          </Paper>

        </Grid>

      </Paper>

      {/*  CALL TO ACTION

        */}
      <Paper
        className={classes.paper1}
        elevation={5}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h2"
            component="h1"
            className={classes.header1}
            id="home-header-action"
          >
            What are you waiting for?
          </Typography>

          <Typography
            variant="h4"
            component='h1'
            className={classes.subheader}
          >
            Hit that <span className={classes.pink}>pink</span> question mark button and contact us!
          </Typography>
        </Grid>
      </Paper>


      {/*  IN DEVELOPMENT

        */}
      <Paper
        className={classes.paper}
        elevation={5}
      >

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <div className={classes.descContainer}>

            <ListItem button>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <Typography variant="h5">
                We don't build features, we build solutions.  If you have any suggestions on how we can improve or add value to our already useful services, submit a feature request through our contact form via the pink question mark button.
              </Typography>
            </ListItem>

          </div>

          <div className={classes.iconContainer}>
            <CodeIcon className={classes.icons} />
            <BuildIcon className={classes.icons}/>
          </div>

        </Grid>

      </Paper>

    </Grid>


    <FooterComponent />

    </div>
  )
}

export default HomeComponent
