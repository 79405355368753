import React, { useEffect, useState, useContext } from 'react';
import MainContext from '../state/main.context';
import { fade,makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BackDrop from '../backdrop.component';
import MaterialTable, { MTableEditField, MTableToolbar } from "material-table";
import { firebaseApp } from '../../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center'
  },
  imgUrl: {
    maxHeight: 80,
    maxWidth: 80
  },
  editField: {
    width: 200,
    height: 100
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  url: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
}));

const AppsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ lastDoc, setLastDoc ] = useState(null);
  const [ apps, setApps ] = useState([]);
  const [ searchResults, setResults ] = useState([]);
  const [ searching, setSearching ] = useState(false);

  const appsRef = firebaseApp.firestore().collection('publicApps');
  const searchUrl = "https://us-central1-manage-it-extension.cloudfunctions.net/searchAlgolia";

  useEffect(() => {

    if(!mainState.authUser) {
      return;
    }

    mainDispatch({ type: 'setLoading', payload: true });

    appsRef.orderBy('title')
    .limit(100).get()
    .then(querySnapshot => {
      if(querySnapshot.docs.length < 0) {
        mainDispatch({ type: 'setLoading', payload: false });
        return;
      }
      let arr = querySnapshot.docs.map(doc => {
        let obj = doc.data();
        obj._id = doc.id;
        return obj;
      });
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length -1]);
      setApps([...apps,...arr]);
      mainDispatch({ type: 'setLoading', payload: false });
    })
    .catch(err => {
      console.dir(err);
      mainDispatch({ type: 'setLoading', payload: false });
    })
  },[])


  useEffect(() => {
    console.log('Apps: ' + apps.length);
  },[ apps ])

  const queryApps = async () => {

    mainDispatch({ type: 'setLoading', payload: true });

    appsRef.orderBy('title')
    .startAfter(lastDoc.data().title)
    .limit(100).get()
    .then(querySnapshot => {
      if(querySnapshot.docs.length < 0) {
        mainDispatch({ type: 'setLoading', payload: false });
        return;
      }
      let arr = querySnapshot.docs.map(doc => {
        let obj = doc.data();
        obj._id = doc.id;
        return obj;
      });

      setApps([...apps,...arr]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length -1])
      mainDispatch({ type: 'setLoading', payload: false });
    })
    .catch(err => {
      console.dir(err);
      mainDispatch({ type: 'setLoading', payload: false });
    })
  }

  const updateImgs = async (appsArr) => {

    let promises = [];
    appsArr.forEach(app => {
      promises.push(extractImage(app));
    });

    try {
      let initImgUpdate = await Promise.all(promises);
      console.dir(initImgUpdate);
    } catch (e) {
      console.dir(e);
    }
  }

  const extractImage = async (app) => {
    //  FIREBASE REFERENCES
    const storageRef = firebaseApp.storage().ref();

    let getBlob = "http://localhost:5000/manage-it-extension/us-central1/getBlob";

    return new Promise( async (resolve,reject) => {
      try {
        let fetchBlob = await fetch(getBlob,{
                    method: 'Post',
                    body:JSON.stringify({
                      url: app.imgUrl
                    })
                  });

        let data = await fetchBlob.json();

        let fileName = app._id + ".jpg";

        if(data.contentType === 'image/png') {
          fileName = app._id + ".png"
        }

        let ref = storageRef.child(`publicApps/${fileName}`);

        let putFile = await ref.putString(data.data, 'data_url');

        let downloadUrl = await ref.getDownloadURL();

        let updateAppData = await appsRef.doc(app._id).update({
          imgUrl: downloadUrl
        })

        resolve('Completed.');

      } catch (e) {
        console.dir(e);
          reject(e);
      }

    });
  }

  const deleteApp = id => {
    mainDispatch({type: 'setLoading', payload: true });
    firebaseApp.firestore().collection('publicApps').doc(id)
      .delete()
      .then(() => {
        let tempApps = apps.filter(app => app._id != id);
        setApps([ ...tempApps ])
        mainDispatch({type: 'setLoading', payload: false });
      })
      .catch(err => {
        console.log('Error deleting app.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const updateApp = (newValue,field, docId) => {
    console.log(newValue,field,docId);

    mainDispatch({type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('publicApps').doc(docId)
      .update({
        [field]: newValue
      })
      .then(() => {
        console.log('Updated app.');
           let tempApps = apps;
           tempApps.some(app => {
             if(app._id === docId) {
               app[field] = newValue;
               return true;
             }
             return false;
           })

           setApps([...tempApps]);

        mainDispatch({type: 'setLoading', payload: false });

      })
      .catch(err => {
        console.log('Error updating app.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const columns = [
    {
      field: 'imgUrl',
      render: rowData => {
        if(rowData.imgUrl) {
          return (
            <img src={rowData.imgUrl} className={classes.imgUrl} />
          )
        }
        return (
          <p>No Image</p>
        )
      }
    },
    {
      title: 'App',
      field: 'title',
      render: rowData => <p className={classes.appTitle}>{ rowData.title }</p>
    },
    {
      title: "Desc",
      field: 'desc',
      render: rowData => <p className={classes.desc}>{ rowData.desc }</p>
    },
    {
      title: "URL",
      field: 'url',
      render: rowData => <p className={classes.url}>{ rowData.url }</p>
    },
    {
      title: "Grades",
      field: 'grades',
      render: rowData => <p className={classes.appTitle}>{ rowData.grades }</p>
    }
  ]

  return (
    <div className={classes.root}>

    <MaterialTable
      title="Public Apps"
      columns={columns}
      data={apps}
      style={{
        width: window.innerWidth * 0.9,
        border: '1px solid lightgrey',
        boderRadius: '5px'
      }}
      options={{
        pageSize: 100,
        headerStyle:{
          backgroundColor: '#66549a',
          color: '#ffffff'
        }
      }}
      actions={[
        {
          icon: () =>
          <DeleteIcon
            color='secondary'
          />,
          tooltip: 'Delete',
          onClick: (event, rowData) => {
            let c = window.confirm('Are you sure?');
            if(c){
              deleteApp(rowData._id);
            }
          }
        }
      ]}
      components={{
        // Container: Box,
        EditField: props => (
          <MTableEditField {...props} className={classes.editField} />
        ),
        Toolbar: props => (
          <div >
            <MTableToolbar {...props} />
            <div className={classes.toolbar}>
              <Button
                color='primary'
                onClick={queryApps}
              >
                Load More
              </Button>
            </div>
          </div>
        ),
      }}
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
          new Promise((resolve, reject) => {
            updateApp(newValue,columnDef.field,rowData._id);
            resolve();
          })
      }}
    />

    <Button
      color='primary'
      onClick={queryApps}
    >
      Load More
    </Button>

    <BackDrop loading={mainState.isLoading} />
    </div>
  )
}

export default AppsComponent
