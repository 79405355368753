import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Snackbar,
  IconButton,
  Tooltip,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable, { MTableToolbar } from "material-table";
import moment from 'moment';
import CustomHeader from '../../basic_header.component';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingBottom: '15vh',
		paddingTop: '10vh'
	},
	loading: {
		marginTop: '10vh'
	},
	note: {
		padding: theme.spacing(2)
	},
	low: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    // width: 50,
    // borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: 'lightblue'
  },
  medium: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    // width: 50,
    // borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: 'yellow'
  },
  high: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    // width: 50,
    // borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: 'orange'
  },
  critical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    // width: 50,
    // borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: 'red'
  },
  ids: {
  	display: 'flex',
  	cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const WeeklyReportComponent = () => {

	const classes = useStyles();
	const location = useLocation();
	const query = useQuery();
	const d = query.get('d');
	const date = query.get('date');
	const [ loading, setLoading ] = useState(true);
	const [ installs, setInstalls ] = useState([]);
	const [ sites, setSites ] = useState([]);
	const [ totalSitesVisited, setTotalSitesVisits ] = useState(0);
	const [ domain, setDomain ] = useState('');
	const [ open, setOpen ] = useState(false);

	useEffect(() => {
		fetchData();
	},[]);

	function useQuery () {
		return new URLSearchParams(location.search);
	} 

	const fetchData = async () => {

		if(!d) {
			setLoading(false);
			console.log('no d provided');
			return;
		}

		let options = {
			method: 'Post',
			body: JSON.stringify({
				d: d,
				date: date
			})
		}
		
		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/fetchWeeklyReport";

		try {

			let response = await fetch(url,options);

			if(response.ok) {

				let json = await response.json();
				console.dir(json);
				setInstalls(json.installs);
				setSites(json.topSites);
				setTotalSitesVisits(json.totalSitesVisited);
				setDomain(json.domain);
				setLoading(false);
				return;
			}

			setLoading(false);
			
		} catch(e) {
			// statements
			setLoading(false);
			console.log(e.message);
		}
	}

	const handleClose = (event,reason) => {
		if(reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	const installColumns = [
	    {
	      title: "Security Risk**",
	      field: 'securityRisk',
	      defaultSort: 'desc',
	      render: rowData => (
	        <Typography 
	          className={
	            `
	              ${
	                rowData.securityRisk >= 50 ?
	                  classes.critical
	                : rowData.securityRisk < 50 && rowData.securityRisk >= 35 ?
	                  classes.high
	                : rowData.securityRisk < 35 && rowData.securityRisk >= 15 ?
	                  classes.medium
	                : rowData.securityRisk < 15 && rowData.securityRisk > 0 ?
	                  classes.low
	                : rowData.securityRisk <= 0 ?
	                  classes.none
	                : null

	              }
	            `
	          }
	        >
	          { 

	          	rowData.securityRisk >= 50 ?
                  "Critical"
                : rowData.securityRisk < 50 && rowData.securityRisk >= 35 ?
                  "High"
                : rowData.securityRisk < 35 && rowData.securityRisk >= 15 ?
                  "Medium"
                : rowData.securityRisk < 15 && rowData.securityRisk > 0 ?
                  "Low"
                : rowData.securityRisk <= 0 ?
                  "None"
                : ""

	          }
	        </Typography>
	      ),
	      width: 150
	    },
	    {
	      title: "App Name",
	      field: 'name',
	      render: rowData => {

	      	if(rowData.homepageUrl) {
	      		return (
			        <Typography className={classes.appName}>
			        <a href={rowData.homepageUrl} target="_blank">{ rowData.name.toUpperCase() }</a>
			        </Typography>
			    )
	      	}

	      	return (
		        <Typography className={classes.appName}>
		        { rowData.name.toUpperCase() }
		        </Typography>
		    )
	      },
	      width: 250
	    },
	    {
	      title: "Description",
	      field: 'desc',
	      render: rowData => (
	        <Typography className={classes.desc}>{ rowData.desc }</Typography>
	      ),
	      sorting: false
	    },
	    {
	    	title: 'Permissions',
	    	field: 'permissions',
	    	render: rowData => (
				<div>
					{
						rowData.permissions.length > 0 ?

							<Typography>{rowData.permissions}</Typography>

						: <Typography align='center'>No Permissions</Typography>
					}
				</div>
	    	),
	    	sorting: false
	    },
	    {
	    	title: 'App ID',
	    	field: 'id',
	    	render: rowData => (
				<Tooltip title='Copy'>
	    		<div className={classes.ids}
					onClick={() => {
						navigator.clipboard.writeText(rowData.id).then(function(){
							setOpen(true);
						}, function() {
							console.log("Couldn't copy")
						})
					}}
				>
	    			<Typography>{rowData.id}</Typography>
	    			<FilterNoneIcon style={{marginLeft: '10px'}} />
				</div>
				</Tooltip>
	    	),
	    	sorting: false
	    }
	  ]

	const RenderInstallTable = () => (
		<MaterialTable
			title={`${installs.length} Recently Installed Apps/Extensions`}
			data={installs}
			columns={installColumns}
			style={{
				margin: '20px'
			}}
			options={{
				sorting: true,
				pageSize: 20,
				exportAllData: true,
				exportButton: true
			}}
			components={{
				Toolbar: props => (
					<div>
						<MTableToolbar {...props} />
						
						<div  className={classes.note}>
							<ul>
								<li style={{ listStyleType: 'none' }}>
									<Typography><b>PLEASE READ</b></Typography>
								</li>
								<li>
									<Typography component='h1'>
										The listed apps and extensions are those that were <span style={{ textDecoration: 'underline' }}>not installed by an admin</span> and that have occurred within the timeline indicated at the top of this page.
									</Typography>
								</li>

								<li>
									<Typography component='h1'>
										New installs are determined when a user logs in to a <span style={{ textDecoration: 'underline' }}>new</span> Chrome browser and/or Chrome device.  As a result, apps and extensions that have been previously installed will re-appear on the list.
									</Typography>
								</li>

								<li>
									<Typography>
									<b>**Security Risks</b> are based on access permissions that extensions have on your users account and devices. Permissions are rated based on the type of access that they have. Some permissions have more capabilities than others so the ratings will vary based on each permission and the number of permissions that the extension itself has been given. Learn more about permissions&nbsp;
							          <a href="https://developer.chrome.com/docs/extensions/mv2/declare_permissions/#manifest" target="_blank">
							             here
							          </a>.
							        </Typography>
								</li>
								<li>
									<Typography>
										Having a high risk rating (e.g. <b>Critical</b>) does not necessarily mean it is a bad app or extension.  It is simply highlighting the item as having potential security risk since the app or extension was not installed by an admin through the Google console.
									</Typography>
								</li>

							</ul>
						</div>
					</div>
				)
			}}
		/>
	)

	const sitesColumn = [
		{
			title: 'Sites',
			field: 'urlDomain',
			render: rowData => (
				<Typography>
					{ rowData.urlDomain }
				</Typography>
			)
		},
		{
			title: '# Visits',
			field: 'visits'
		}
	]

	const RenderSitesTable = () => (
		<MaterialTable
			title="Top 200 Sites Visited"
			data={sites}
			columns={sitesColumn}
			style={{
				margin: '20px',
				maxWidth: 600,
				alignSelf: 'center'
			}}
			options={{
				sorting: true,
				pageSize: 50,
				exportAllData: true,
				exportButton: true
			}}
			components={{
				Toolbar: props => (
					<div>
						<MTableToolbar {...props} />
						
						<div  className={classes.note}>
						<Typography component='h1'>
						These are NOT weekly numbers.  It is a list of the current top 200 sites visited by your users.
						</Typography>
						</div>
					</div>
				)
			}}
		/>
	)

	if(loading) {

		return (

			<div className={classes.root}>

				<CustomHeader title="Weekly Report"/>

				<Typography 
					align='center' 
					variant='h3' 
					component='h1'
					className={classes.loading}
				>Fetching Data...</Typography>

				<Backdrop className={classes.backdrop} open={loading} onClick={() => console.log('close')}>
			        <CircularProgress color="inherit" />
			    </Backdrop>

			</div>

		)

	}

	return (
		<div className={classes.root}>

			<CustomHeader title="Weekly Report"/>

			<Typography variant='h2' align='center' component='h1'>
				{ domain || "" }
			</Typography>

			<Typography variant='body1' align='center'>
				Report Dates: { moment(Number(date)).subtract(8,'days').format("MM/DD/YYYY") } - { moment(Number(date)).format("MM/DD/YYYY") }
			</Typography>

			<Typography style={{ padding: 15, marginTop: 10 }}>
			<b>IMPORTANT:</b> The data provided is dynamic, which means the report you are seeing right now will be different in two hours.  Visiting this same URL in the future will render an inaccurate report <u>for the timeline listed above</u>.  You may export the data on each table by clicking on the export icon on the upper-right corner next to the search field.
			</Typography>

			<RenderInstallTable />

			<RenderSitesTable />

			<Snackbar
		        anchorOrigin={{
		          vertical: 'top',
		          horizontal: 'center',
		        }}
		        open={open}
		        autoHideDuration={4000}
		        onClose={handleClose}
		        message="App ID Copied to Clipboard"
		        action={
		          <React.Fragment>
		            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
		              <CloseIcon fontSize="small" />
		            </IconButton>
		          </React.Fragment>
		        }
		      />
		</div>
	)
}

export default WeeklyReportComponent
