import React, { useEffect, useState, useContext } from 'react';
import {
  Checkbox,
  Box,
  Typography,
  TextField,
  Paper,
  Button
} from '@material-ui/core';
import MaterialTable, { MTableEditField, MTableEditRow } from "material-table";
import { firebaseApp } from '../../../../firebase';
import MainContext from '../../../state/main.context';
import { fade, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import BackDrop from '../../../backdrop.component';
import HeatMapComponent from '../../apps/heatmap.component';

const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 150,
    maxHeight: 150,
    overflow: 'scroll'
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  visits: {
    maxWidth: 100,
    minWidth: 50,
    overflow: 'scroll'
  },
  url: {
    minWidth: 300,
    maxWidth: 500,
    overflow: 'scroll'
  },
  imgDiv: {
    maxWidth: 60,
  },
  img: {
    width: 50,
    height: 50
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  loadMore: {
    width: 200
  }
}));

const UsageTableComponent = () => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(true);
  const [ apps, setApps ] = useState([]);
  const [ lastSnapshot, setLastSnapshot ] = useState(null);
  const [ page, setPage ] = useState(0);

  //  LISTEN AND ADJUST TO APPS CHANGES
  useEffect(() => {
    if(!mainState.domain) {
      return;
    }

    if(mainState.appInsights) {
      let timeDiff = Date.now() - mainState.appInsights.last_checked;

      if(timeDiff < 84400000) {
        console.log('App Insights list refreshes every 24 hours.');
        setApps(mainState.appInsights.apps);
        setLoading(false);
        return;
      }

      getAppInsights();

      return;
    }  //  THERE IS APP INSIGHTS

    getAppInsights();

  },[mainState.domain]);

  const getAppInsights = () => {
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('visited_sites').orderBy('num_visits','desc').limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0){
          return setLoading(false);
        }

        let tempData = snapshot.docs.map(doc => {

          let obj = {
            id: doc.id,
            ref: doc,
            num_visits: doc.data().num_visits,
            favicon: doc.data().favicon
          }
          return obj;
        });

        let payload = {
          apps: tempData,
          last_checked: Date.now()
        }

        setLastSnapshot(snapshot.docs[snapshot.docs.length-1]);
        setApps(tempData);
        mainDispatch({ type: 'setAppInsights', payload: payload })
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  const getMoreAppInsights = () => {

    setLoading(true);
    
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('visited_sites').orderBy('num_visits','desc')
      .startAt(lastSnapshot).limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0){
          setLoading(false);
          return;
        }

        let tempData = snapshot.docs.map(doc => doc.data());

        let combinedData = [...apps,...tempData];

        let payload = {
          apps: combinedData,
          last_checked: Date.now()
        }

        setLastSnapshot(snapshot.docs[snapshot.docs.length-1]);
        setApps(combinedData);
        mainDispatch({ type: 'setAppInsights', payload: payload })
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }

  const columns = [
    {
      title: 'Image',
      field: 'favicon',
      render: rowData => {
        if(rowData.hasOwnProperty('favicon') && rowData.favicon) {
          return (
            <div className={classes.imgDiv}>
              <img src={rowData.favicon} className={classes.img} />
            </div>
          )
        }
        return (
          <div></div>
        )
      }
    },
    {
      title: 'Domain',
      field: 'id',
      render: rowData =>
        <p className={classes.url}>
          { rowData.id }
        </p>
    },
    {
      title: 'Visits',
      field: 'num_visits',
      render: rowData =>
        <p className={classes.visits}>
        { rowData.hasOwnProperty('num_visits') ? rowData.num_visits : 0 }
      </p>
    }
  ];

  return (
    <div>
      <MaterialTable
        title="SITE VISITS"
        columns={columns}
        data={apps}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        options={{
          pageSize: 50,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          }
        }}
        detailPanel={ rowData => {
          // let records = rowData.ref.data().visits;
          return (
            <div className={classes.heatCont}>
              <HeatMapComponent 
                id={rowData.id}
                dispatch={mainDispatch}
                domain={mainState.domain}
                email={mainState.authUser.email}
              />
            </div>
          )
        }}

      />

      <div className={classes.loadMoreContainer}>
        <Button
          color="primary"
          variant="contained"
          className={classes.loadMore}
          onClick={getMoreAppInsights}
        >
          Load More Data
        </Button>
      </div>
      <BackDrop loading={loading} />
    </div>
  )
}

export default UsageTableComponent;
