import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Icon,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import CoursesListsComponent from './courses.lists.component';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(6)
	},
	header: {
		marginBottom: theme.spacing(2)
	},
	form: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 500,
		maxWidth: 500,
		maxHeight: 150,
		padding: theme.spacing(2),
		border: '1px solid grey',
		borderRadius: 10
	},
	textField: {
		minWidth: 350
	},
	button: {
		margin: theme.spacing(1)
	},
	instructions: {
		minWidth: 500,
		maxWidth: 800,
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(5)
	},
	underline: {
		textDecoration: 'underline'
	}
}));

const GClassroomComponent = props => {

	let { history } = props;

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);
	const [ teacherEmail, setEmail ] = useState(null);
	const [ courses, setCourses ] = useState([]);

	useEffect(() => {

	},[]);

	const handleSubmit = async (e) => {

		e.preventDefault();

		if(!teacherEmail) {
			alert('A teacher email is required.');
			e.target.reset();
			return;
		}

		let options = {
			method: 'Post',
			body:JSON.stringify({
				adminEmail: mainState.authUser.email,
				teacherEmail: teacherEmail
			})
		}

		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getCourses";

		mainDispatch({ type: 'setLoading', payload: true });

		try {
			let initGetCourses = await fetch(url,options);

			if(initGetCourses.ok) {
				let json = await initGetCourses.json();
				setCourses(json);
				mainDispatch({ type: 'setLoading', payload: false });

				setTimeout(() => {
					if(json.length <= 0) {
						alert('User does not have any courses.');
					}
				}, 500)
				
				return;
			}

			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue retrieving courses for the user.  The user may or may not have any courses.  Please try again later or contact support@approveit.app');
			}, 500)

		} catch(e) {
			mainDispatch({ type: 'setLoading', payload: false });
			setTimeout(() => {
				alert('There was an issue retrieving courses for the user.  The user may or may not have any courses.  Please try again later or contact support@approveit.app');
			}, 500)
		}	
	}

	if(mainState && mainState.domainData.subscription !== 'premium') {
		return (
			<div className={classes.root}>
				<Typography
					align="center"
					component="h1"
				>
					Premium Subscription is required for Google Classroom Admin.
				</Typography>
			</div>
		)
	}

	return (
		<div className={classes.root}>

			<Typography
				variant='h1'
				align='center'
				className={classes.header}
			>
				Google Classroom Admin
			</Typography>

			<Typography 
				className={classes.instructions}
				component='h1'

			>
				<b>NOTE:</b>  To take advantage of the Google Classroom Admin feature, you must ensure that you have <span className={classes.underline}>Domain Wide Delegation</span> configured.  Visit the Getting Started page via the left menu or go directly to this <a href="https://docs.google.com/document/d/e/2PACX-1vTWli82yfCsBOrkbmvXwRhqi5eU0GiD1QZH8N0f0peTy0TrMmJtwaYyQ0sYwZXb4YIU7rCmz2b3gwLO/pub" target="_blank">document instruction</a>.
			</Typography>
			
			<form className={classes.form}
				onSubmit={handleSubmit}
			>
				<TextField
					className={classes.textField}
					label="Enter Teacher Email"
			        onChange={e => setEmail(e.target.value)}
				/>
				<Button
			        color="primary"
			        variant="contained"
			        className={classes.button}
			        endIcon={<Icon>search</Icon>}
			        type="submit"
			     >
			     </Button>
			</form>

			{
				courses.length > 0 && (
					<CoursesListsComponent 
						courses={courses} 
						teacher={teacherEmail}
					/>
				)
			}

		</div>
	)
}

export default GClassroomComponent