import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minWidth: 700,
		minHeight: 300
	},
	textField: {
		margin: theme.spacing(1)
	},
	listItem: {
		backgroundColor: 'lightgrey',
		borderRadius: 5,
		minWidth: 400,
		maxWidth: 500,
		margin: theme.spacing(1)
	},
	addTeacherContainer: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 450,
		marginTop: theme.spacing(2),
		border: '1px solid lightgrey',
		borderRadius: 10,
		padding: theme.spacing(1)
	}
}));

const CourseStudentsComponent = ({ students }) => {

	const classes = useStyles();
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		
		

	},[]);

	return (
		<Paper className={classes.root}>

			<List>
				{
					students.length > 0 && (
						students.map(student => 
							<ListItem 
								key={student.userId}
								className={classes.listItem}
							>
								<ListItemAvatar>
									<Avatar alt={student.profile.name.fullName} src={student.profile.photoUrl} />
								</ListItemAvatar>

								<ListItemText
									primary={student.profile.name.fullName}
								/>
							</ListItem>
						)
					)
				}
			</List>

		</Paper>
	)
}

export default CourseStudentsComponent
