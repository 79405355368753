import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp } from '../../../firebase';
import MainContext from '../../state/main.context';
import MaterialTable from 'material-table';
import BackDrop from '../../backdrop.component';
import moment from 'moment';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10vh',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ActionRecordsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(true);
  const [ lastDoc, setLastDoc ] = useState('');
  const [ actions, setActions ] = useState([]);
  const [ showTour, setShowTour ] = useState(true);

  const steps = [
    {
      target: '.records-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.records-table',
      content: "The table will initially present the first 50 recorded activities in a descending order (most recent first).  The search field is useful for action keywords like 'deleted','updated', and 'added'.",
      placement: 'center'
    },
    {
      target: '.load-more-records',
      content: 'Click on this button to load more recorded activities.  50 more activities will be added for each click unless there are no more activities to load.',
      placement:'top'
    }
  ]

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('action_records').orderBy('timestamp','desc').limit(50)
      .get()
      .then(snapshots => {

        if(snapshots.docs.length <= 0) {
          console.log('There are no docs.');
          setLoading(false);
          return;
        }

        let tempLastDoc = snapshots.docs[snapshots.docs.length-1];
        let tempArr = [];

        snapshots.docs.forEach(doc => tempArr.push(doc.data()))

        setActions([ ...tempArr ]);
        setLastDoc(tempLastDoc);
        setLoading(false);
      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
      })
  },[])


  //
  const getData = () => {

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('action_records').orderBy('timestamp','desc')
      .startAt(lastDoc).limit(50)
      .get()
      .then(snapshots => {

        if(snapshots.docs.length <= 0) {
          console.log('There are no docs.');
          setLoading(false);
          return;
        }

        let tempLastDoc = snapshots.docs[snapshots.docs.length-1];

        if(tempLastDoc.id == lastDoc.id) {
          alert('No more data.');
          return;
        }
        let tempArr = [];

        snapshots.docs.forEach(doc => tempArr.push(doc.data()))

        //  REMOVE FIRST ITEM BECAUSE IT AS IN LAST QUERY
        tempArr.shift();

        if(tempArr.length <= 0) {
          setLoading(false);
          alert('No more data left.');
          return;
        }

        setActions([ ...actions, ...tempArr ]);
        setLastDoc(tempLastDoc);
        setLoading(false);
      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
      })

  }
  //*/

  const columns = [
    {
      title: 'Date',
      field: 'timestamp',
      render: rowData =>
        <Typography>
          { moment(rowData.timestamp).format("ddd, MMMM Do YYYY, h:mm a") }
        </Typography>
    },
    {
      title: 'User',
      field: 'email',
      render: rowData =>
        <Typography>{rowData.email}</Typography>
    },
    {
      title: 'Action',
      field: 'action',
      render: rowData =>
        <Typography>{rowData.action}</Typography>
    }

  ]

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }

  }

  return (
    <div className={`${classes.root} records-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <div className="records-table">
        <MaterialTable
          title="Admin Activities"
          columns={columns}
          data={actions}
          style={{
            width: window.innerWidth * 0.9,
            border: '1px solid lightgrey',
            boderRadius: '5px'
          }}
          options={{
            pageSize: 50
          }}
        />
      </div>

      <Button
        variant="contained"
        color='primary'
        onClick={getData}
        className='load-more-records'
      >
        Load More
      </Button>

      <BackDrop loading={loading} />
    </div>
  )
}

export default ActionRecordsComponent
