import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import moment from 'moment';
import { firebaseApp } from '../../../firebase';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	    display: 'flex',
	    flexFlow: 'row',
	    flexWrap: 'wrap',
	    justifyContent: 'center',
	    minWidth: 500
	},
	form: {
	    display: 'flex',
	    flexDirection: 'column',
	    minWidth: 500,
	    maxWidth: 500,
	    minHeight: 700,
	    // maxHeight: 700,
	    margin: theme.spacing(2),
	    padding: theme.spacing(1),
	    border: '1px solid grey',
	    borderRadius: 5
	},
	checkboxes: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap'
	},
	submitBtn: {
		margin: theme.spacing(2)
	},
	preview: {
		display: 'flex',
	    flexDirection: 'column',
	    minWidth: 500,
	    maxWidth: 500,
	    minHeight: 700,
	    margin: theme.spacing(2),
	    padding: theme.spacing(1),
	    wordWrap: 'break-word'
	},
	span: {
		color: 'red'
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	announcementWarning: {
		margin: theme.spacing(1),
		color: 'red',
		maxWidth: 650,
		textAlign: 'center'
	},
	group: {
		fontSize: '0.8em'
	}
}));

const ScheduleAnnouncementComponent = ({ canSendAnnouncements }) => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;

	// FORM ITEMS
	const [ title, setTitle ] = useState('');
	const [ desc, setDesc ] = useState('');
	const [ url1, setUrl1 ] = useState('');
	const [ url2, setUrl2 ] = useState('');
	const [ url3, setUrl3 ] = useState('');
	const [ announcementDate, setAnnouncementDate ] = useState(new Date());
	const [ OUs, setOUs ] = useState([]);

	useEffect(() => { },[]);

	const handleDateChange = (date) => {
		  console.log(date);
    	setAnnouncementDate(date);
    };

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.persist();

		var formObj = {};
		formObj.title = e.target[0].value;
		formObj.desc = e.target[1].value;
		formObj.url1 = e.target[2].value;
		formObj.url2 = e.target[3].value;
		formObj.url3 = e.target[4].value;
		formObj.announcementDate = e.target[5].value;
		formObj.groups = OUs;//e.target[7].value.toLowerCase();

		if(formObj.groups.length <= 0) {
			formObj.groups = ['all'];
		}

		formObj.createdAt = Date.now();
		formObj.submittedBy = mainState.authUser.email;

		mainDispatch({ type: 'setLoading', payload: true });

		//  CHECK IF DATE IS FOR TODAY OR THE FUTURE
		const today = moment(new Date()).format('MM/DD/YYYY');

		console.log(`Today: ${today}`);
		console.log(`Announcement Date: ${formObj.announcementDate}`);

		//  SAVE ANNOUNCEMENT TO THE announcement COLLECTION SINCE IT IS FOR TODAY
		if(today == formObj.announcementDate) {

			await firebaseApp.firestore().collection('domains').doc(mainState.domainData.domain)
				.collection('announcements').add(formObj);

			e.target.reset();
			resetFormItems();
			mainDispatch({ type: 'setLoading', payload: false });
			return;
		}

		//  SAVE ANNOUNCEMENT TO scheduledAnnouncements SINCE IT IS NOT FOR TODAY.
		await firebaseApp.firestore().collection('domains').doc(mainState.domainData.domain)
				.collection('scheduledAnnouncements').add(formObj);

		e.target.reset();
		resetFormItems();
		mainDispatch({ type: 'setLoading', payload: false });
	}

	const resetFormItems = () => {
		setTitle("");
		setDesc('');
		setUrl1('');
		setUrl2('');
		setUrl3('');
		setOUs([]);
		setAnnouncementDate(new Date());
	}

	const handleUrl = e => {
		e.persist();
		let id = e.target.id;
		switch(id) {
			case 'announcement-url1':
				setUrl1(e.target.value);
				break;

			case 'announcement-url2':
				setUrl2(e.target.value);
				break;

			case 'announcement-url3':
				setUrl3(e.target.value);
				break;
		}
	}

	const handleDescPaste = e => {
		e.persist();
		setDesc(e.target.value);
	}

	const handleTitlePaste = e => {
		e.persist();
		setTitle(e.target.value);
	}

	const RenderAvailableGroups = () =>{
		if(mainState.hasOwnProperty('domainData') && mainState.domainData) {
			if(mainState.domainData.hasOwnProperty('orgUnits') && Array.isArray(mainState.domainData.orgUnits) && mainState.domainData.orgUnits.length > 0) {
				return (
					<div>
						<p className={classes.group}>Available Group(s)</p>
						<p className={classes.group}>
						{
							mainState.domainData.orgUnits.map(unit => 
								<span key={unit}>{unit}, </span>
							)
						}
						</p>
					</div>
				)
			}
		}

		return (
			<div>
				<p className={classes.group}>Available Group(s)</p>
				<p className={classes.group}>All</p>
			</div>
		)
	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<div className={classes.root}>
				
				{
					!canSendAnnouncements && (
						<Typography component='h1' className={classes.announcementWarning}>
						  Your domain does not have premium access.  The form will not function.  Please contact your school administrator if you would like to use this feature. 
						</Typography>
					)
				}

				<form 
					onSubmit={handleSubmit}
					className={classes.form}
				>
					<TextField 
						required
						label="Title"
						id='announcement-title'
						onChange={e => setTitle(e.target.value)}
						onPaste={handleTitlePaste}
					/>
					<TextField 
						required
						label="Description"
						id='announcement-desc'
						multiline
						rows={5}
						onChange={e => setDesc(e.target.value)}
						onPaste={handleDescPaste}
					/>
					<TextField
						label="Resource URL 1 (optional)"
						id='announcement-url1'
						onPaste={handleUrl}
						onChange= {e => handleUrl(e)}
					/>
					<TextField 
						label="Resource URL 2 (optional)"
						id='announcement-url2'
						onPaste={handleUrl}
						onChange= {e => handleUrl(e)}
					/>
					<TextField 
						label="Resource URL 3 (optional)"
						id='announcement-url3'
						onPaste={handleUrl}
						onChange= {e => handleUrl(e)}
					/>

					<KeyboardDatePicker
						disablePast
						required
				        variant="inline"
				        format="MM/dd/yyyy"
				        margin="normal"
				        id="announcement-date"
				        label="Announcement Date (format: MM/DD/YYYY)"
				        value={announcementDate}
				        onChange={handleDateChange}
				        KeyboardButtonProps={{
				        	'aria-label': 'change date',
				        }}
			        />

					<FormLabel>
						Select one or more groups as recipients of the annoucement or leave blank to send to all.
					</FormLabel>
					<FormGroup className={classes.checkboxes}>
            {
              (mainState && mainState.hasOwnProperty('domainData') && 
              	mainState.domainData &&
              	mainState.domainData.hasOwnProperty('orgUnits') && 
              	mainState.domainData.orgUnits.length > 0)
                
                ?  mainState.domainData.orgUnits.map(ou => 

                    <FormControlLabel
                      key={ou} 
                      control={
                        <Checkbox  
                          checked={OUs.includes(ou)}
                          onChange={(e) => {
                            if(OUs.includes(ou)){
                            	let tempOUs = OUs.filter(i => i !== ou);
                            	setOUs(tempOUs);
                            } else {
                            	setOUs([...OUs,ou]);
                            }
                          }}
                        />
                      } 
                      label={ou} 
                    />

                  )

                : <p>You do not have any organizational units listed.  Please ensure that you run Google Sync with our system at least one time.</p>
            }
          </FormGroup>

					{/*<TextField
						helperText="Separate multiple groups by comma."
						id='announcement-groups'
					/>*/}
			        <Button
			        	type='submit'
			        	variant='contained'
			        	color='primary'
			        	className={classes.submitBtn}
			        	disabled={ !title || !desc || !announcementDate || !canSendAnnouncements }
			        >
			         Send | Schedule Announcement
			        </Button>

			        <Typography align='center'>
			        	<span className={classes.span}>Please Note:</span>  Announcements are automatically checked every hour from 7am until 5pm.
			        </Typography>
				</form> 

				<Paper className={classes.preview}>
					<Typography
						align="center"
						component="h1"
						variant="h6"
					>Announcement Preview</Typography>

					{
						announcementDate && (
							<div>
								<Typography className={classes.title}><b>Date:</b> {moment(new Date(announcementDate)).format('ddd, MMM Do YYYY')}</Typography>
							</div>
						)
					}

					{
						title && (
							<div>
								<Typography className={classes.title}><b>Title:</b> {title}</Typography>
							</div>
						)
					}

					{
						desc && (
							<div>
								<Typography className={classes.title}><b>Message</b></Typography>
								<Typography>{desc}</Typography>
							</div>
						)
					}

					{
						url1 && (
							<div>
								<a href={url1} target="_blank">
								<Typography className={classes.title}>
								<b>Resource Link 1</b>
								</Typography>
								</a>
							</div>
						)
					}

					{
						url2 && (
							<div>
								<a href={url2} target="_blank">
								<Typography className={classes.title}>
								<b>Resource Link 2</b>
								</Typography>
								</a>
							</div>
						)
					}

					{
						url3 && (
							<div>
								<a href={url3} target="_blank">
								<Typography className={classes.title}>
								<b>Resource Link 3</b>
								</Typography>
								</a>
							</div>
						)
					}

				</Paper>
			</div>
		</MuiPickersUtilsProvider>
	)
}

export default ScheduleAnnouncementComponent
