import Papa from 'papaparse';

const downloadCSV = async (data, title="download.csv") => {

	return new Promise( async (resolve,reject) => {

		let csv = await Papa.unparse(data);

	    let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

	    let csvURL = window.URL.createObjectURL(csvData);

	    let testLink = document.createElement('a');
	    testLink.href = csvURL;
	    testLink.setAttribute('download', title);
	    testLink.click();

	    return resolve();

	});

}

export {
	downloadCSV
}