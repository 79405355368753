import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Typography,
  Paper,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import moment from 'moment';
import Header from './header.component';
import BackDrop from '../backdrop.component';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		paddingTop: '15vh',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.primary.main,0.75)
	},
	root2: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.primary.main,0.75)
	},
	root3: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.primary.main,0.75)
	},
	card: {
		minWidth: 700,
		maxWidth: 900,
		margin: theme.spacing(2),
		wordWrap: 'break-word'
	},
	link: {
		margin: theme.spacing(2)
	},
	resourcesLabel: {
		marginTop: theme.spacing(2)
	},
	loading: {
		color: 'white',
		marginTop: '10vh'
	},
	noAnnouncements: {
		color: 'white'
	}
}));

const AnnouncementRendererComponent = props => {

	let { domain, announcementId } = props.match.params;

	const classes = useStyles();
	const [ loading, setLoading ] = useState(true);
	const [ announcement, setAnnouncement ] = useState(null);

	useEffect(() => {
		
		document.getElementById('inquiry').style.display = 'none';

		initGetAnnouncementInformation();

	},[]);

	const initGetAnnouncementInformation = async () => {
		if(!domain || !announcementId) {
			return;
		}

		let path = `domains/${domain}/announcements/${announcementId}`;

		let options = {
			method: "Post",
			body: JSON.stringify({
				path: path
			})
		}

		// let url = "http://localhost:5000/manage-it-extension/us-central1/getAnnouncement";
		let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getAnnouncement";

		try {
			let fetchAnnouncement = await fetch(url,options);

			if(fetchAnnouncement.ok) {
				let json = await fetchAnnouncement.json();
				setAnnouncement(json);
			}

			setLoading(false);

		} catch(e) {
			console.log(e.message);
			setLoading(false);
		}
	}

	if(loading) {
		return (
			<div className={classes.root2}>
				<Header  />
				<Typography component="h1" 
					className={classes.loading}>Loading announcement...
				</Typography>
				<BackDrop loading={loading} />
			</div>
		)
	}

	if(!announcement) {
		return (
			<div className={classes.root3}>
				<Header  />
				<Typography component="h1" 
					className={classes.noAnnouncements}>Announcement does not exist or it was deleted.</Typography>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			<Header  />
			<Card className={classes.card} key={announcement.title}>
				<CardHeader
					title={announcement.title}
					subheader={announcement.announcementDate}
				/>
				<CardContent>
					<Typography
						component="h1"
						variant="h6"
					>Message</Typography>
					<Typography>
						{announcement.desc}
					</Typography>

					<Typography
						component="h1"
						variant="h6"
						className={classes.resourcesLabel}
					>Linked Resources</Typography>
					<Typography className={classes.link} >
						{
							announcement.url1 ?
								<a 
									href={announcement.url1} 
									target="_blank">Link 1</a>
							: ""
						}
					</Typography>
					<Typography className={classes.link}>
						{
							announcement.url2 ?
								<a 
									href={announcement.url2} 
									target="_blank">Link 2</a>
							: ""
						}
					</Typography>
					<Typography className={classes.link}>
						{
							announcement.url3 ?
								<a 
									href={announcement.url3} 
									target="_blank">Link 3</a>
							: ""
						}
					</Typography>
				</CardContent>
			</Card>

		</div>
	)
}

export default AnnouncementRendererComponent
