import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';

import MainContext from '../state/main.context';
import { SignOut } from '../utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  headerTitles: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    // flexGrow: 0.5,
    // display: 'flex',
    // justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    flexGrow: '6',
    fontWeight: 'bolder'
  },
  menuButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    cursor: 'pointer',
    margin: theme.spacing(1)
  },
  logo: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    maxHeight: 75,
    maxWidth: 75
  }
}))


const CustomHeader = ({ title, toggleMenu }) => {

  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);
  const [ pageTitle, setPageTitle ] = useState(title);
  const [ showMenu, setShowMenu ] = useState(true);
  const [ open, setOpen ] = useState(false);
  const anchorRef = React.useRef(null);

  let { mainState, mainDispatch } = context;

  const handleClick = () => {
    if(!mainState.authUser || title === 'Home') {
      history.push('/login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/home');
    SignOut();
  }


    return (
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.headerTitles}>

            <img
              onClick={() => history.push('/home')}
              className={classes.logo}
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2FApproveItLogoTrans%20WHITE.png?alt=media&token=662d8e7e-62fa-4e26-a30f-1536e510f5f8"}
            />

            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            Manage iT  |
            </Typography>

            <Typography variant="body1">{ title }</Typography>

          </div>

          <Button
            color='inherit'
            className={classes.loginBtn}
            onClick={handleClick}
          >
            {(mainState.authUser && title !== 'Home') ? 'Logout' : 'Login'}
          </Button>

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
