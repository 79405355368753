import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Paper,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { firebaseApp } from '../../firebase';
import { getDomainData } from './getters/getDomainData';
import { getUserData } from './getters/getUserData';
import { RecordActions } from './admin_actions';
import MainContext from '../state/main.context';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import '../css/heat-map.css';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    width: '90vw',
    minHeight: '100vh',
    borderRadius: theme.spacing(3)
  },
  divConts: {
    flexGrow: 1,
    justifySelf: 'center',
    // minWidth: 450,
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 450
    }
  },
  divConts2: {
    flexGrow: 1,
    justifySelf: 'center',
    // minWidth: 450,
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: '35%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 250
    }
  },
  heatMapCont: {
    // flexGrow: 1,
    minWidth: '100%',
    margin: theme.spacing(2)
  },
  titleIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  title: {
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(2)
  },
  currentInfo: {
    margin: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(2)
  },
  menuBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(1)
  },
  imgCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  img: {
    height: 200,
    width: 200
  },
  heatmap: {
    minWidth: 800,
    margin: theme.spacing(2)
  },
  configTour: {
    width: 1000
  }
}));

const ConfigurationsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ domainData, setDomainData ] = useState({
    orgName: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    domains: [],
    defaultGroup: '',
    orgUnits: []
  });

  const [ userData, setUserData ] = useState({});
  const [ domainFormDisabled, setDomainDisabled ] = useState(true);
  const [ userFormDisabled, setUserDisabled ] = useState(true);
  const [ adminFormDisabled, setAdminDisabled ] = useState(true);
  const [ publicDisabled, setPublicDisabled ] = useState(true);
  const [ chartData, setChartData ] = useState([]);
  const [ admin, setAdminInfo ] = useState({});
  const [ appFormDisabled, setAppFormDisabled ] = useState(true);
  const [ formEnabled, setFormEnabled ] = useState(false);
  const [ formAccessGroup, setFormAccessGroup ] = useState([]);
  const [ trackedGroups, setTrackedGroups ] = useState([]);
  const [ googleClassroomData, setGoogleClassroomData ] = useState(null);
  const startDate = moment().subtract(365,'days').format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");
  const [ helpers, setHelpers ] = useState(null);
  const [ showTour, setShowTour ] = useState(true);
  const steps = [
    {
      target: '.config-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.config-contact',
      content: "This is the district's main contact.  Click on the pencil to update information.",
      placement: 'right',
    },
    {
      target: '.config-org',
      content: <div style={{ textAlign: 'left', marginTop: '10vh' }}>
        <p>These are the district's default settings.</p>
        <p><b>Default Group</b> is the group that users are assigned if they are new to the domain and are added via the Chrome extension.  You can always change later but this allows the user to use the extension at the onset.</p>
        <p><b>Group(s) Tracked for Site Activity</b> is where you indicate the groups that you would like to track for site usage data.</p>
        <p><b>Domains and Subdomains</b> is where you list (comma-separated) the domains in your district that you would like to be able to access/utilized the Chrome extension.</p>
        <p><b>Organization</b> is the name of your district.</p>
        <p>Click on the pencil to edit.  You may edit one or more items at a time.</p>
      </div>,
      placement: 'left'
    },
    {
      target: '.config-public',
      content: <div>
        <p>This is where you can add information that you would like to render on your public-facing site.</p>
        <p>See our <a href="https://manage-it.app/public/approveit.app" target="_blank">example page</a>.  Your entries are the two paragraphs/lines on the right side of the logo.</p>
      </div>,
      placement: 'right',
    },
    {
      target: '.config-img',
      content: 'Add/Update your logo here.  This is the logo that will render on your public facing site.  Click on the image or placeholder to update the image.',
      placement: 'left',
    },
    {
      target: '.config-heatmap',
      content: "This is your public facing site's daily visits for the last 360 days.  Hover over each tile to see how many visits the site has had.  The darker the color the more visits.",
      placement: 'top',
    }
  ]

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    if(!mainState.domainData) {
      initGetDomainData(mainState.domain,mainState.authUser.email);
    } else {
      setDomainData(mainState.domainData);

      //  CHART DATA
     if(mainState.domainData.hasOwnProperty('public_site_visits') && 
          mainState.domainData.public_site_visits.length > 0) {

        let barData = mainState.domainData.public_site_visits.reduce((acc,current) => {
          let date = moment(current).format('YYYY-MM-DD');
          let itemExist = false;

          acc.some(item => {
            if(item.date == date) {
              item.count++;
              itemExist = true;
              return true;
            }
            return false;
          })

          if(!itemExist){
            acc.push({
              date: date,
              count: 1
            })
          }

          return acc;
        },[]);

        setChartData(barData);

      }

      //  IF FORM USE IS CONFIGURED
      if(mainState.domainData.hasOwnProperty('form_configs')) {
        setFormEnabled(mainState.domainData.form_configs.enabled);
        setFormAccessGroup(mainState.domainData.form_configs.formAccessGroup);
      }

      if(mainState.domainData.hasOwnProperty('admin')) {
        setAdminInfo(mainState.domainData.admin);
      }

      if(mainState.domainData.hasOwnProperty('google_classroom_visits')) {
        setGoogleClassroomData(mainState.domainData.google_classroom_visits);
      }

      if(mainState.domainData.hasOwnProperty('site_activity_groups')) {
        setTrackedGroups(mainState.domainData.site_activity_groups);
      }

      mainDispatch({ type: 'setLoading', payload: false });
    }

    if(!mainState.userData) {
      initGetUserData(mainState.domain,mainState.authUser.email);
    } else {
      setUserData(mainState.userData);
      mainDispatch({ type: 'setLoading', payload: false });
    }
  },[]);

  useEffect(() => {},[ domainData ])

  const initGetDomainData = async (domain,adminEmail) => {

    mainDispatch({ type: 'setLoading', payload: true });

    let receivedData = await getDomainData(domain,adminEmail);

    if(!receivedData) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setDomainData', payload: receivedData });

    if(receivedData.hasOwnProperty('public_site_visits') && receivedData.public_site_visits.length > 0) {

      let barData = receivedData.public_site_visits.reduce((acc,current) => {
        let date = moment(current).format('YYYY-MM-DD');
        let itemExist = false;

        acc.some(item => {
          if(item.date == date) {
            item.count++;
            itemExist = true;
            return true;
          }
          return false;
        })

        if(!itemExist){
          acc.push({
            date: date,
            count: 1
          })
        }

        return acc;
      },[]);

      setChartData(barData);
    }

    //  IF FORM USE IS CONFIGURED
    if(receivedData.hasOwnProperty('form_configs')) {
      setFormEnabled(receivedData.form_configs.enabled);
      setFormAccessGroup(receivedData.form_configs.formAccessGroup);
    }
 
    setDomainData(receivedData);

    if(receivedData.hasOwnProperty('admin')) {
        setAdminInfo(receivedData.admin);
    }

    mainDispatch({ type: 'setLoading', payload: false });

  }

  const initGetUserData = async (domain,email) => {

    mainDispatch({ type: 'setLoading', payload: true });


    let receivedData = await getUserData(domain,email);

    if(!receivedData) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    setUserData(receivedData);
    mainDispatch({ type: 'setLoading', payload: false });

  }

  const updateDomain = e => {

    e.preventDefault();

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .set(domainData)
      .then(() => {
        initGetDomainData(mainState.domain,mainState.authUser.email);
        setDomainDisabled(true);
        RecordActions(domainData.domain,mainState.authUser.email,"Updated Domain Information");
      })
      .catch(err => {
        mainDispatch({ type:'setLoading', payload: false });
        setDomainDisabled(true);
        alert('There was an issue.  Pleaes try again later.');
      })

  }

  const updateDomainKey = _.debounce((key,val) => {

    let tempData = domainData;

    switch(key) {
      case 'street':
      case 'city':
      case 'state':
      case 'zip':
        tempData.address[key] = val;
        break;
      case 'site_activity_groups':
        tempData.site_activity_groups = [...val];
        setTrackedGroups(val);
        break;

      case 'domains':
        tempData.domains = val.split(',').map(each => each.trim());
        break;

      default:
        tempData[key] = val;
    }

    setDomainData(tempData);

  },500);

  const updateUserKey = _.debounce((key,val) => {

    let tempData = userData;

    tempData[key] = val;
    tempData.displayName = tempData.firstName + " " + tempData.lastName;

    setUserData(tempData);

  },500);

  const updateProfile = e => {

    e.preventDefault();

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .collection('users').doc(userData.email)
      .set(userData)
      .then(() => {
        initGetUserData(mainState.domain,mainState.authUser.email);
        setUserDisabled(true);
        RecordActions(domainData.domain,mainState.authUser.email,"Updated Their Own Profile Information");
      })
      .catch(err => {
        mainDispatch({ type:'setLoading', payload: false });
        setUserDisabled(true);
      })

  }

  const updateAdminInfo = e => {

    e.preventDefault();

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .update({
        admin: admin
      })
      .then( () => {
        initGetDomainData(mainState.domain,mainState.authUser.email);
        mainDispatch({ type:'setLoading', payload: false });
        setAdminDisabled(true);
        RecordActions(domainData.domain,mainState.authUser.email,"Updated Designated Admin Information");
      })
      .catch(err => {
        mainDispatch({ type:'setLoading', payload: false });
        setUserDisabled(true);
      })

  }

  const updateAdminKey = _.debounce((key,val) => {

    let tempData = admin;

    tempData[key] = val;
    tempData.displayName = tempData.firstName + " " + tempData.lastName;

    setAdminInfo(tempData);

  },500);

  const updatePublicKey = _.debounce((key,val) => {

    let tempData = domainData;

    if(!('publicInfo' in tempData)) {
      tempData['publicInfo'] = {};
      tempData.publicInfo.desc1 = '';
      tempData.publicInfo.desc2 = '';
    }

    tempData.publicInfo[key] = val;

    setDomainData(tempData);

  },500);

  const updatePublicInfo = e => {

    let tempData = domainData;

    e.preventDefault();

    if(!('publicInfo' in domainData)) {
      tempData['publicInfo'] = {};
    }

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .update({
        publicInfo: tempData.publicInfo
      })
      .then(() => {
        mainDispatch({ type:'setLoading', payload: false });
        setPublicDisabled(true);
        RecordActions(domainData.domain,mainState.authUser.email,"Updated Public-Facing Information");
      })
      .catch(err => {
        mainDispatch({ type:'setLoading', payload: false });
        setPublicDisabled(true);
      })
  }

  const updateFormInfo = e => {

    e.preventDefault();

    mainDispatch({ type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(domainData.domain)
      .update({
        form_configs: {
          enabled: formEnabled,
          formAccessGroup: formAccessGroup
        }
      })
      .then(() => {
        initGetDomainData(mainState.domain,mainState.authUser.email);
        mainDispatch({ type:'setLoading', payload: false });
        RecordActions(domainData.domain,mainState.authUser.email,"Updated form_configs");
      })
      .catch(err => {
        console.dir(err);
        alert('There was an error.  Please try again later or contact use at support@approveit.app.')
        mainDispatch({ type:'setLoading', payload: false });
      })

  }

  const uploadImage = (file) => {

    if(file.size > 100000) {
      alert("Please upload files that are 100kb or less in size.");
      return;
    }

    let acceptedFileTypes = ["image/png","image/jpeg"];
    let contentType = file.type;

    if(!acceptedFileTypes.includes(contentType)) {
      alert('Please upload .png or .jpg files only.');
      return;
    }

    mainDispatch({ type: 'setLoading', payload: true })

    let fileName = mainState.domain;

    if(contentType == 'image/jpeg') {
      fileName = fileName + ".jpg";
    }

    if(contentType == 'image/png') {
      fileName = fileName + ".png";
    }

    var storageRef = firebaseApp.storage().ref();
    var imageRef = storageRef.child(`${mainState.domain}/${fileName}`);
    let metadata = {
      contentType: contentType
    }

    const reader = new FileReader();

    reader.onload = function(img) {

      if(img.currentTarget.readyState == 2) {

        //  SAVE IMAGE TO FIRE STORAGE
        imageRef.putString(img.currentTarget.result,"data_url")
          .then(snapshot => {

            //  GET DOWNLOAD URL
            snapshot.ref.getDownloadURL()
            .then(downloadURL => {

              //  SAVE DOWNLOAD URL TO DOMAIN DATA
              firebaseApp.firestore().collection('domains').doc(mainState.domain)
                .update({
                  "publicInfo.image": downloadURL
                })
                .then(() =>{
                  mainDispatch({ type: 'setLoading', payload: false })
                  RecordActions(domainData.domain,mainState.authUser.email,"Updated Domain Logo");
                })
                .catch(error => {
                  console.dir(error);
                  mainDispatch({ type: 'setLoading', payload: false })
                  alert('There was an error.  Please try again later.');
                })

            })
            .catch(err => {
              console.dir(err);
              mainDispatch({ type: 'setLoading', payload: false })
              alert('There was an error.  Please try again later.');
            })
          })
          .catch(err => {
            console.dir(err);
            mainDispatch({ type: 'setLoading', payload: false })
            alert('There was an error.  Please try again later.');
          })

      }
    }

    reader.readAsDataURL(file);
  }

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }

  }


  return (
    <div className={`${classes.root} config-root`}>

    <Joyride
      run={showTour}
      autoStart={true}
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleCallback}
    />

    {/*  User Profile  */}
    <Paper className={`${classes.divConts}`} elevation={3}>

      <form className={classes.form} onSubmit={updateProfile}>

      <div className={classes.titleIcon}>

        <Typography
          variant="h6"
          align="center"
          className={classes.title}
        >User Profile</Typography>

        <EditIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => setUserDisabled(!userFormDisabled)}
        />

      </div>

        {
          userFormDisabled ? null

          : <TextField
              className={classes.textField}
              label="First Name"
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.firstName : '' 
              }
              onChange={e => {
                updateUserKey('firstName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ? null

          : <TextField
              className={classes.textField}
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.lastName : '' 
              }
              label="Last Name"
              onChange={e => {
                updateUserKey('lastName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.displayName : '' }
            </Typography>

          : null
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.position : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Position"
              placeholder={ 
                Object.keys(userData).length > 0 ? userData.position : '' 
              }
              onChange={e => {
                updateUserKey('position',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(userData).length > 0 ? userData.phone : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Phone"
              placeholder={ Object.keys(userData).length > 0 ? userData.phone : '' }
              onChange={e => {
                updateUserKey('phone',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          userFormDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }

      </form>

    </Paper>

    {/*  Designated Admin  */}
    <Paper className={`${classes.divConts} config-contact`} elevation={3}>

      <form className={classes.form} onSubmit={updateAdminInfo}>

      <div className={classes.titleIcon}>

        <Typography
          variant="h6"
          align="center"
          className={classes.title}
        >Designated Admin</Typography>

        <EditIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => setAdminDisabled(!adminFormDisabled)}
        />

      </div>

        {  //  FIRST NAME
          adminFormDisabled ? null

          : <TextField
              className={classes.textField}
              label="First Name"
              placeholder={ 
                Object.keys(admin).length > 0 ? admin.firstName : ''
              }
              onChange={e => {
                updateAdminKey('firstName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {  // LAST NAME
          adminFormDisabled ? null

          : <TextField
              className={classes.textField}
              label="Last Name"
              placeholder={ 
                Object.keys(admin).length > 0 ? admin.lastName : ''
              }
              onChange={e => {
                updateAdminKey('lastName',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          adminFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(admin).length > 0 ? admin.displayName : '' }
            </Typography>

          : null
        }

        {  // POSITION
          adminFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(admin).length > 0 ? admin.position : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Position"
              placeholder={ 
                Object.keys(admin).length > 0 ? admin.position : ''
              }
              onChange={e => {
                updateAdminKey('position',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {  // EMAIL
          adminFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(admin).length > 0 ? admin.email : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Email"
              placeholder={ 
                Object.keys(admin).length > 0 ? admin.email : ''
              }
              onChange={e => {
                updateAdminKey('email',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {  //  PHONE
          adminFormDisabled ?

            <Typography className={classes.currentInfo}>
              { Object.keys(admin).length > 0 ? admin.phone : '' }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Phone"
              placeholder={ 
                Object.keys(admin).length > 0 ? admin.phone : ''
              }
              onChange={e => {
                updateAdminKey('phone',e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          adminFormDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }

      </form>
    </Paper>

  {/*  Form Config  */}
    <Paper className={`${classes.divConts} form-config`} elevation={3}>

      <form className={classes.form} onSubmit={updateFormInfo}>

      <div className={classes.titleIcon}>

        <Typography
          variant="h6"
          align="center"
          className={classes.title}
        >Inquiry Form Config</Typography>

        <EditIcon
          color="primary"
          style={{ cursor: 'pointer' }}
          onClick={() => setAppFormDisabled(!appFormDisabled)}
        />

      </div>

         <FormControlLabel
            control={
              <Switch
                disabled={appFormDisabled}
                checked={formEnabled}
                onChange={() => setFormEnabled(!formEnabled)}
                name="formEnabled"
                color="primary"
              />
            }
            label="Enable"
          />

        {  //  GROUPS
          appFormDisabled ?
            
            <React.Fragment>
              <Typography variant='h6'>
                Groups with access to the form
              </Typography>
              <Typography style={{leftPadding: '10px'}}>
                { formAccessGroup.length > 0 ? 
                    formAccessGroup.join(', ') 
                  : 'Not configured' 
                }
              </Typography>
            </React.Fragment>

          : <React.Fragment>

              <FormGroup>
                {
                  (mainState && 
                    mainState.domainData.hasOwnProperty('orgUnits') && 
                    mainState.domainData.orgUnits.length > 0)
                    
                    ?  mainState.domainData.orgUnits.map(ou => 

                        <FormControlLabel
                          key={ou} 
                          control={
                            <Checkbox  
                              checked={formAccessGroup.includes(ou)}
                              onChange={(e) => {
                                if(formAccessGroup.includes(ou)) {
                                  let tempGroups = formAccessGroup.filter(group => group !== ou);
                                  setFormAccessGroup(tempGroups);
                                } else {
                                  let newGroup = [...formAccessGroup,ou];
                                  setFormAccessGroup(newGroup);
                                }
                              }}
                            />
                          } 
                          label={ou} 
                        />

                      )

                    : null
                }
              </FormGroup>

            </React.Fragment>
        }

        {
          appFormDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }

      </form>
    </Paper>

  {/*  ORGANIZATION  */}
    <Paper className={`${classes.divConts} config-org`} elevation={3}>

      <form className={classes.form} onSubmit={updateDomain}>

        <div className={classes.titleIcon}>

          <Typography
            variant="h6"
            align="center"
            className={classes.title}
          >Organization Information</Typography>

          <EditIcon
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => setDomainDisabled(!domainFormDisabled)}
          />

        </div>

        {
          domainFormDisabled ?

            <div className={classes.currentInfo}>
              <Typography>
                <b>Default Group:</b> { domainData.defaultGroup || "Needs to be configured." }
              </Typography>
              <Typography style={{margin: '5px'}}>
                This is the group assigned to users who are added through the chrome extension.
              </Typography>
            </div>

            : <TextField
              className={classes.textField}
              label="Default Group"
              placeholder={ domainData.defaultGroup || '' }
              onChange={e => {
                updateDomainKey('defaultGroup', e.target.value.trim())
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              helperText="Default group will be given to new users."
            />
        }

        {
          domainFormDisabled ?

            <Typography className={classes.currentInfo}>
              <b>Group(s) Tracked for Site Activity: </b>
              {
                domainData.hasOwnProperty('site_activity_groups') ?

                   domainData.site_activity_groups.join(',')

                  : ""
              }
            </Typography>

            : <React.Fragment>

                <FormGroup>
                  {
                    (mainState && 
                      mainState.domainData.hasOwnProperty('orgUnits') && 
                      mainState.domainData.orgUnits.length > 0)
                      
                      ?  mainState.domainData.orgUnits.map(ou => 

                          <FormControlLabel
                            key={ou} 
                            control={
                              <Checkbox  
                                checked={trackedGroups.includes(ou)}
                                onChange={(e) => {
                                  if(trackedGroups.includes(ou)) {
                                    let tempGroups = trackedGroups.filter(group => group !== ou);
                                    updateDomainKey('site_activity_groups', tempGroups);
                                  } else {
                                    let newGroup = [...trackedGroups,ou];
                                    updateDomainKey('site_activity_groups', newGroup);
                                  }
                                }}
                              />
                            } 
                            label={ou} 
                          />

                        )

                      : null
                  }
                </FormGroup>

              </React.Fragment>

        }

        {
          domainFormDisabled ?

            <Typography className={classes.currentInfo}>
              <b>Domains and Subdomains:</b> { domainData.domains.join(',') }
            </Typography>

            : <TextField
                className={classes.textField}
                label="Domains"
                placeholder={ domainData.domains.join(',') }
                helperText="Separate by comma (approveit.app, students.approveit.app)"
                onChange={e => {
                  updateDomainKey('domains', e.target.value)
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
        }

        {
          domainFormDisabled ?

            <Typography className={classes.currentInfo}>
              <b>Organization:</b> { domainData.orgName }
            </Typography>

            : <TextField
              className={classes.textField}
              label="Organization Name"
              placeholder={ domainData.orgName }
              onChange={e => {
                updateDomainKey('orgName', e.target.value)
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
        }

        {
          domainFormDisabled ? null

            : <Typography align="center" className={classes.title}>Address</Typography>
        }

        {
          domainFormDisabled ?
            <div className={classes.currentInfo}>
              <Typography>
                { domainData.address.street }
              </Typography>
              <Typography>
                { domainData.address.city }, { domainData.address.state } { domainData.address.zip }
              </Typography>
            </div>

            : <TextField
                className={classes.textField}
                label="Street"
                placeholder={ domainData.address.street }
                onChange={e => {
                  updateDomainKey('street', e.target.value)
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
        }

        {
          domainFormDisabled ? null

            : <TextField
                className={classes.textField}
                label="City"
                placeholder={ domainData.address.city }
                onChange={e => {
                  updateDomainKey('city', e.target.value)
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
        }

        {
          domainFormDisabled ? null

            : <TextField
                className={classes.textField}
                label="State (e.g. IL)"
                placeholder={ domainData.address.state }
                onChange={e => {
                  updateDomainKey('state', e.target.value)
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
        }

        {
          domainFormDisabled ? null

            : <TextField
                className={classes.textField}
                label="Zip Code"
                placeholder={ domainData.address.zip }
                onChange={e => {
                  updateDomainKey('zip', e.target.value)
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
        }

        {
          domainFormDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }
      </form>

    </Paper>

    {/*  PUBLIC INFORMATION  */}
    <Paper className={`${classes.divConts} config-public`} elevation={3}>

      <form className={classes.form} onSubmit={updatePublicInfo}>

        <div className={classes.titleIcon}>

          <Typography
            variant="h6"
            align="center"
            className={classes.title}
          >Public Information</Typography>

          <EditIcon
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => setPublicDisabled(!publicDisabled)}
          />
        </div>

        {
          publicDisabled ? null

          :  <Typography variant="h6" component="h1" align="center">
              This is information that will be added at the top of the public website.
             </Typography>
        }


        {
          publicDisabled ?

            <Typography className={classes.currentInfo}>
              {
                domainData.hasOwnProperty('publicInfo') && domainData.publicInfo.hasOwnProperty('desc1') ?

                    domainData.publicInfo.desc1

                  : "Description 1 is not being used."

              }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Description 1"
              multiline
              rows={3}
              onChange={e => {
                updatePublicKey('desc1',e.target.value);
              }}
              variant="outlined"
            />
        }

        {
          publicDisabled ?

            <Typography className={classes.currentInfo}>
              {
                domainData.hasOwnProperty('publicInfo') && domainData.publicInfo.hasOwnProperty('desc2') ?

                    domainData.publicInfo.desc2

                  : "Description 2 is not being used."
              }
            </Typography>

          : <TextField
              className={classes.textField}
              label="Description 2"
              multiline
              rows={3}
              onChange={e => {
                updatePublicKey('desc2',e.target.value);
              }}
              variant="outlined"
            />
        }

        {
          publicDisabled ? null

            : <Button
                color='primary'
                variant="contained"
                type='submit'
              >
                Update
              </Button>
        }
      </form>

    </Paper>

    {/*  PUBLIC IMAGE  */}
    <Paper className={`${classes.divConts} config-img`} elevation={3}>

      <div className={classes.form}>

        <div className={classes.titleIcon}>

          <Typography
            variant="h6"
            align="center"
            className={classes.title}
          >Public Image | Logo</Typography>

        </div>

        {
          (domainData.hasOwnProperty('publicInfo') && domainData.publicInfo.hasOwnProperty('image') &&
           domainData.publicInfo.image) ?

              <label htmlFor={mainState.domain} className={classes.imgCont}>
                <img src={domainData.publicInfo.image} className={classes.img} />
                <input type='file'
                  id={mainState.domain}
                  onChange={e => {
                    uploadImage(e.target.files[0]);
                  }}
                  style={{ display: 'none'}}
                />
              </label>

            :
              <label htmlFor="domain-image" >
                <Typography
                  variant="h4"
                  component="h1"
                  align='center'
                  style={{
                    marginTop: '30px',
                    cursor: 'pointer'
                  }}
                >Upload Image</Typography>
                <Typography variant="subtitle1" component="h1" align='center'>.png or .jpg only</Typography>
                <Typography variant="subtitle1" component="h1" align='center'>100kb or less</Typography>
                <input type='file'
                  id="domain-image"
                  onChange={e => {
                    uploadImage(e.target.files[0]);
                  }}
                  style={{ display: 'none'}}
                />
              </label>
        }
      </div>

    </Paper>

    {
      googleClassroomData && (
        <Paper className={`${classes.divConts2} config-img`} elevation={3}>

          <div className={classes.titleIcon}>

            <Typography
              variant="h6"
              align="center"
              className={classes.title}
            >Google Classroom Usage</Typography>

          </div>

          <div className={classes.form}>
            <Typography
              component="h1"
            >
              <b>Last Visited:</b> {moment(new Date(googleClassroomData.last_visit)).format("ddd, MMM Do YYYY, h:mm a")}
            </Typography>
            <Typography>
              <b>Total Visits:</b> {googleClassroomData.total}
            </Typography>
          </div>
        </Paper>
      )
    }

    {/*  PUBLIC SITE VISITS  */}
    <Paper className={`${classes.heatMapCont} config-heatmap`} elevation={3}>

      <Typography
        variant="h6"
        align="center"
        className={classes.title}
      >Public Site Visits</Typography>

      <div className={classes.heatmap}>


        <CalendarHeatmap
          startDate={moment().subtract(365,'days')}
          endDate={new Date()}
          values={chartData}
          gutterSize={0.5}
          classForValue={value => {
            if (!value) {
              return 'color-empty';
            }
            return `color-github-${value.count}`;
          }}
          tooltipDataAttrs={value => {

            if(!value.date) {
              return {
                'data-tip': 'No data for this day.'
              }
            } else {
              return {
                'data-tip': `Date: ${value.date} | Visits: ${
                  value.count
                }`,
              };
            }

          }}
          showWeekdayLabels={true}
        />
        <ReactTooltip />


      </div>
    </Paper>

    </div>
  )
}

export default ConfigurationsComponent;
