import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardAction,
  CardContent,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: theme.spacing(2),
		maxWidth: 850,
		minWidth: 850
	},
	accordion: {
		minWidth: 850,
		maxWidth: 850,
		margin: theme.spacing(1)
	},
	summary: {
	    backgroundColor: 'lightgrey'
	},
	summaryContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	heading: {
		marginLeft: theme.spacing(1),
	    fontWeight: 'bold'
	},
	accordionDetails: {
		display: 'flex',
		flexDirection: 'column'
	},
	materials: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 850
	}
}));

const WorkCard = ({ assignment }) => {

	const classes = useStyles();
	const re = /_/gi;

	return (
		<Accordion className={classes.accordion}>
			<AccordionSummary
	            className={classes.summary}
	          >
	          	<div className={classes.summaryContainer}>
	                <Typography className={classes.heading}> { assignment.title } </Typography>
	                <Typography>{moment(assignment.creationTime).format('ddd, MMM Do YYYY, h:mm a')}</Typography>
	            </div>
	        </AccordionSummary>

	         <AccordionDetails
	            className={classes.accordionDetails}
	         >

	         	{ //  ONLY IF THERE IS A DUE DATE
                	assignment.hasOwnProperty('dueDate') && (
                		<Typography className={classes.dueDate}> 
                			<b>Due: </b>
			                {
			                	moment(assignment.dueDate.year + "-" + assignment.dueDate.month + "-" + assignment.dueDate.day).format("ddd, MMM Do YYYY")
			                } 
                		</Typography>
                	)
                }

	         	<div className={classes.materials}>
	         		<Typography component="h1"><b>Type:</b> { assignment.hasOwnProperty('workType') ? assignment.workType.replace(re," ") : 'MATERIAL' }
	         		</Typography>

	         		<Typography component="h1"><b>Description:</b> { assignment.description }</Typography>

	         		<Typography component="h1"><b>Materials:</b></Typography>

	         		{  //  PARSE THROUGH MATERIALS AND RENDER EACH COURSE WORK POSTINGS
	         			(assignment.hasOwnProperty('materials') && assignment.materials.length > 0) && (
	         				assignment.materials.reduce((acc,curr) => {
	         					if(curr.hasOwnProperty('driveFile')) {
	         						let tempObj = {};
	         						tempObj.title = curr.driveFile.driveFile.title;
	         						tempObj.url = curr.driveFile.driveFile.alternateLink;
	         						acc.push(tempObj);
	         						return acc;
	         					}
	         					if(curr.hasOwnProperty('form')) {
	         						let tempObj = {};
	         						tempObj.title = curr.form.title;
	         						tempObj.url = curr.form.formUrl;
	         						acc.push(tempObj);
	         						return acc;
	         					}
	         					if(curr.hasOwnProperty('link')) {
	         						let tempObj = {};
	         						tempObj.title = curr.link.title;
	         						tempObj.url = curr.link.url;
	         						acc.push(tempObj);
	         						return acc;
	         					}

	         					return acc;

	         				},[]).map(each => 
	         					<a key={each.url} href={each.url} target="_blank">
	         						<Typography>{each.title}</Typography>
	         					</a>
	         				)
	         			)
	         		}

	         	</div>
	          	

	         </AccordionDetails>

		</Accordion>
	)
}

const CourseWorkComponent = ({ courseWork }) => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		
		

	},[]);

	if(courseWork.length <= 0) {
		return (
			<Typography variant='subtitle1' component='h1' align='center'>No Course Work</Typography>
		)
	}

	return (
		<div className={classes.root}>
			{

				courseWork.map(work => <WorkCard key={work.id} assignment={work} />)
				

			}

		</div>
	)
}

export default CourseWorkComponent
