import React, { useEffect, useState, useContext } from 'react';
import {
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import MaterialTable, { MTableEditField } from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import { firebaseApp } from '../../../firebase';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 150,
    maxHeight: 150,
    overflow: 'scroll'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  url: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  notes: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  groups: {
    maxWidth: 200,
    maxHeight: 200,
    overflowWrap: 'normal'
  },
  editField: {
    width: 200,
    height: 100
  }
}));

const ResourcesTableComponent = props => {
  const context = useContext(MainContext);
  const classes = useStyles();
  let { mainState, mainDispatch } = context;
  const [ resources, setResources ] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ orgUnits, setOUs ] = useState(["all"]);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    if(mainState.domainData.hasOwnProperty('orgUnits') && mainState.domainData.orgUnits.length > 0) {
      setOUs([...orgUnits,...mainState.domainData.orgUnits]);
    }

    const resourcesListener = firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('resources').onSnapshot(snapshot => {
        if(snapshot.length <= 0 ) {
          return;
        }
        let tempResources = [];
        snapshot.forEach(snap => {
          let tempObj = snap.data();
          tempObj._id = snap.id;
          tempResources.push(tempObj);
        });

        setResources([...tempResources]);
      });

      return () => resourcesListener();
  },[])

  const deleteResource = (id,title) => {
    mainDispatch({type: 'setLoading', payload: true });
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('resources').doc(id)
      .delete()
      .then(() => {
        console.log('Deleted resource.');
        mainDispatch({type: 'setLoading', payload: false });
        RecordActions(mainState.domain,mainState.authUser.email,`Deleted Resource: ${ title }`);
      })
      .catch(err => {
        console.log('Error deleting resource.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const updateResource = newData => {
    let tempId = newData._id;

    delete newData._id;

    if(typeof newData.groups === 'string') {
      newData.groups = newData.groups.split(',').map(item => item.trim().toLowerCase());
    }

    mainDispatch({type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('resources').doc(tempId)
      .set(newData)
      .then(() => {
        console.log('Updated resource.');
        mainDispatch({type: 'setLoading', payload: false });
        RecordActions(mainState.domain,mainState.authUser.email,`Updated Resource: ${ newData.title }`);
      })
      .catch(err => {
        console.log('Error updating resource.');
        mainDispatch({type: 'setLoading', payload: false });
      })

  }

  const columns = [
    {
      title: 'Title',
      field: 'title',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('title') ?  rowData.title.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Desc',
      field: 'desc',
      render: rowData =>
        <p className={classes.desc}>
          { rowData.hasOwnProperty('desc') ?  rowData.desc : '' }
        </p>
    },
    {
      title: 'URL',
      field: 'url',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('url') ?  rowData.url : '' }
        </p>
    },
    {
      title: 'Category',
      field: 'category',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('category') ?  rowData.category : '' }
        </p>
    },
    {
      title: 'Organizational Units Access',
      field: 'groups',
      render: rowData =>
        <p className={classes.groups}>
          { rowData.hasOwnProperty('groups') ?  rowData.groups.join("\n") : '' }
        </p>,
      editComponent: props => {

        return (

          <FormGroup>
            {
              orgUnits.length > 0
                
                ?  orgUnits.map(ou => 

                    <FormControlLabel
                      key={ou} 
                      control={
                        <Checkbox  
                          checked={props.rowData.groups.includes(ou)}
                          onChange={(e) => {
                            if(props.rowData.groups.includes(ou)) {
                              let tempGroups = props.rowData.groups.filter(group => (group !== ou && orgUnits.includes(group)));
                              props.onChange(tempGroups);
                            } else {
                              let newGroup = [...props.rowData.groups,ou];
                              newGroup = newGroup.filter(group => orgUnits.includes(group));
                              props.onChange(newGroup);
                            }
                          }}
                        />
                      } 
                      label={ou} 
                    />

                  )

                : null
            }
          </FormGroup>

        )
      }
    },
    // {
    //   title: 'Public',
    //   field: 'isPublic',
    //   editable: 'never',
    //   render: rowData =>
    //       <Checkbox
    //         checked={ rowData.hasOwnProperty('isPublic') ? rowData.isPublic : false }
    //         onChange={() => {
    //           let tempData = rowData;
    //           tempData.isPublic = !rowData.isPublic;
    //           updateResource(tempData);
    //         }}
    //         name="public"
    //       />
    // },
    {
      title: 'Notes',
      field: 'notes',
      render: rowData =>
        <p className={classes.notes}>
          { rowData.hasOwnProperty('notes') ?  rowData.notes : '' }
        </p>
    }
  ];

  return (
    <div className='resources-table'>
      <MaterialTable
        title="Resources"
        columns={columns}
        data={resources}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          },
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
          }),
          exportButton: true,
          exportAllData: true
        }}
        actions={[
          {
            icon: () =>
            <DeleteIcon
              color='secondary'
            />,
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              let c = window.confirm('Are you sure?');
              if(c){
                deleteResource(rowData._id, rowData.title);
              }
            }
          }
        ]}
        components={{
          Container: Box,
          EditField: props => (
            <MTableEditField {...props} className={classes.editField} />
          )
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateResource(newData);
              resolve();
            })
        }}
      />
    </div>
  )
}

export default ResourcesTableComponent;
