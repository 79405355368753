import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firebaseApp } from '../../../firebase';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexFlow: 'row',
		flexWrap: 'wrap',
		padding: theme.spacing(2)
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 400,
		maxWidth: 500,
		padding: theme.spacing(1),
		margin: theme.spacing(1)
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'row'
	}
}));

const DomainDetailsComponent = ({ domain }) => {

	const classes = useStyles();
	const [ loading, setLoading ] = useState(true);
	const [ domainData, setDomainData ] = useState(null);
	const domainRef = firebaseApp.firestore().collection('domains').doc(domain);

	useEffect(() => {
		// getDomainData();
		const domainListener = domainRef
			.onSnapshot(snapshot => {
				if(!snapshot.exists) {
					setLoading(false);
					return;
				}
				let data = snapshot.data();
				setDomainData(data);
				setLoading(false);
			});

			return () => domainListener();
	},[]);

	const getDomainData = async () => {
		let domainSnap = await firebaseApp.firestore().collection('domains').doc(domain).get();

		if(!domainSnap.exists) {
			setLoading(false);
			return;
		}
		let data = domainSnap.data();
		console.dir(data);
		setDomainData(data);
		setLoading(false);
	}

	if(loading) {
		return(
			<Typography align='center' component='h1' variant='body1'>
				Loading...
			</Typography>
		)
	}

	if(!domainData) {

		return (
			<Typography align='center' component='h1' variant='body1'>
				Data not found for {domain}.
			</Typography>
		)

	}

	const handleChange = async (e) => {
		e.persist();

		console.dir(e.currentTarget);

		let key = e.currentTarget.name;
		let val;

		if(!key) {
			return;
		}

		switch(key) {
			case 'active':
				val = !domainData.active;
				break;

			case 'subscription':
				val = e.currentTarget.value;
				break;
		}

		domainRef.update({
			[key]: val
		})

	}

	return (
		<div className={classes.root}>

			<Paper className={classes.paper}>
				<Typography align='center' component="h1" variant="h5">Status</Typography>
				
				<FormGroup row>
					<FormControlLabel
						label="Active"
						control={
							<Switch
								checked={domainData.active}
            					onChange={handleChange}
            					name='active'
            					color="primary"
							/>
						}
					/>
				</FormGroup>

				<FormControl>
					<FormLabel component="legend">Subscription</FormLabel>
					<RadioGroup 
						name="subscription" 
						value={domainData.subscription} 
						onChange={handleChange}
						className={classes.radioGroup}
					>
						<FormControlLabel value="premium" control={<Radio />} label="Premium"/>
						<FormControlLabel value="basic" control={<Radio />} label="Basic"/>
					</RadioGroup>
				</FormControl>

			</Paper>

			<Paper className={classes.paper}>

				<Typography align='center' component="h1" variant="h5">Organization</Typography>

				<Typography>{domainData.orgName}</Typography>
				<Typography>{domainData.address.street || ""}</Typography>
				<Typography>
					{domainData.address.city || ""}, {domainData.address.state || ""} {domainData.address.zip || ""}
				</Typography>

				<Typography>Users: {domainData.num_users || 0}</Typography>

			</Paper>

			<Paper className={classes.paper}>

				<Typography align='center' component="h1" variant="h5">Designated Admin</Typography>

				{
					domainData.hasOwnProperty('admin') && (
						<div>
							<Typography>{domainData.admin.displayName || ""}</Typography>
							<Typography>{domainData.admin.position || ""}</Typography>
							<Typography>{domainData.admin.email || ""}</Typography>
							<Typography>{domainData.admin.phone || ""}</Typography>
						</div>
					)
				}

			</Paper>

		</div>
	)
}

export default DomainDetailsComponent
