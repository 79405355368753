import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Tabs,
  Tab,
  Typography,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainContext from '../../state/main.context';
import BackDrop from '../../backdrop.component';
import ScheduleAnnouncementComponent from './schedule.announcement.component';
import ScheduledAnnouncementsComponent from './scheduled.announcements.component';
import SentAnnouncementsComponent from './sent.announcements.component';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: theme.spacing(5),
		marginLeft: theme.spacing(5),
		marginRight: theme.spacing(5),
		padding: theme.spacing(1),
		paddingBottom: '5vh',
		width: '90vw'
	},
	paperTabs: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.spacing(1)
	},
	attention: {
		margin: theme.spacing(3),
		padding: theme.spacing(1),
		maxWidth: 750
	}
}));

const AnnouncementsComponent = () => {

	const classes = useStyles();
	const context = useContext(MainContext);
	let { mainState, mainDispatch } = context;
	const [ tab, setTab ] = useState(0);
	const [ showTour, setShowTour ] = useState(true);
	const [ canSendAnnouncements, setCanSendAnnouncement ] = useState(true);

	const steps = [
	    {
	      target: '.announcement-root',
	      content: <div style={{ marginTop: '10vh' }}>
	        <p>
	          Click next to learn more about this page.
	        </p>
	      </div>,
	      placement: 'top-end',
	    },
	    {
	      target: '.announcement-schedule-tab',
	      content: 'Schedule announcements here.  If the date of the announcement is the same as the day you schedule it, it will become available for notification to you Chrome users.  Announcements are checked every 2 hours between 7:00am and 5:00pm.'
	    },
	    {
	      target: '.announcement-scheduled-tab',
	      content: 'This is where all of your scheduled announcements will be listed.  Each announcement can be edited and/or deleted before they are released to your Chrome users.'
	    },
	    {
	      target: '.announcements-tab',
	      content: 'This is where all released announcements will be listed.  Announcements will only be viewable on the Manage iT extension on the date that they are marked to be announced.'
	    }
    ];

    useEffect(() => {
    	if(!mainState) {
    		return;
    	}

    	if(mainState.domainData) {
    		if(mainState.domainData.subscription == 'basic') {
    			setCanSendAnnouncement(false);
    		}
    	}
    },[])

	const handleChange = (event, newValue) => {
	    setTab(newValue);
	};

	const handleCallback = data => {

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
		  // Need to set our running state to false, so we can restart if we click start again.
		  setShowTour(false);
		  setTimeout(() => setShowTour(true),500);
		}
	}

	return (
		<div className={`${classes.root} announcement-root`}>

			<Joyride
		        run={showTour}
		        steps={steps}
		        continuous={true}
		        showProgress={true}
		        showSkipButton={true}
		        callback={handleCallback}
		     />

			<Typography className={classes.attention}>
				<b>NOTICE:</b>  As of February 12, 2021, the announcement feature is live in our Chrome extension.  Announcements you create will become available within the extension.  In addition, notifications will be sent to your users' devices.  Please refer to this <a href="https://docs.google.com/document/d/e/2PACX-1vSCFFWr3qE3AA-Kb6TwQFSTmX7JY3BIUaOFkRstBZTEBOu0drfVV1EvUcWkXk5KXNKCgglJ8CHV094k/pub" target="_blank">document</a> to learn more before using this awesome feature.
			</Typography>

			<div className={classes.paperTabs}>
		        <Paper square>
		          <Tabs
		            value={tab}
		            indicatorColor="primary"
		            textColor="primary"
		            onChange={handleChange}
		            centered
		          >
		            <Tab label="Schedule Announcements" className="announcement-schedule-tab" />
		            <Tab label="Scheduled Announcements" className="announcement-scheduled-tab" />
		            <Tab label="Announcements" className="announcements-tab" />
		          </Tabs>
		        </Paper>
		      </div>

		      {
		      	tab === 0 && (
		      		<ScheduleAnnouncementComponent 
		      			canSendAnnouncements={canSendAnnouncements}
		      		/>
		      	)
		      }

		      {
		      	tab === 1 && (
		      		<ScheduledAnnouncementsComponent />
		      	)
		      }

		      {
		      	tab === 2 && (
		      		<SentAnnouncementsComponent />
		      	)
		      }


			<BackDrop loading={mainState.isLoading} />      
		</div>
	)
}

export default AnnouncementsComponent
