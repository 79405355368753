import React, { useEffect, useState, useContext } from 'react';
import {
  Checkbox,
  Box,
  Typography,
  TextField,
  Paper,
  Button
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MaterialTable, { MTableEditField, MTableEditRow, MTableToolbar } from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import { firebaseApp } from '../../../../firebase';
import { RecordActions } from '../../admin_actions';
import MainContext from '../../../state/main.context';
import { fade, makeStyles } from '@material-ui/core/styles';
import HeatMapComponent from '../../apps/heatmap.component';
import moment from 'moment';
import Papa from 'papaparse';
import { downloadCSV } from '../../../utilities/csv_downloader';


const useStyles = makeStyles(theme => ({
  title: {
    maxWidth: 150,
    maxHeight: 150,
    overflow: 'scroll'
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  url: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  category: {
    maxWidth: 200,
    overflow: 'scroll'
  },
  notes: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  groups: {
    maxWidth: 200,
    maxHeight: 200,
    minWidth: 100,
    minHeight: 50,
    overflowWrap: 'wrap'
  },
  editField: {
    width: 200,
    height: 100
  },
  detailPanel: {
    backgroundColor: fade(theme.palette.primary.main,0.20),
    padding: theme.spacing(2)
  },
  panel: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    maxWidth: '90vw'
  },
  leftPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  bottomPanel: {
    display: 'flex',
    maxWidth: '90vw',
    margin: theme.spacing(2)
  },
  stats: {
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '90vw'
  },
  img: {
    maxWidth: 100,
    maxHeight: 100,
    cursor: 'pointer'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  learnMore: {
    margin: theme.spacing(1)
  }
}));

const AppsTableComponent = () => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { apps } = mainState;
  const [selectedRow, setSelectedRow] = useState(null);

  //  LISTEN AND ADJUST TO APPS CHANGES
  useEffect(() => {},[ apps ])

  const deleteApp = rowData => {

    let urlRef = rowData.imgUrl;
    let isDomainImage = false;

    //  IF IMAGE WAS UPLOADED BY DOMAIN ON THE APP
    if(rowData.hasOwnProperty('isDomainImage') && rowData.isDomainImage) {
      isDomainImage = true;
    }

    mainDispatch({type: 'setLoading', payload: true });
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').doc(rowData._id)
      .delete()
      .then(() => {

        RecordActions(mainState.domain,mainState.authUser.email,`Deleted App: ${ rowData.title }`);

        // DELETE IMAGE FROM FIREBASE
        if(isDomainImage) {

          return firebaseApp.storage().refFromURL(urlRef).delete()
            .then(() => {
              console.log('Image was successfully deleted...');
              mainDispatch({type: 'setLoading', payload: false });
            })
            .catch(err => {
              console.dir(err);
              return mainDispatch({type: 'setLoading', payload: false });
            })

        }

        mainDispatch({type: 'setLoading', payload: false });
      })
      .catch(err => {
        console.log('Error deleting app.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const updateApp = (newValue,field, docId, title) => {

    if(field === 'groups' && typeof newValue === 'string') {
      newValue = newValue.split(',');
    }

    if(field === 'category' && typeof newValue === 'string') {
      newValue = newValue.split(',');
    }

    mainDispatch({type: 'setLoading', payload: true });

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').doc(docId)
      .update({
        [field]: newValue
      })
      .then(() => {
        console.log('Updated app.');
        mainDispatch({type: 'setLoading', payload: false });
        RecordActions(mainState.domain,mainState.authUser.email,`Updated App: ${ title } | ${ field } | ${ newValue }`);
      })
      .catch(err => {
        console.log('Error updating app.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const uploadImage = (file,app) => {

    if(file.size > 50000) {
      alert("Please upload files that are 50kb or less in size.");
      return;
    }

    let acceptedFileTypes = ["image/png","image/jpeg"];
    let contentType = file.type;

    if(!acceptedFileTypes.includes(contentType)) {
      alert('Please upload .png or .jpg files only.');
      return;
    }

    mainDispatch({ type: 'setLoading', payload: true })

    let fileName = app._id;

    if(contentType == 'image/jpeg') {
      fileName = fileName + ".jpg";
    }

    if(contentType == 'image/png') {
      fileName = fileName + ".png";
    }

    var storageRef = firebaseApp.storage().ref();
    var imageRef = storageRef.child(`${mainState.domain}/${fileName}`);
    let metadata = {
      contentType: contentType
    }

    const reader = new FileReader();

    reader.onload = function(img) {

      if(img.currentTarget.readyState == 2) {

        //  SAVE IMAGE TO FIRE STORAGE
        imageRef.putString(img.currentTarget.result,"data_url")
          .then(snapshot => {

            //  GET DOWNLOAD URL
            snapshot.ref.getDownloadURL()
            .then(downloadURL => {

              //  SAVE DOWNLOAD URL TO APP DATA
              firebaseApp.firestore().collection('domains').doc(mainState.domain)
                .collection('apps').doc(app._id)
                .update({
                  imgUrl: downloadURL,
                  isDomainImage: true,
                })
                .then(() =>{
                  mainDispatch({ type: 'setLoading', payload: false })
                  RecordActions(mainState.domain,mainState.authUser.email,`Updated App Image: ${ app.title }`);
                })
                .catch(error => {
                  console.dir(error);
                  mainDispatch({ type: 'setLoading', payload: false })
                  alert('There was an error.  Please try again later.');
                })

            })
            .catch(err => {
              console.dir(err);
              mainDispatch({ type: 'setLoading', payload: false })
              alert('There was an error.  Please try again later.');
            })
          })
          .catch(err => {
            console.dir(err);
            mainDispatch({ type: 'setLoading', payload: false })
            alert('There was an error.  Please try again later.');
          })

      }
    }

    reader.readAsDataURL(file);
  }

  const getCategory = (item) => {
    if(item.hasOwnProperty('category') && typeof item.category === 'object') {
      return item.category.join(',');
    }
    return "";
  }

  const initDownloadData = async () => {

    mainDispatch({ type: 'setLoading', payload: true });

    let data = apps.map(item => {

      var obj = {
        title: item.title,
        desc: item.desc,
        url: item.url,
        category: getCategory(item),
        isPublic: item.isPublic || false,
        company: item.company,
        agreementStatus: item.agreementStatus || "",
        agreementType: item.agreementType || "",
        approvalDate: item.approvalDate || "",
        expirationDate: item.expirationDate || "",
        gradeLevel: item.gradeLevel || "",
        contentArea: item.contentArea || ""
      }

      return obj;
    })

    await downloadCSV(data);

    mainDispatch({ type: 'setLoading', payload: false });
  }

  const columns = [
    {
      field: 'imgUrl',
      render: rowData => {
        if(rowData.hasOwnProperty('imgUrl') && rowData.imgUrl) {
          return (
            <label htmlFor={rowData._id}>
              <img src={rowData.imgUrl} className={classes.img} />
              <input type='file'
                id={rowData._id}
                onChange={e => {
                  uploadImage(e.target.files[0], rowData);
                }}
                style={{ display: 'none' }}
              />
            </label>
          )
        }
        return (
          <label htmlFor={rowData._id + rowData.title} >
          Uploading an image <br/>
          (.png or .jpg ONLY)<br/>
          (50kb or less)
          <input type='file'
            id={rowData._id + rowData.title}
            onChange={e => {
              uploadImage(e.target.files[0], rowData);
            }}
          />
          </label>
        )
      },
      editable: 'never'
    },
    {
      title: 'Title',
      field: 'title',
      render: rowData =>
        <p className={classes.appTitle}>
          { rowData.hasOwnProperty('title') ? rowData.title.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Desc',
      field: 'desc',
      render: rowData =>
        <p className={classes.desc}>
        { rowData.hasOwnProperty('desc') ? rowData.desc : '' }
      </p>
    },
    {
      title: 'URL',
      field: 'url',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('url') ? rowData.url : ''}
        </p>
    },
    {
      title: 'Organizational Unit Access',
      field: 'groups',
      editable: 'never',
      render: rowData => {
        if(rowData.hasOwnProperty('groups') && rowData.groups.length > 0) {
          return (
            <p className={classes.groups}>
              { rowData.groups.join(",") }
            </p>
          )
        }

        return (
          <p className={classes.groups}>
          </p>
        )

      },
      editComponent: props => (
       <input
         type="text"
         onChange={e => console.log(e.target.value)}
       />
     )
    },
    {
      title: 'Categories (separated by comma)',
      field: 'category',
      editable: 'never',
      render: rowData => {

        if(rowData.hasOwnProperty('category') && typeof rowData.category === 'object') {
          return (
            <p className={classes.category}>
              { rowData.category.join(', ') }
            </p>
          )
        }

        if(rowData.hasOwnProperty('category') && typeof rowData.category === 'string') {
          return (
            <p>
              { rowData.category }
            </p>
          )
        }

        return <p></p>
      }

    },
    {
      title: 'Public',
      field: 'isPublic',
      editable: 'never',
      render: rowData =>
          <Checkbox
            checked={rowData.hasOwnProperty('isPublic') ? rowData.isPublic : false}
            name="public"
            disabled={true}
          />

    },
    {
      title: 'Company',
      field: 'company',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('company') ? rowData.company.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Agreement Status',
      field: 'agreementStatus',
      editable: 'never',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('agreementStatus') ? rowData.agreementStatus.toUpperCase() : '' }
        </p>,
      lookup: {
        0: "Approved",
        1: "Not Approved",
        2: "Approved with Guardian Release"
      }
    },
    {
      title: 'Agreement Type',
      field: 'agreementType',
      editable: 'never',
      render: rowData =>
        <p className={classes.url}>
          { (rowData.hasOwnProperty('agreementType') && typeof rowData.agreementType == 'string') 
              ? rowData.agreementType.toUpperCase() 
              : '' 
          }
        </p>
    },
    {
      title: 'Approval Date',
      field: 'approvalDate',
      editable: 'never',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('approvalDate') ? rowData.approvalDate : '' }
        </p>
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      editable: 'never',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('expirationDate') ?rowData.expirationDate : '' }
        </p>
    },
    {
      title: 'Data',
      field: 'data',
      editable: 'never',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('data') ?rowData.data : '' }
        </p>
    },
    {
      title: 'Grade Level',
      field: 'gradeLevel',
      editable: 'never',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('gradeLevel') ? rowData.gradeLevel : '' }
        </p>
    },
    {
      title: 'Content Area',
      field: 'contentArea',
      editable: 'never',
      render: rowData =>
        <p className={classes.desc}>
          { rowData.hasOwnProperty('contentArea') ? rowData.contentArea : '' }
        </p>
    },
    {
      title: 'Notes',
      field: 'notes',
      editable: 'never',
      render: rowData =>
        <p className={classes.notes}>
          { rowData.hasOwnProperty('notes') ? rowData.notes : '' }
        </p>
    }
  ];

  return (
    <div className="apps-table">
      <MaterialTable
        columns={columns}
        data={apps}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 20,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          }
        }}
        actions={[
          {
            icon: () =>
            <DeleteIcon
              color='secondary'
            />,
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              let c = window.confirm('Are you sure?');
              if(c){
                deleteApp(rowData);
              }
            }
          }
        ]}
        components={{
          Container: Box,
          EditField: props => (
            <MTableEditField {...props} className={classes.editField}
            />
          ),
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.toolbar}>
                <Typography
                  component="h1"
                  className={classes.learnMore}
                >
                  Learn about managing apps <a href="https://docs.google.com/document/d/e/2PACX-1vS0YQFDVlGQnCoy8_mBHlCVZ5qI24qLyQV7s33JnbPVx8Pg3XppKs20RwgqWUDu9wqsLe6RN2TVSnMS/pub?embedded=true" target="_blank">here</a>.
                </Typography>

                <Button
                  // variant="contained"
                  color="primary"
                  className={classes.toolbarBtn}
                  startIcon={<GetAppIcon />}
                  onClick={initDownloadData}
                >
                  Download Data
                </Button>
              </div>
            </div>
          ),
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
            new Promise((resolve, reject) => {
              //  ACCOMMODATES DROPDOWN OPTION
              if(columnDef.field == 'agreementStatus') {
                newValue = columnDef.lookup[newValue]
              }
              updateApp(newValue,columnDef.field,rowData._id, rowData.title);
              resolve();
            })
        }}
        detailPanel={rowData => {
          return (
            <div className={classes.detailPanel}>

              <div className={classes.panel}>
                <Paper className={classes.leftPanel}>
                  <Typography variant="subtitle1" component='h1'>
                    <b>TITLE:</b>  {
                      (rowData.hasOwnProperty('title') && rowData.title) && (
                         rowData.title
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>DESC:</b> {
                      (rowData.hasOwnProperty('desc') && rowData.desc) && (
                         rowData.desc
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>URL:</b>  {
                      (rowData.hasOwnProperty('url') && rowData.url) && (
                         rowData.url
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>GROUPS:</b>  {
                       (rowData.hasOwnProperty('groups') && rowData.groups.length > 0) && (
                           rowData.groups.join(",")
                       )
                     }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>CATEGORY:</b>  {
                      (rowData.hasOwnProperty('category') && rowData.category) && (
                         rowData.category
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>COMPANY:</b>  {
                      (rowData.hasOwnProperty('company') && rowData.company) && (
                         rowData.company
                      )
                    }
                  </Typography>
                </Paper>

                <Paper className={classes.rightPanel}>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>STATUS:</b>  {
                      (rowData.hasOwnProperty('agreementStatus') && rowData.agreementStatus) && (
                         rowData.agreementStatus
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>TYPE:</b>  {
                      (rowData.hasOwnProperty('agreementType') && rowData.agreementType) && (
                         rowData.agreementType
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>APPROVAL DATE:</b>  {
                      (rowData.hasOwnProperty('approvalDate') && rowData.approvalDate) && (
                         rowData.approvalDate
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>EXPIRATION DATE:</b>  {
                      (rowData.hasOwnProperty('expirationDate') && rowData.expirationDate) && (
                         rowData.expirationDate
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>DATA:</b>  {
                      (rowData.hasOwnProperty('data') && rowData.data) && (
                         rowData.data
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>GRADE LEVEL:</b>  {
                      (rowData.hasOwnProperty('gradeLevel') && rowData.gradeLevel) && (
                         rowData.gradeLevel
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>CONTENT AREA:</b>  {
                      (rowData.hasOwnProperty('contentArea') && rowData.contentArea) && (
                         rowData.contentArea
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>NOTES:</b> {
                      (rowData.hasOwnProperty('notes') && rowData.notes) && (
                         rowData.notes
                      )
                    }
                  </Typography>
                </Paper>
              </div>

              <Paper className={classes.bottomPanel}>
                <HeatMapComponent 
                  id={rowData.url.split('/')[2]} 
                  dispatch={mainDispatch}
                  email={mainState.authUser.email}
                  domain={mainState.domain}
                />
              </Paper>

            </div>
          )
        }}
      />
    </div>
  )
}

export default AppsTableComponent;
