import React, { useEffect, useState, useContext } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  TextField,
  FormGroup,
  Paper,
  Button,
  Tooltip,
  Dialog,
  Slide,
  AppBar,
  IconButton,
  Toolbar,
  DialogActions,
  DialogTitle,
  DialogContent,
  Modal,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import MaterialTable, { 
  MTableEditField, 
  MTableEditRow, 
  MTableToolbar, 
  MTableHeader 
} from "material-table";
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { firebaseApp } from '../../../firebase';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import HeatMapComponent from './heatmap.component';
import moment from 'moment';
import Papa from 'papaparse';
import { downloadCSV } from '../../utilities/csv_downloader';
import AgreementsComponent from './agreements.component';

import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '50px'
  },
  title: {
    maxWidth: 150,
    maxHeight: 150,
    overflow: 'scroll'
  },
  appTitle: {
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word'
  },
  desc: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  url: {
    maxWidth: 150,
    overflow: 'scroll'
  },
  category: {
    maxWidth: 200,
    overflow: 'scroll'
  },
  notes: {
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 100,
    overflow: 'scroll'
  },
  groups: {
    maxWidth: 200,
    maxHeight: 200,
    minWidth: 100,
    minHeight: 50,
    overflowWrap: 'wrap'
  },
  editField: {
    width: 200,
    height: 100
  },
  detailPanel: {
    backgroundColor: fade(theme.palette.primary.main,0.20),
    padding: theme.spacing(2)
  },
  panel: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    maxWidth: '90vw'
  },
  leftPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  bottomPanel: {
    display: 'flex',
    maxWidth: '90vw',
    margin: theme.spacing(2)
  },
  stats: {
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '90vw'
  },
  img: {
    maxWidth: 100,
    maxHeight: 100,
    cursor: 'pointer'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  learnMore: {
    margin: theme.spacing(1)
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formItem: {
    margin: theme.spacing(1)
  },
  sdpcCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1)
  },

  //  DIALOG
  appBar: {
    position: 'relative'
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  appEditModal: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
    overflow: 'scroll'
  },
  formContainer: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(5),
    padding: theme.spacing(5)
  },
  form1: {
    border: '1px solid lightgrey',
    borderRadius: 5,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    width: 700,
    display: 'flex',
    flexWrap: 'wrap'
  },
  form1Item: {
    width: 300,
    margin: theme.spacing(1)
  },
  form2: {
    width: 400,
    border: '1px solid lightgrey',
    borderRadius: 5,
    margin: theme.spacing(3),
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  cancelIcon: {
    cursor: 'pointer',
    margin: theme.spacing(5),
    color: 'red',
    height: 70,
    width: 70
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AgreementTypeForm = React.memo( props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ app, setApp ] = useState(null);

  const appId = props.rowData._id;

  useEffect(() => {

    if(!mainState || !mainState.domain) {
      return;
    }
  
    const appListener = firebaseApp.firestore()
      .collection('domains').doc(mainState.domain)
      .collection('apps').doc(appId)
      .onSnapshot(snapshot => {
        let tempData = snapshot.data();
        tempData.id = snapshot.id;
        setApp(tempData);
      }, err => {
        console.dir(err);
      })

      return () => appListener();

  },[])

  const handleSubmit = async (e) => {

    e.preventDefault();
    e.persist();

    let title = e.target[0].value;
    let url = e.target[2].value;

    let tempAgreements = app.agreementType;
    tempAgreements = [...tempAgreements,{ title:title, url:url }];

    await firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').doc(appId).update({
        agreementType: tempAgreements
      })

    e.target.reset();
  }

  const deleteAgreement = async (index) => {
    let tempAgreements = app.agreementType;

    tempAgreements.splice(index,1);

    await firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').doc(appId).update({
        agreementType: tempAgreements
      })

    // props.onChange(tempAgreements);
  }

  if(!app) {
    return (
      <Typography align='center'>Loading...</Typography>
    )
  }

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField 
          className={classes.formItem} 
          type='text' 
          label='Enter Agreement Title' 
          variant='outlined'
          required
        />
        <TextField 
          className={classes.formItem} 
          type='url' 
          label="Enter/Paste URL"
          variant='outlined'
        />
        <Button 
          className={classes.formItem} 
          type='submit'
          color='primary'
          variant='contained'
        >Add Agreement</Button>
      </form>

      <div>
        {
          (Array.isArray(app.agreementType) && app.agreementType.length > 0) ?

            app.agreementType.map((agreement,index) => {
              return (
                <p key={index}>
                  <span 
                    onClick={() => deleteAgreement(index)}
                    style={{color: 'red', margin: '5px', cursor: 'pointer'}}
                  >X</span>
                <a href={agreement.url} target="_blank">{agreement.title}</a></p>
              )
            })

          : null
        }
      </div>
    </div>
  )
});

const RenderEditForm = props => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ newData, setNewData ] = useState(Object.assign({},props.appToEdit));
  const [ oldData, setOldData ] = useState(Object.assign({},props.appToEdit));
  const [ agreementStatus, setAgreementStatus ] = useState(props.appToEdit.agreementStatus);
  const [ groups, setGroups ] = useState(props.appToEdit.groups);
  const [ orgUnits, setOUs ] = useState(["all"]);

  useEffect(() => {
    if(mainState.domainData.hasOwnProperty('orgUnits') && mainState.domainData.orgUnits.length > 0) {
      setOUs([...orgUnits,...mainState.domainData.orgUnits]);
    }
  },[])

  const submitAppEdit = async (e) => {
    e.preventDefault();
    updateAppInfo(newData,oldData);
  }

  const updateAppkey = _.debounce((key,val) => {

   let tempData = newData;

   if(key == 'agreementStatus') {
    setAgreementStatus(val);
   }

   if(key == 'groups') {
    let tempGroups;
      if(groups.includes(val)) {
      tempGroups = groups.filter(g => (g !== val && orgUnits.includes(g)));
    } else {
      tempGroups = [...groups,val];
      tempGroups = tempGroups.filter(g => orgUnits.includes(g));
    }
    
    setGroups(tempGroups);

    return;
   }

   tempData[key] = val;

   setNewData(tempData);

  },500)

  const updateAppInfo = async (newInfo, oldInfo) => {

    let appId = newInfo._id;

    newInfo.groups = groups;

    await firebaseApp.firestore()
        .collection('domains').doc(mainState.domain)
        .collection('apps').doc(appId)
        .update(newInfo);

    props.closeModal();

    return;

    let tempObj = {};

    for(const key in newInfo) {
        switch(key) {
            case '_id':
            case 'timestamp':
            case 'clicks':
            case 'agreementType':
              break;
            case 'groups':
                //  TURN OLD GROUPS INTO STRING TO CHECK
                if(typeof newInfo.groups == 'string') {
                    console.log('New Group')
                    tempObj.groups = newInfo.groups.split(',').map(item => item.trim());
                    if(tempObj.groups.length <= 0) {
                      tempObj.groups.push('all');
                    }
                }
                break;
            case 'category':
                //  TURN OLD GROUPS INTO STRING TO CHECK
                let tempOldCategory = oldInfo.category.join(',');
                if(typeof newInfo.category == 'string') {
                    if(newInfo.category !== tempOldCategory) {
                        console.log('New categories')
                        tempObj.category = newInfo.category.split(',').map(item => item.trim().toLowerCase());
                    }
                }
                break;
            default:
                if(newInfo[key] !== oldInfo[key]){
                    tempObj[key] = newInfo[key];
                }
        }
    } // FOR LOOP

    //  UPDATE ONLY IF THERE IS ANYTHING TO UPDATE
    if(Object.keys(tempObj).length > 0) {
      await firebaseApp.firestore()
        .collection('domains').doc(mainState.domain)
        .collection('apps').doc(appId)
        .update(tempObj);
    }

    props.closeModal();

  }

  return (
    <div className={classes.formContainer}>

      <CancelIcon 
        onClick={props.closeModal}
        className={classes.cancelIcon} 
      />

      <div>
        <Typography align='center' variant='h6'>Update one or more items</Typography>
        <form 
          className={classes.form1}
          onSubmit={submitAppEdit}
        >

          <TextField
            label="App Title"
            placeholder={oldData.title}
            onChange={e => updateAppkey('title',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Description"
            placeholder={oldData.desc}
            variant='outlined'
            multiline
            rows={3}
            onChange={e => updateAppkey('desc',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="URL"
            placeholder={oldData.url}
            onChange={e => updateAppkey('url',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <FormLabel component="legend">Select Groups</FormLabel>
          <FormGroup>
            {
              orgUnits.length > 0
                
                ?  orgUnits.map(ou => 

                    <FormControlLabel
                      key={ou} 
                      control={
                        <Checkbox  
                          checked={groups.includes(ou)}
                          onChange={(e) => {
                            updateAppkey('groups',ou)
                          }}
                        />
                      } 
                      label={ou} 
                    />

                  )

                : null
            }
          </FormGroup>

          <TextField
            label="Categories"
            helperText="Separated by comma."
            placeholder={oldData.category.join(',')}
            onChange={e => updateAppkey('category',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Company"
            placeholder={oldData.company}
            onChange={e => updateAppkey('company',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <FormControl className={classes.formControl}>
            <InputLabel>Agreement Status</InputLabel>
            <Select
              className={classes.form1Item}
              value={agreementStatus}
              onChange={e => updateAppkey('agreementStatus',e.target.value)}
            >
              <MenuItem value='Approved'>Approved</MenuItem>
              <MenuItem value='Not Approved'>Not Approved</MenuItem>
              <MenuItem value='Approve with Guardian Release'>Approve with Guardian Release</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Approve Date"
            placeholder={oldData.approvalDate}
            onChange={e => updateAppkey('approvalDate',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Expiration Date"
            placeholder={oldData.expirationDate}
            onChange={e => updateAppkey('expirationDate',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Data"
            helperText="Separated by comma."
            placeholder={oldData.expirationDate}
            onChange={e => updateAppkey('data',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Grade Level"
            placeholder={oldData.gradeLevel}
            onChange={e => updateAppkey('gradeLevel',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          /> 

          <TextField
            label="Content Area"
            placeholder={oldData.contentArea}
            variant='outlined'
            multiline
            rows={4}
            onChange={e => updateAppkey('contentArea',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <TextField
            label="Notes"
            placeholder={oldData.notes}
            variant='outlined'
            multiline
            rows={4}
            onChange={e => updateAppkey('notes',e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.form1Item}
          />

          <div className={classes.form1Item}></div>  
          
          <Button
            type='submit'
            variant='contained'
            color='primary'
            style={{ maxHeight: 50, flexGrow: 1 }}
          >
            Submit Changes
          </Button>
        </form>
      </div>
      
      <div className={classes.form2}>
        <Typography 
          align='center' 
          variant="h6" 
          component="h1"
        >Add | Remove Agreements</Typography>
        <Typography>
        <b>NOTE:</b>  We highly recommend that you use the <a href="https://sdpc.a4l.org/" target="_blank">Student Data Privacy Consortium (SDPC)</a> when managing agreements (if applicable) since you can easily integrate/import the agreements in our system when the agreements become available.  This keeps the management of your assets in one place while ensuring up-to-date agreements via the consortium.
        </Typography>
        <AgreementTypeForm rowData={props.appToEdit} />
      </div>

    </div>
  )
}

const AppsTableComponent = () => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { apps } = mainState;
  const [selectedRow, setSelectedRow] = useState(null);
  const [ openSDPC, setOpenSDPC ] = useState(false);
  const [ openEditModal, setOpenEditModal ] = useState(false);
  const [ appToEdit, setAppToEdit ] = useState(null);

  //  LISTEN AND ADJUST TO APPS CHANGES
  useEffect(() => {},[ apps ])

  const deleteApp = rowData => {

    let urlRef = rowData.imgUrl;
    let isDomainImage = false;

    //  IF IMAGE WAS UPLOADED BY DOMAIN ON THE APP
    if(rowData.hasOwnProperty('isDomainImage') && rowData.isDomainImage) {
      isDomainImage = true;
    }

    mainDispatch({type: 'setLoading', payload: true });
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').doc(rowData._id)
      .delete()
      .then(() => {

        RecordActions(mainState.domain,mainState.authUser.email,`Deleted App: ${ rowData.title }`);

        // DELETE IMAGE FROM FIREBASE
        if(isDomainImage) {

          return firebaseApp.storage().refFromURL(urlRef).delete()
            .then(() => {
              console.log('Image was successfully deleted...');
              mainDispatch({type: 'setLoading', payload: false });
            })
            .catch(err => {
              console.dir(err);
              return mainDispatch({type: 'setLoading', payload: false });
            })

        }

        mainDispatch({type: 'setLoading', payload: false });
      })
      .catch(err => {
        console.log('Error deleting app.');
        mainDispatch({type: 'setLoading', payload: false });
      })
  }

  const updateAppInfo = async (newData, oldData) => {

    let appId = newData._id;

    let tempObj = {};

    for(const key in newData) {
        switch(key) {
            case '_id':
            case 'timestamp':
            case 'clicks':
              break;
            case 'agreementType':
                if(newData.agreementType.length !== oldData.agreementType.length) {
                  tempObj.agreementType = newData.agreementType;
                }
                break;
            case 'groups':
                //  TURN OLD GROUPS INTO STRING TO CHECK
                let tempOldGroups = oldData.groups.join(',');
                if(typeof newData.groups == 'string') {
                    if(newData.groups !== tempOldGroups) {
                        console.log('New Group')
                        tempObj.groups = newData.groups.split(',').map(item => item.trim().toLowerCase());
                        if(tempObj.groups.length <= 0) {
                          tempObj.groups.push('all');
                        }
                    }
                }
                break;
            case 'category':
                //  TURN OLD GROUPS INTO STRING TO CHECK
                let tempOldCategory = oldData.category.join(',');
                if(typeof newData.category == 'string') {
                    if(newData.category !== tempOldCategory) {
                        console.log('New categories')
                        tempObj.category = newData.category.split(',').map(item => item.trim().toLowerCase());
                    }
                }
                break;
            default:
                if(newData[key] !== oldData[key]){
                    tempObj[key] = newData[key];
                }
        }
    }

    //  UPDATE ONLY IF THERE IS ANYTHING TO UPDATE
    if(Object.keys(tempObj).length > 0) {
      await firebaseApp.firestore()
        .collection('domains').doc(mainState.domain)
        .collection('apps').doc(appId)
        .update(tempObj);
    }
  }

  const uploadImage = (file,app) => {

    if(file.size > 50000) {
      alert("Please upload files that are 50kb or less in size.");
      return;
    }

    let acceptedFileTypes = ["image/png","image/jpeg"];
    let contentType = file.type;

    if(!acceptedFileTypes.includes(contentType)) {
      alert('Please upload .png or .jpg files only.');
      return;
    }

    mainDispatch({ type: 'setLoading', payload: true })

    let fileName = app._id;

    if(contentType == 'image/jpeg') {
      fileName = fileName + ".jpg";
    }

    if(contentType == 'image/png') {
      fileName = fileName + ".png";
    }

    var storageRef = firebaseApp.storage().ref();
    var imageRef = storageRef.child(`${mainState.domain}/${fileName}`);
    let metadata = {
      contentType: contentType
    }

    const reader = new FileReader();

    reader.onload = function(img) {

      if(img.currentTarget.readyState == 2) {

        //  SAVE IMAGE TO FIRE STORAGE
        imageRef.putString(img.currentTarget.result,"data_url")
          .then(snapshot => {

            //  GET DOWNLOAD URL
            snapshot.ref.getDownloadURL()
            .then(downloadURL => {

              //  SAVE DOWNLOAD URL TO APP DATA
              firebaseApp.firestore().collection('domains').doc(mainState.domain)
                .collection('apps').doc(app._id)
                .update({
                  imgUrl: downloadURL,
                  isDomainImage: true,
                })
                .then(() =>{
                  mainDispatch({ type: 'setLoading', payload: false })
                  RecordActions(mainState.domain,mainState.authUser.email,`Updated App Image: ${ app.title }`);
                })
                .catch(error => {
                  console.dir(error);
                  mainDispatch({ type: 'setLoading', payload: false })
                  alert('There was an error.  Please try again later.');
                })

            })
            .catch(err => {
              console.dir(err);
              mainDispatch({ type: 'setLoading', payload: false })
              alert('There was an error.  Please try again later.');
            })
          })
          .catch(err => {
            console.dir(err);
            mainDispatch({ type: 'setLoading', payload: false })
            alert('There was an error.  Please try again later.');
          })

      }
    }

    reader.readAsDataURL(file);
  }

  const getCategory = (item) => {
    if(item.hasOwnProperty('category') && typeof item.category === 'object') {
      return item.category.join(',');
    }
    return "";
  }

  const RenderAgreementType = ({ agreementType }) => {

    let regex = new RegExp('http','i');

    if(Array.isArray(agreementType)) {
      return (
        <div className={classes.url}>
          {
            agreementType.map((agreement,index) => 
              <React.Fragment key={index}>
              {
                agreement.url ?
                  <a href={agreement.url} target="_blank">{ agreement.title ? agreement.title : "Agreement" }</a>
                : <p>{agreement.title}</p>
              }
              <br/>
              <br />
              </React.Fragment>
            )
          }
        </div>
      )
    }

    if(agreementType.search(regex) > -1){
      return (
        <div className={classes.url}>
          <a href={agreementType} target="_blank">
            <p>Agreement</p>
          </a>
        </div>
      )
    }
    return (
      <p className={classes.url}>{ agreementType }</p>
    )
  }

  const handleClose = () => {
    setOpenSDPC(false);
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  }

  const columns = [
    {
      render: rowData => {
        return (
          <EditIcon 
            onClick={() => {
              setAppToEdit(rowData);
              setOpenEditModal(true);
            }}
          />
        )
      },
      sorting: false
    },
    {
      field: 'imgUrl',
      export: false,
      render: rowData => {
        if(rowData.hasOwnProperty('imgUrl') && rowData.imgUrl) {
          return (
            <label htmlFor={rowData._id}>
              <img src={rowData.imgUrl} className={classes.img} />
              <input type='file'
                id={rowData._id}
                onChange={e => {
                  uploadImage(e.target.files[0], rowData);
                }}
                style={{ display: 'none' }}
              />
            </label>
          )
        }
        return (
          <label htmlFor={rowData._id + rowData.title} >
          Uploading an image <br/>
          (.png or .jpg ONLY)<br/>
          (50kb or less)
          <input type='file'
            id={rowData._id + rowData.title}
            onChange={e => {
              uploadImage(e.target.files[0], rowData);
            }}
          />
          </label>
        )
      },
      editable: 'never',
      sorting: false
    },
    {
      title: 'Title',
      field: 'title',
      render: rowData =>
        <p className={classes.appTitle}>
          { rowData.hasOwnProperty('title') ? rowData.title.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Desc',
      field: 'desc',
      render: rowData =>
        <p className={classes.desc}>
        { rowData.hasOwnProperty('desc') ? rowData.desc : '' }
      </p>,
      sorting: false
    },
    {
      title: 'URL',
      field: 'url',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('url') ? rowData.url : ''}
        </p>,
      sorting: false
    },
    {
      title: <HtmlTooltip
              placement="right"
              title={
                <React.Fragment>
                  <Typography>
                  Select Organizational Unit(s) that you want the app to show up for in their Chrome extension.
                  </Typography>
                </React.Fragment>
              }
            >
              <Typography>
                Organizational Unit Access
                <InfoIcon />
              </Typography>
            </HtmlTooltip>,
      field: 'groups',
      editable: 'always',
      render: rowData => {
        if(rowData.hasOwnProperty('groups') && rowData.groups.length > 0) {
          return (
            <p className={classes.groups}>
              { rowData.groups.join("\n") }
            </p>
          )
        }

        return (
          <p className={classes.groups}>
          </p>
        )

      },
      sorting: false
    },
    {
      title: 'Categories (separated by comma)',
      field: 'category',
      render: rowData => {

        if(rowData.hasOwnProperty('category') && typeof rowData.category === 'object') {
          return (
            <p className={classes.category}>
              { rowData.category.join(', ') }
            </p>
          )
        }

        if(rowData.hasOwnProperty('category') && typeof rowData.category === 'string') {
          return (
            <p>
              { rowData.category }
            </p>
          )
        }

        return <p></p>
      }

    },
    {
      title: 'Company',
      field: 'company',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('company') ? rowData.company.toUpperCase() : '' }
        </p>
    },
    {
      title: 'Agreement Status',
      field: 'agreementStatus',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('agreementStatus') ? rowData.agreementStatus.toUpperCase() : '' }
        </p>,
      lookup: {
        Approved: "Approved",
        "Not Approved": "Not Approved",
        "Approve with Guardian Release": "Approved with Guardian Release"
      }
    },
    {
      title: 'Agreement Type',
      field: 'agreementType',
      render: rowData =>
        <React.Fragment>
          { 
            rowData.hasOwnProperty('agreementType') ? 
                <RenderAgreementType agreementType={rowData.agreementType} />
              : '' 
          }
        </React.Fragment>,
      editComponent: props => ( <AgreementTypeForm {...props} />),
      sorting: false
    },
    {
      title: 'District Agreement Finalized',
      field: 'isPublic',
      sorting: false,
      editable: 'never',
      render: rowData => (
          <HtmlTooltip
            placement="right"
            title={
              <React.Fragment>
                <Typography>
                Checking this box indicates that you have compiled all of the necessary information to meet compliance for disclosure.  When checked, app information will become publicly available.  Below are suggested items to double-check:
                </Typography>
                <ul>
                  <li>All agreements are accurate.</li>
                  <li>Agreement dates match agreement documents.</li>
                </ul>
              </React.Fragment>
            }
          >
            <Checkbox
              checked={rowData.hasOwnProperty('isPublic') ? rowData.isPublic : false}
              onChange={() => {

                mainDispatch({type: 'setLoading', payload: true });

                firebaseApp.firestore().collection('domains').doc(mainState.domain)
                  .collection('apps').doc(rowData._id)
                  .update({
                    isPublic: !rowData.isPublic
                  })
                  .then(() => {
                    console.log('Updated app.');
                    mainDispatch({type: 'setLoading', payload: false });
                  })
                  .catch(err => {
                    console.log('Error updating app.');
                    mainDispatch({type: 'setLoading', payload: false });
                  })

              }}
              name="public"
            />
          </HtmlTooltip>
        )
    },
    {
      title: 'Approval Date',
      field: 'approvalDate',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('approvalDate') ? rowData.approvalDate : '' }
        </p>,
      editComponent: props => (
        <input 
          type='date' 
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        />
        )
    },
    {
      title: 'Expiration Date',
      field: 'expirationDate',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('expirationDate') ?rowData.expirationDate : '' }
        </p>,
      editComponent: props => (
        <input 
          type='date' 
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        />
        )
    },
    {
      title: 'Data',
      field: 'data',
      render: rowData =>
        <p className={classes.url}>
          { rowData.hasOwnProperty('data') ?rowData.data : '' }
        </p>
    },
    {
      title: 'Grade Level',
      field: 'gradeLevel',
      render: rowData =>
        <p className={classes.title}>
          { rowData.hasOwnProperty('gradeLevel') ? rowData.gradeLevel : '' }
        </p>,
      sorting: false
    },
    {
      title: 'Content Area',
      field: 'contentArea',
      render: rowData =>
        <p className={classes.desc}>
          { rowData.hasOwnProperty('contentArea') ? rowData.contentArea : '' }
        </p>,
      sorting: false
    },
    {
      title: 'Notes',
      field: 'notes',
      render: rowData =>
        <p className={classes.notes}>
          { rowData.hasOwnProperty('notes') ? rowData.notes : '' }
        </p>,
      sorting: false
    }
  ];

  return (
    <div className={`apps-table ${classes.root}`}>
      <MaterialTable
        title=""
        columns={columns}
        data={apps}
        style={{
          width: window.innerWidth * 0.9,
          border: '1px solid lightgrey',
          boderRadius: '5px'
        }}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 20,
          headerStyle:{
            backgroundColor: '#66549a',
            color: '#ffffff'
          },
          exportButton: true,
          exportAllData: true
        }}
        actions={[
          {
            icon: () =>
            <DeleteIcon
              color='secondary'
            />,
            tooltip: 'Delete',
            onClick: (event, rowData) => {
              let c = window.confirm('Are you sure?');
              if(c){
                deleteApp(rowData);
              }
            }
          }
        ]}

        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />

              <div className={classes.sdpcCont}>
                <Button
                  className='sdpc-btn'
                  variant='contained'
                  color='secondary'
                  onClick={() => setOpenSDPC(true)}
                >
                  Student Data Privacy Consortium
                </Button>
              </div>
            </div>
          )
        }}
        
        detailPanel={rowData => {
          return (
            <div className={classes.detailPanel}>

              <div className={classes.panel}>
                <Paper className={classes.leftPanel}>
                  <Typography variant="subtitle1" component='h1'>
                    <b>TITLE:</b>  {
                      (rowData.hasOwnProperty('title') && rowData.title) && (
                         rowData.title
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>DESC:</b> {
                      (rowData.hasOwnProperty('desc') && rowData.desc) && (
                         rowData.desc
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>URL:</b>  {
                      (rowData.hasOwnProperty('url') && rowData.url) && (
                         rowData.url
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>GROUPS:</b>  {
                       (rowData.hasOwnProperty('groups') && rowData.groups.length > 0) && (
                           rowData.groups.join(",")
                       )
                     }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>CATEGORY:</b>  {
                      (rowData.hasOwnProperty('category') && rowData.category) && (
                         rowData.category
                      )
                    }
                  </Typography>
                  <Typography  variant="subtitle1" component='h1'>
                    <b>COMPANY:</b>  {
                      (rowData.hasOwnProperty('company') && rowData.company) && (
                         rowData.company
                      )
                    }
                  </Typography>
                </Paper>

                <Paper className={classes.rightPanel}>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>STATUS:</b>  {
                      (rowData.hasOwnProperty('agreementStatus') && rowData.agreementStatus) && (
                         rowData.agreementStatus
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>TYPE:</b>  {
                      (rowData.hasOwnProperty('agreementType') && rowData.agreementType) && (
                         <RenderAgreementType agreementType={rowData.agreementType} />
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>APPROVAL DATE:</b>  {
                      (rowData.hasOwnProperty('approvalDate') && rowData.approvalDate) && (
                         rowData.approvalDate
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>EXPIRATION DATE:</b>  {
                      (rowData.hasOwnProperty('expirationDate') && rowData.expirationDate) && (
                         rowData.expirationDate
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>DATA:</b>  {
                      (rowData.hasOwnProperty('data') && rowData.data) && (
                         rowData.data
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>GRADE LEVEL:</b>  {
                      (rowData.hasOwnProperty('gradeLevel') && rowData.gradeLevel) && (
                         rowData.gradeLevel
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>CONTENT AREA:</b>  {
                      (rowData.hasOwnProperty('contentArea') && rowData.contentArea) && (
                         rowData.contentArea
                      )
                    }
                  </Typography>

                  <Typography  variant="subtitle1" component='h1'>
                    <b>NOTES:</b> {
                      (rowData.hasOwnProperty('notes') && rowData.notes) && (
                         rowData.notes
                      )
                    }
                  </Typography>
                </Paper>
              </div>

              <Paper className={classes.bottomPanel}>
                <HeatMapComponent 
                  id={rowData.url.split('/')[2]} 
                  dispatch={mainDispatch}
                  email={mainState.authUser.email}
                  domain={mainState.domain}
                />
              </Paper>

            </div>
          )
        }}
      />

      <Dialog fullScreen open={openSDPC} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography 
              variant="h6" 
              className={classes.dialogTitle}
              style={{ cursor: 'pointer' }}
            >
              Student Data Privacy Consortium Data
            </Typography>
          </Toolbar>
        </AppBar>

        <AgreementsComponent { ...mainState } />

      </Dialog>

      <Modal open={openEditModal} onClose={handleCloseEditModal}>
        <div className={classes.appEditModal}>
          <RenderEditForm 
            appToEdit={appToEdit}
            closeModal={handleCloseEditModal}
          />
        </div>
      </Modal>

    </div>
  )
}

export default AppsTableComponent;
