import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  headerTitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    fontWeight: 'bolder'
  },
  btnGroup: {
    flexGrow: 1,
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(10)
  },
  menuButton: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: theme.spacing(2)
  }
}))


const CustomHeader = ({title}) => {

  const classes = useStyles();

  const history = useHistory();


  return (
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerTitles}>
            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            { title }
            </Typography>

          </div>

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
