import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Button,
  Tooltip,
  Snackbar,
  IconButton
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import moment from 'moment';

import { firebaseApp } from '../../../firebase';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import BackDrop from '../../backdrop.component';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable, { MTableToolbar } from "material-table";
import { downloadCSV } from '../../utilities/csv_downloader';

/*  KEY

  none = 0
  low = 10
  medium = 20
  high = 30
  critical = 50  

 */

const allPermissions = {
  activeTab: 'low',
  alarms: 'none',
  background: 'low',
  bookmarks: 'medium',
  browsingData: 'none',
  certificateProvider: 'low',
  clipboardRead: 'high',
  clipboardWrite: 'medium',
  contentSettings: 'high',
  contextMenus: 'none',
  cookies: 'critical',
  debugger: 'critical',
  declarativeContent: 'none',
  declarativeNetRequest: 'high',
  declarativeWebRequest: 'critical',
  desktopCapture: 'high',
  displaySource: 'high',
  dns: 'high',
  documentScan: 'low',
  downloads: 'medium',
  'enterprise.deviceAttributes': 'none',
  'enterprise.platformKeys': 'low',
  experimental: 'high',
  fileBrowserHandler: 'none',
  fileSystemProvider: 'medium',
  fontSettings: 'none',
  gcm: 'none',
  geolocation: 'medium',
  hid: 'low',
  history: 'high',
  identity: 'low',
  idle: 'none',
  idltest: 'high',
  management: 'medium',
  mdns: 'high',
  nativeMessaging: 'medium',
  config: 'low',
  notifications: 'low',
  pageCapture: 'high',
  platformKeys: 'low',
  power: 'none',
  printerProvider: 'low',
  privacy: 'high',
  processes: 'medium',
  proxy: 'high',
  sessions: 'none',
  signedInDevices: 'medium',
  storage: 'medium',
  'system.cpu': 'none',
  'system.display': 'none',
  'system.memory': 'none',
  'system.storage': 'medium',
  tabCapture: 'high',
  tabs: 'high',
  topSites: 'medium',
  tts: 'none',
  ttsEngine: 'medium',
  unlimitedStorage: 'none',
  usbDevices: 'low',
  vpnProvider: 'high',
  wallpaper: 'none',
  webNavigation: 'medium',
  webRequest: 'critical',
  webRequestBlocking: 'low',
  '<all_urls>': 'critical',
  'http://*/*': 'high',
  'https://*/*': 'high',
  '*://*/*': 'critical',
  'file:///*': 'high',
  'http://*/': 'high',
  'https://*/': 'high',
  '*://*/': 'critical',
}


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  noDataText: {
    marginTop: '10vh'
  },
  detailPanel: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row'
  },
  detailLeft: {
    border: '1px solid lightgrey',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    minWidth: 750,
    maxWidth: '90vw',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll'
  },
  detailRight: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    maxHeight: 500,
    overflow: 'scroll'
  },
  numUsers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    minWidth: 50,
    overflow: 'scroll'
  },
  appName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 200,
    maxHeight: 150,
    overflowWrap: 'break-word',
    overflow: 'auto'
  },
  desc: {
    maxWidth: 500,
    maxHeight: 250,
    minWidth: 200,
    overflow: 'scroll'
  },
  user: {
    marginLeft: theme.spacing(1)
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  loadMore: {
    width: 200
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  securityRisk: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: 25,
    border: '1px solid black'
  },
  none: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: 'lightgreen'
  },
  note: {
    padding: theme.spacing(2)
  },
  low: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: 'lightblue'
  },
  medium: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: 'yellow'
  },
  high: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: 'orange'
  },
  critical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    backgroundColor: 'red'
  },
  adminInstalled: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  installType: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 100,
    overflow: 'scroll'
  },
  ids: {
    display: 'flex',
    cursor: 'pointer'
  },
}));

const AppDetailPanel = ({ appInfo }) => {

  const classes = useStyles();

  return(
    <div className={classes.detailPanel}>

      <div className={classes.detailLeft}>
        <Typography><b>Type:</b> { appInfo.isApp ? "Chrome App" : "Extension" }</Typography>
        <Typography align='center'><b>USERS</b></Typography>
        <div className={classes.detailRight}>
        {
          appInfo.users.map(user =>
            <Typography key={user} className={classes.user}><span>&#8226; &nbsp;</span> {user}</Typography>
          )
        }
        </div>
      </div>

    </div>
  )
}

const InstalledChromeApps = props => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ chromeApps, setChromeApps ] = useState([]);
  const [ lastDoc, setLastDoc ] = useState('');
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }
    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('appsExtensions').get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0) {
          setLoading(false);
          alert('No data found.');
          return;
        }

        let tempLastDoc = snapshot.docs[snapshot.docs.length-1];
        let tempChromeApps = snapshot.docs.map(doc => doc.data());

        setChromeApps([...tempChromeApps]);
        // setLastDoc(tempLastDoc);

        setLoading(false);

      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
        alert('There was an error.  Please try again.');
      })

  },[]);

  const getMoreData = () => {

    setLoading(true);

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('appsExtensions').orderBy('total_users','desc')
      .startAt(lastDoc).limit(100).get()
      .then(snapshot => {

        if(snapshot.docs.length <= 0) {
          setLoading(false);
          alert('No more data left.');
          return;
        }

        let tempLastDoc = snapshot.docs[snapshot.docs.length-1];
        let tempChromeApps = snapshot.docs.map(doc => doc.data());

        //  REMOVE FIRST ITEM BECAUSE IT AS IN LAST QUERY
        tempChromeApps.shift();

        if(tempChromeApps.length <= 0) {
          setLoading(false);
          alert('No more data left.');
          return;
        }

        setChromeApps([...chromeApps,...tempChromeApps]);
        setLastDoc(tempLastDoc);

        setLoading(false);

      })
      .catch(err => {
        console.dir(err);
        setLoading(false);
        alert('There was an error.  Please try again.');
      })

  }

  const handleClose = (event,reason) => {
    if(reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const columns = [
    {
      title: "# Users",
      field: 'total_users',
      // defaultSort: 'desc',
      render: rowData => (
        <Typography className={classes.numUsers}>{ rowData.total_users }</Typography>
      ),
      width: 150
    },
    {
      title: "Security Risk",
      field: 'securityRisk',
      defaultSort: 'desc',
      render: rowData => (
        <Typography 
          className={
            `
              ${
                
                  rowData.installType == 'admin' ?
                    classes.adminInstalled
                : rowData.securityRisk >= 50 ?
                    classes.critical
                : rowData.securityRisk < 50 && rowData.securityRisk >= 35 ?
                    classes.high
                : rowData.securityRisk < 35 && rowData.securityRisk >= 15 ?
                    classes.medium
                : rowData.securityRisk < 15 && rowData.securityRisk > 0 ?
                    classes.low
                : rowData.securityRisk <= 0 ?
                    classes.none
                : null

              }
            `
          }
        >
          { 

            rowData.securityRisk >= 50 ?
                "Critical"
              : rowData.securityRisk < 50 && rowData.securityRisk >= 35 ?
                "High"
              : rowData.securityRisk < 35 && rowData.securityRisk >= 15 ?
                "Medium"
              : rowData.securityRisk < 15 && rowData.securityRisk > 0 ?
                "Low"
              : rowData.securityRisk <= 0 ?
                "None"
              : ""

          }
        </Typography>
      ),
      width: 150
    },
    {
      title: 'Admin Install',
      field: 'installType',
      render: rowData => {
        return (
          <Typography className={classes.installType}>

            {
              rowData.installType === 'admin' ?
                "TRUE"
              : "FALSE"
            }

          </Typography>
        )
      },
      width: 150
    },
    {
      title: "App Name",
      field: 'name',
      render: rowData => {

        if(rowData.homepageUrl) {
            return (
              <Typography className={classes.appName}>
              <a href={rowData.homepageUrl} target="_blank">{ rowData.name.toUpperCase() }</a>
              </Typography>
          )
          }

          return (
            <Typography className={classes.appName}>
            { rowData.name.toUpperCase() }
            </Typography>
        )

      },
      width: 250
    },
    {
      title: "Description",
      field: 'description',
      render: rowData => (
        <Typography className={classes.desc}>{ rowData.description }</Typography>
      ),
      sorting: false
    },
    {
        title: 'Permissions',
        field: 'permissions',
        render: rowData => (
        <div>
          {
            rowData.permissions.length > 0 ?

              <Typography>{rowData.permissions.join(', ')}</Typography>

            : <Typography align='center'>No Permissions</Typography>
          }
        </div>
        ),
        sorting: false
      },
      {
        title: 'App ID',
        field: 'id',
        render: rowData => (
        <Tooltip title='Copy'>
          <div className={classes.ids}
          onClick={() => {
            navigator.clipboard.writeText(rowData.id).then(function(){
              setOpen(true);
            }, function() {
              console.log("Couldn't copy")
            })
          }}
        >
            <Typography>{rowData.id}</Typography>
            <FilterNoneIcon style={{marginLeft: '10px'}} />
        </div>
        </Tooltip>
        ),
        sorting: false
      }
  ]

  if(loading) {
    return (
      <div className={classes.root}>

        <Typography align='center'
            component="h1"
            variant="subtitle1"
            className={classes.noDataText}
          >Fetching data...</Typography>

        <BackDrop loading={loading} />
      </div>
    )
  }

  return (
    <div className={classes.root}>

      {
        chromeApps.length <= 0 && (
          <Typography align='center'
            component="h1"
            variant="subtitle1"
            className={classes.noDataText}
          >No apps data collected yet.  Come back later :)</Typography>
        )
      }

      {
        chromeApps.length > 0 && (
          <div className={classes.mainContainer}>
            <MaterialTable
              title="Chrome Apps & Extensions"
              data={chromeApps}
              columns={columns}
              style={{
                minWidth: '90vw',
                margin: '10px',
                marginTop: '30px'
              }}
              options={{
                search: true,
                sorting: true,
                pageSize: 50,
                exportButton: true,
                exportAllData: true
              }}
              detailPanel={ rowData => {
                  return (
                    <AppDetailPanel
                      appInfo={rowData}
                    />
                  )
              }}

              components={{
                Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    
                    <div  className={classes.note}>
                      <ul>
                        <li style={{ listStyleType: 'none' }}>
                          <Typography><b>PLEASE READ</b></Typography>
                        </li>
                        <li>
                          <Typography component='h1'>
                            The listed apps and extensions are those that were <span style={{ textDecoration: 'underline' }}>not installed by an admin</span> and that have occurred within the timeline indicated at the top of this page.
                          </Typography>
                        </li>

                        <li>
                          <Typography component='h1'>
                            New installs are determined when a user logs in to a <span style={{ textDecoration: 'underline' }}>new</span> Chrome browser and/or Chrome device.  As a result, apps and extensions that have been previously installed will re-appear on the list.
                          </Typography>
                        </li>

                        <li>
                          <Typography>
                          <b>**Security Risks</b> are based on access permissions that extensions have on your users account and devices. Permissions are rated based on the type of access that they have. Some permissions have more capabilities than others so the ratings will vary based on each permission and the number of permissions that the extension itself has been given. <a href="https://developer.chrome.com/docs/extensions/mv2/declare_permissions/#manifest" target="_blank">Learn more about permissions</a>.
                              </Typography>
                        </li>
                        <li>
                          <Typography>
                            Having a high risk rating (e.g. <b>Critical</b>) does not necessarily mean it is a bad app or extension.  It is simply highlighting the item as having potential security risk since the app or extension was not installed by an admin through the Google console.
                          </Typography>
                        </li>

                      </ul>
                    </div>
                  </div>
                )
              }}

            />
            
          </div>
        )
      }

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="App ID Copied to Clipboard"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <BackDrop loading={loading} />

    </div>
  )
}

export default InstalledChromeApps
