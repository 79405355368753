import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormGroup,
  Modal,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppsTableComponent from './apps.table.component';
import { firebaseApp, provider } from '../../../firebase';
import { getUserCredentials } from '../getters/getUserData';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import _ from 'lodash';
import Papa from 'papaparse';
import SearchAppsComponent from './searchApps.component';
import UsageTableComponent from './usage.table.component';
import InstalledChromeApps from './chrome.apps.component';
import UserUsageComponent from './user_usage.component';
import AppRequestsComponent from './app_requests.component';
import BackDrop from '../../backdrop.component';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const test_app_data = require('./test_app_data.json');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    paddingBottom: '5vh',
    minWidth: '90vw'
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  formTitle: {
    textAlign: 'center'
  },
  form1: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  form2: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(1)
  },
  menuBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(1)
  },
  textField: {
    margin: theme.spacing(1)
  },
  addBtns: {
    display: 'flex',
    flexDirection: 'row'
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
}));

const AppsComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ addApp, setAddApp ] = useState(false);
  const [ searchForApps, setSearchForApps ] = useState(false);
  const [tab, setTab] = React.useState(0);

  //  FORM ITEMS
  const [ appTitle, setAppTitle ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ url, setUrl ] = useState("");
  const [ groups, setGroups ] = useState("");
  const [ categories, setCategories ] = useState([]);
  const [ isPublic, setIsPublic ] = useState(false);
  const [ companyName, setCompanyName ] = useState("");
  const [ agreementStatus, setAgreementStatus ] = useState('Approved');
  const [ agreementTypeTitle, setAgreementTypeTitle ] = useState("");
  const [ agreementTypeUrl, setAgreementTypeUrl ] = useState("");
  const [ exhibitTitle, setExhibitTitle ] = useState("");
  const [ exhibitUrl, setExhibitUrl ] = useState("");
  const [ approvalDate, setApprovalDate ] = useState("");
  const [ expirationDate, setExpirationDate ] = useState("");
  const [ agreementDates, setAgreementDates ] = useState("");
  const [ gradeLevel, setGradeLevel ] = useState("");
  const [ contentArea, setContentArea ] = useState("");
  const [ showTour, setShowTour ] = useState(true);

  const steps = [
    {
      target: '.apps-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.apps-table-tab',
      content: 'Access apps listing and edit app information.'
    },
    {
      target: '.apps-search-tab',
      content: 'Search for apps that you want to add from over 3,000 EdTech apps in our database.'
    },
    {
      target: '.apps-add-tab',
      content: 'Add individual app information or use our template to perform a bulk upload.  Click on the button to get started.'
    },
    {
      target: '.apps-data-tab',
      content: 'View usage data about apps that are being used in your district.'
    },
    {
      target: '.chrome-apps-data-tab',
      content: 'A list of Chrome Apps and Extensions used in your district.  It includes information about the app and/or extension as well as the number of users using them and who the users are.'
    },
    {
      target: '.apps-user-tab',
      content: 'Query user app activity in details.  The data provides time spent on specific URLs.'
    },
    {
      target: '.apps-request-tab',
      content: 'List of all app requests and their statuses.'
    },
    {
      target: '.apps-table',
      content: 'This is where you manage your information about your apps.  Every column item is editable.  Simply click on the dashed line.  You can use the actions column to delete an app or see app details such as usage statistics.',
      placement: 'center'
    },
    {
      target: '.sdpc-btn',
      content: 'Integrate Student Data Privacy Consortium data with your managed apps for public disclosure.',
      placement: 'top-end'
    }
  ]

  useEffect(() => {
    if(!mainState.authUser) {
      return;
    }

    //  USER DUMMY DATA
    // if(window.location.hostname === "localhost") {
    //   mainDispatch({ type: 'setLoading', payload: false });
    //   return mainDispatch({ type: 'setApps', payload: test_app_data });
    // }

    const appsListener = firebaseApp.firestore()
      .collection('domains').doc(mainState.domain)
      .collection('apps').onSnapshot(snapshot => {
        if(snapshot.length <= 0 ) {
          return;
        }

        let tempApps = [];
        snapshot.forEach(snap => {
          let tempObj = snap.data();
          tempObj._id = snap.id;
          tempApps.push(tempObj);
        });
        mainDispatch({ type: 'setApps', payload: tempApps });

      });

      return () => appsListener();
  },[]) //  useEffect

  const initGetCredentials = async () => {

    console.log('Getting credential...');
    
    mainDispatch({ type: 'setLoading', payload: true });

    let credentials = await getUserCredentials();

    if(!credentials) {
      mainDispatch({ type: 'setLoading', payload: false });
      return;
    }

    mainDispatch({ type: 'setCredentials', payload: credentials });

    mainDispatch({ type: 'setLoading', payload: false });

    // getUserInfo(mainState.authUser.email,credentials.accessToken);
  }

  const getUserInfo = async (email,accessToken) => {

    console.log('getting user info....');

    let options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json"
      }
    }

    let url = `https://admin.googleapis.com/admin/directory/v1/users/${email}`;

    try {
      let initGetUserInfo = await fetch(url,options);
      let json = await initGetUserInfo.json();

      if(initGetUserInfo.ok) {
        mainDispatch({ type: 'setAdminData', payload: json });
      }

      mainDispatch({ type: 'setLoading', payload: false });

    } catch(e) {
      // statements
      console.log(e);
      mainDispatch({ type: 'setLoading', payload: false });
    }
  }

  const submitApp = e => {

    e.preventDefault();
    e.persist();

    mainDispatch({ type: 'setLoading', payload: true });

    //  IF THERE ARE CUSTOM CATEGORIES
    //  ADD THEM
    let tempCategories = categories;
    let moreCats = document.getElementById('more-categories').value;
    if(moreCats) {
      moreCats.split(',').forEach(item => tempCategories.push(item.trim()));
    }

    let formObj = {
      timestamp: new Date().getTime(),
      title: appTitle,
      desc: description,
      url: url,
      groups: groups.split(',') || [],
      category: tempCategories,
      isPublic: isPublic,
      company: companyName,
      agreementStatus: agreementStatus,
      agreementType: [],
      approvalDate: approvalDate,
      expirationDate: expirationDate,
      gradeLevel: gradeLevel,
      contentArea: contentArea,
      notes: ''
    }

    if(agreementTypeTitle && agreementTypeUrl) {
      formObj.agreementType.push({
        title: agreementTypeTitle,
        url: agreementTypeUrl
      })
    }

    if(exhibitTitle && exhibitUrl) {
      formObj.agreementType.push({
        title: exhibitTitle,
        url: exhibitUrl
      })
    }

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('apps').add(formObj)
      . then(() => {
        console.log('App saved...');
        e.target.reset();
        setCategories([])
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setConfirm', payload: true });
        mainDispatch({ type: 'setConfirmMessage', payload: "Successfully saved app." });
        RecordActions(mainState.domain,mainState.authUser.email,`Added App: ${ appTitle }`);
      })
      .catch(err => {
        console.dir(err);
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setConfirm', payload: true });
        mainDispatch({ type: 'setConfirmMessage', payload: "Error saving app.  Please try again later." });
      })
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    mainDispatch({ type: 'setLoading', payload: true });
    e.persist();

    let file = e.target[0].files[0];

    Papa.parse(file, {
      complete: function (results) {
        let data = results.data || [];

        if(data.length <= 0) {
          mainDispatch({ type: 'setLoading', payload: false });
          return;
        }

        //  UPLOAD TO FIRESTORE
        let domain = mainState.authUser.email.split('@')[1];
        let domainAppsRef = firebaseApp.firestore().collection('domains').doc(mainState.domain)
          .collection('apps');
        let batch = firebaseApp.firestore().batch();

        data.forEach(item => {

          if((item[0]).toLowerCase() === 'title') {
            return;
          }
          if(item[0]) {

            let isPublic = false;

            //  CHECK TYPE AND CONVERT TO BOOLEAN IF NOT
            //  DEFAULT TO FALSE
            if(typeof item[5] !== 'boolean' && typeof item[5] === 'string') {

              if(item[5].toLowerCase() === 'true') {
                isPublic = true;
              }

            }

            let tempObject = {
              title: (item[0]).toLowerCase(),
              desc: item[1],
              url: item[2],
              category: (item[3]).toLowerCase().split(',') || [],
              groups: (item[4]).toLowerCase().split(',') || [],
              isPublic: isPublic,
              company: item[6].toLowerCase(),
              agreementStatus: item[7],
              agreementType: [],
              approvalDate: item[12],
              expirationDate: item[113],
              gradeLevel: item[14],
              contentArea: item[15],
              timestamp: Date.now(),
              notes: ''
            }

            let agreementTypeTitle = item[8];
            let agreementTypeUrl = item[9];
            let exhibitTitle = item[10];
            let exhibitUrl = item[11];

            if(agreementTypeTitle && agreementTypeUrl) {
              tempObject.agreementType.push({
                title: agreementTypeTitle,
                url: agreementTypeUrl
              })
            }

            if(exhibitTitle && exhibitUrl) {
              tempObject.agreementType.push({
                title: exhibitTitle,
                url: exhibitUrl
              })
            }


            batch.set(domainAppsRef.doc(),tempObject)
          }
        });

        batch.commit()
          .then(() => {
            mainDispatch({ type: 'setLoading', payload: false });
            mainDispatch({ type: 'setConfirm', payload: true });
            mainDispatch({ type: 'setConfirmMessage', payload: "Successfully saved apps." });
            RecordActions(mainState.domain,mainState.authUser.email,"Upload Apps to DashBoard");
          })
          .catch(err => {
            console.log(err);
            mainDispatch({ type: 'setLoading', payload: false });
            mainDispatch({ type: 'setConfirm', payload: true });
            mainDispatch({ type: 'setConfirmMessage', payload: "Error saving apps.  Please try again later." });
          });

      }
    });//  PAPA PARSE

  }

  const addCategory = (isChecked,val) => {
    let tempCats = categories;
    if(!isChecked) {
      let filteredCategories = tempCats.filter(cat => cat !== val);
      return setCategories([...filteredCategories]);
    }
    tempCats.push(val);
    setCategories([...tempCats]);
  }

  const updateForm = _.debounce((field,val) => {

    switch(field) {
      case 'title':
        return setAppTitle(val);
      case 'desc':
        return setDescription(val);
      case 'url':
        return setUrl(val);
      case 'groups':
        return setGroups(val);
      case 'company':
        return setCompanyName(val);
      case 'agreementTypeTitle':
        return setAgreementTypeTitle(val);
      case 'agreementTypeUrl':
        return setAgreementTypeUrl(val);
      case 'exhibitTitle':
        return setExhibitTitle(val);
      case 'exhibitUrl':
        return setExhibitUrl(val);
      case 'approvalDate':
        return setApprovalDate(val);
      case 'expirationDate':
        return setExpirationDate(val);
      case 'gradeLevel':
        return setGradeLevel(val);
      case 'contentArea':
        return setContentArea(val);
      default:

    }

  },500);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }

  }

  return (
    <Paper className={`${classes.root} apps-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <div className={classes.paperTabs,'apps-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <Tab label="Apps" className="apps-table-tab" />
            <Tab label="Search Apps" className="apps-search-tab" />
            <Tab label="Add Apps Manually" className="apps-add-tab" />
            <Tab label="Web App Insights" className="apps-data-tab" />
            <Tab label="Chrome Apps | Extensions" className="chrome-apps-data-tab" />
            <Tab label="User Insights" className="apps-user-tab" />
            <Tab label="Requests" className="apps-request-tab" />
          </Tabs>
        </Paper>
      </div>

      { //  APPS TABLE
        tab === 0 && (
          <AppsTableComponent />
        )
      }

      { //  SEARCH APPS
        tab === 1 && (
          <SearchAppsComponent />
        )
      }

      { //  ADD APP FORM
        tab === 2 && (

          <Grid
            container
            justify="space-evenly"
            spacing={2}
            className={classes.forms}
          >
            <Grid item xs={8}>
              <form className={classes.form1}
                onSubmit={submitApp}
              >
                <h3 className={classes.formTitle}>Add Individual App</h3>

                <TextField
                  className={classes.textField}
                  type="text"
                  label='Title'
                  variant="outlined"
                  onChange={e => updateForm('title',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type='text'
                  label='Description'
                  variant="outlined"
                  multiline
                  row={3}
                  onChange={e => updateForm('desc',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type='text'
                  variant="outlined"
                  label='URL (be sure to include https:// portion of address)'
                  onChange={e => updateForm('url',e.target.value)}
                  onBlur={e => updateForm('url',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type='text'
                  variant="outlined"
                  label='Groups (comma separated)'
                  onChange={e => updateForm('groups',e.target.value)}
                />

                {/**** CATEGORIES CHECKBOXES ****/}

                <FormLabel>Categories</FormLabel>

                <FormGroup row>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Admin')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Admin" />
                    }
                    label="Admin"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Athletics')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Athletics" />
                    }
                    label="Athletics"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Staff')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Staff" />
                    }
                    label="Staff"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Support')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Support" />
                    }
                    label="Support"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Teachers')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Teachers" />
                    }
                    label="Teachers"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Students')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Students" />
                    }
                    label="Students"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Applied Technology')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Applied Technology" />
                    }
                    label="Applied Technology"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Art and Music')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Art and Music" />
                    }
                    label="Art and Music"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Business Education')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Business Education" />
                    }
                    label="Business Education"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('English')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="English" />
                    }
                    label="English"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Family and Consumer Sciences')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Family and Consumer Sciences" />
                    }
                    label="Family and Consumer Sciences"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Math')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Math" />
                    }
                    label="Math"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Physical Education')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Physical Education" />
                    }
                    label="Physical Education"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Science')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Science" />
                    }
                    label="Science"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Social Studies')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Social Studies" />
                    }
                    label="Social Studies"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('Special Education')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="Special Education" />
                    }
                    label="Special Education"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={categories.includes('World Language')}
                        onChange={e => addCategory(e.target.checked,e.target.name)}
                        name="World Language" />
                    }
                    label="World Language"
                  />

                  <TextField
                    id='more-categories'
                    label="Add more categories"
                    helperText="separate by comma"
                    variant="outlined"
                  />

                </FormGroup>

                {/**** END CATEGORIES CHECKBOXES ****/}

                <Typography variant="h6">
                  Additional information that will render on public-facing site.
                </Typography>

                <FormControlLabel
                  control={<Checkbox checked={isPublic} onChange={() => setIsPublic(!isPublic)} name="public" />}
                  label="isPublic (Applies to domains with public facing site feature.)"
                />

                <TextField
                  className={classes.textField}
                  style={{ margin: '10px'}}
                  type="text"
                  label='Company Name'
                  variant="outlined"
                  onChange={e => updateForm('company',e.target.value)}
                />

                {/**** AGREEMENT STATUS ****/}

                <FormControl component="fieldset">
                  <FormLabel component="legend">Agreement Status</FormLabel>
                  <RadioGroup aria-label="agreementStatus" name="agreementStatus" value={agreementStatus} onChange={e => setAgreementStatus(e.target.value) }>
                    <FormControlLabel value="Approved" control={<Radio />} label="Approved" />
                    <FormControlLabel value="Approved w/ Guardian Release" control={<Radio />} label="Approved w/ Guardian Release" />
                    <FormControlLabel value="Not Approved" control={<Radio />} label="Not Approved" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Agreement Type Title'
                  helperText="Example:  IL-NDPA-V1.0a (With Exhibit)"
                  onChange={e => updateForm('agreementTypeTitle',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Agreement Type URL'
                  helperText="Be sure to include https as part of URL."
                  onChange={e => updateForm('agreementTypeUrl',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Exhibit Title'
                  helperText="Example: Exhibit E"
                  onChange={e => updateForm('exhibitTitle',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Exhibit URL'
                  helperText="Be sure to include https as part of URL."
                  onChange={e => updateForm('exhibitUrl',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type="date"
                  variant="outlined"
                  label='Approval Date'
                  onChange={e => updateForm('approvalDate',e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  className={classes.textField}
                  type="date"
                  variant="outlined"
                  label='Expiration Date'
                  onChange={e => updateForm('expirationDate',e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Grade Level'
                  multiline
                  rows={3}
                  onChange={e => updateForm('gradeLevel',e.target.value)}
                />

                <TextField
                  className={classes.textField}
                  type="text"
                  variant="outlined"
                  label='Content Area'
                  multiline
                  rows={3}
                  onChange={e => updateForm('contentArea',e.target.value)}
                />

                <Button
                  className={classes.submitBtn}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={mainState.isLoading}
                >
                  Submit
                </Button>

              </form>
            </Grid>

            <Grid item xs={4}>
              <form
                className={classes.form2}
                onSubmit={handleUpload}
              >

              <h3 className={classes.formTitle}>Add Multiple Apps</h3>

                <p>
                  Please use: &nbsp; &nbsp;
                  <a href="https://docs.google.com/spreadsheets/d/1wR719yBEPl34txcrNgiTtcAoTK-Ycbc41exu9noagbQ/edit#gid=0" target="_blank">
                    Template Sheet
                  </a>
                </p>
                <TextField type='file' required />
                <Button
                  style={{marginTop: '10px',marginBottom: '10px'}}
                  variant='contained'
                  color='secondary'
                  type='submit'
                >
                  Upload Apps
                </Button>
              </form>
            </Grid>

          </Grid>

        )
      }

      { //  APP USAGE
        tab === 3 && (
          <UsageTableComponent />
        )
      }

      { //  CHROME APPS
        tab === 4 && (
          <InstalledChromeApps />
        )
      }

      { // USER INSIGHT
        tab === 5 && (
          <UserUsageComponent />
        )
      }

      { // USER INSIGHT
        tab === 6 && (
          <AppRequestsComponent />
        )
      }

      <BackDrop loading={mainState.isLoading}/>
    </Paper>
  )
}

export default AppsComponent;
