import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import CustomHeader from './header.component';
import BackDrop from './backdrop.component';
import ContactFormComponent from './inquiry_forms/contact_form.component';

const ContactUsComponent = props => {

  return (
    <div>

      <CustomHeader title='Contact Us' />

      <ContactFormComponent />

    </div>
  )
}

export default ContactUsComponent;
