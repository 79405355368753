import * as firebase from 'firebase/app';
import "firebase/auth";
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';


const apiKey = process.env.REACT_APP_API_KEY
const authDomain = process.env.REACT_APP_AUTH_DOMAIN
const databaseURL = process.env.REACT_APP_DATABASE_URL
const projectId = process.env.REACT_APP_PROJECT_ID
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET
const appId = process.env.REACT_APP_ID
const measurementId = process.env.REACT_APP_MEASUREMENT_ID

var firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  databaseURL: databaseURL,
  projectId: projectId,
  storageBucket: storageBucket,
  appId: appId,
  measurementId: measurementId
};


const firebaseApp = firebase.initializeApp(firebaseConfig)
const provider = new firebase.auth.GoogleAuthProvider();
const analytics = firebaseApp.analytics();
const functions = firebase.functions();

export{
  firebaseApp,
  provider,
  analytics,
  functions
}
