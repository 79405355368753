import React, { useEffect, useState, useContext } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Tabs,
  Tab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HeaderComponent from '../../header.component';
import ResourcesTableComponent from './resources.table.component';
import { firebaseApp } from '../../../firebase';
import { RecordActions } from '../admin_actions';
import MainContext from '../../state/main.context';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import Papa from 'papaparse';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    padding: theme.spacing(1),
    paddingBottom: '5vh',
    width: '90vw'
  },
  forms: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2)
  },
  formTitle: {
    textAlign: 'center'
  },
  form1: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  form2: {
    display: 'flex',
    flexGrow: 0.5,
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: theme.spacing(2)
  },
  submitBtn: {
    margin: theme.spacing(1)
  },
  menuBtn: {
    alignSelf: 'flex-start',
    margin: theme.spacing(1)
  },
  paperTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
}));

const ResourcesComponent = props => {
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(false);
  const [ showTour, setShowTour ] = useState(true);
  const [tab, setTab] = React.useState(0);

  //  ADD RESOUCE FORM
  const [ groups, setGroups ] = useState([]);

  const steps = [
    {
      target: '.resources-root',
      content: <div style={{ marginTop: '10vh' }}>
        <p>
          Click next to learn more about this page.
        </p>
      </div>,
      placement: 'top-end',
    },
    {
      target: '.resources-add',
      content: 'Add individual resource information or use our template to perform a bulk upload.  Click on the button to get started.'
    },
    {
      target: '.resources-table',
      content: 'This is where you manage your information about your resources.  To edit information about a resouce, click on the pencil icon under the actions column.',
      placement: 'center'
    }
  ]

  const submitResource = e => {
    e.preventDefault();
    e.persist();

    mainDispatch({ type: 'setLoading', payload: true });

    let title = e.target[0].value;
    let desc = e.target[1].value;
    let url = e.target[3].value;
    let category = e.target[9].value;

    firebaseApp.firestore().collection('domains').doc(mainState.domain)
      .collection('resources').add({
        timestamp: new Date().getTime(),
        title:title,
        desc:desc,
        url:url,
        groups: groups,
        category: category,
        isPublic: false,
        notes: ''
      })
      . then(() => {
        console.log('Resouce saved...');
        e.target.reset();
        setGroups([]);
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setConfirm', payload: true });
        mainDispatch({ type: 'setConfirmMessage', payload: "Successfully saved resource." });
        RecordActions(mainState.domain,mainState.authUser.email,`Added Resource: ${ title }`);
      })
      .catch(err => {
        console.dir(err);
        mainDispatch({ type: 'setLoading', payload: false });
        mainDispatch({ type: 'setConfirm', payload: true });
        mainDispatch({ type: 'setConfirmMessage', payload: "Error saving resource.  Please try again later." });
      })
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    mainDispatch({ type: 'setLoading', payload: true });
    e.persist();

    let file = e.target[0].files[0];

    Papa.parse(file, {
      complete: function (results) {
        let data = results.data || [];
        console.dir(data);
        if(data.length <= 0) {
          mainDispatch({ type: 'setLoading', payload: false });
          return;
        }
        console.dir(data);

        //  UPLOAD TO FIRESTORE
        let domain = mainState.authUser.email.split('@')[1];
        let domainResourceRef = firebaseApp.firestore().collection('domains').doc(mainState.domain)
          .collection('resources');
        let batch = firebaseApp.firestore().batch();

        data.forEach(item => {
          if((item[0]).toLowerCase() === 'title') {
            return;
          }
          if(item[0] && item[1] && item[2] && item[3]) {
            batch.set(domainResourceRef.doc(),{
              title: (item[0]).toLowerCase(),
              desc: item[1],
              url: item[2],
              category: (item[3]).toLowerCase(),
              groups: (item[4]).toLowerCase().split(',') || [],
              timestamp: new Date().getTime(),
              isPublic: false,
              notes: ''
            })
          }
        });

        batch.commit()
          .then(() => {
            mainDispatch({ type: 'setLoading', payload: false });
            mainDispatch({ type: 'setConfirm', payload: true });
            mainDispatch({ type: 'setConfirmMessage', payload: "Successfully saved resources." });
            RecordActions(mainState.domain,mainState.authUser.email,`Uploaded Resources`);
          })
          .catch(err => {
            console.log(err);
            mainDispatch({ type: 'setLoading', payload: false });
            mainDispatch({ type: 'setConfirm', payload: true });
            mainDispatch({ type: 'setConfirmMessage', payload: "Error saving resources.  Please try again later." });
          });

      }
    });//  PAPA PARSE

  }

  const handleCallback = data => {

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      setTimeout(() => setShowTour(true),500);
    }
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Paper className={`${classes.root} resources-root`}>

      <Joyride
        run={showTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleCallback}
      />

      <div className={classes.paperTabs,'resources-tabs'}>
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <Tab label="Resources" className="resources-table" />
            <Tab label="Add Resource" className="resources-add" />
          </Tabs>
        </Paper>
      </div>

      {
        tab === 0 && (
          <ResourcesTableComponent />
        )
      }

      {  //  ADD RESOURCE FORM
        tab === 1 && (

          <Grid
            container
            justify="space-evenly"
            spacing={2}
            className={classes.forms}
          >
            <Grid item xs={8}>
              <form className={classes.form1}
                onSubmit={submitResource}
              >
                <h3 className={classes.formTitle}>Add Individual Resource</h3>

                <TextField
                  type="text"
                  label='Title'
                />

                <TextField
                  type='text'
                  label='Description'
                  multiline
                  row={3}
                />

                <TextField
                  type='text'
                  label='URL (be sure to include https:// portion of address)'
                />

                <FormLabel style={{ marginTop: "10px" }}>Select OU that will have access to the resouce.</FormLabel>
                <FormGroup>
                  {
                    (mainState.domainData.hasOwnProperty('orgUnits') && mainState.domainData.orgUnits.length > 0)
                      
                      ?  mainState.domainData.orgUnits.map(ou => 

                          <FormControlLabel
                            key={ou} 
                            control={
                              <Checkbox  
                                checked={groups.includes(ou)}
                                onChange={(e) => {
                                  if(groups.includes(ou)) {
                                    let tempGroups = groups.filter(group => group !== ou);
                                    setGroups(tempGroups);
                                  } else {
                                    let newGroup = [...groups,ou];
                                    setGroups(newGroup);
                                  }
                                }}
                              />
                            } 
                            label={ou} 
                          />

                        )

                      : <p>Organizational Units were not found in our database.  Please perform a Google Sync at least one time before adding a resource.</p>
                  }
                </FormGroup>

                <TextField
                  type='text'
                  label='Category'
                />

                <Button
                  className={classes.submitBtn}
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={mainState.isLoading}
                >
                  Submit
                </Button>

              </form>
            </Grid>

            <Grid item xs={4}>
              <form
                className={classes.form2}
                onSubmit={handleUpload}
              >

              <h3 className={classes.formTitle}>Add Multiple Resources</h3>

                <p>
                  A .csv file with FIVE columns is required. &nbsp; &nbsp;
                  <a href="https://docs.google.com/spreadsheets/d/1wYjSb09F89I-He4o9BhzfCvHcsj3MtSK1O3Hlm0k-k8/edit?usp=sharing" target="_blank">
                    Template Sheet
                  </a>
                </p>
                <TextField type='file' required />
                <Button
                  style={{marginTop: '10px',marginBottom: '10px'}}
                  variant='contained'
                  color='secondary'
                  type='submit'
                >
                  Upload Resources
                </Button>
              </form>
            </Grid>

          </Grid>

        )
      }

    </Paper>
  )
}

export default ResourcesComponent;
