import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  MenuItem,
  Grow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from './state/main.context';
import { SignOut } from './utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  },
  headerTitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  mainTitle: {
    marginRight: theme.spacing(5),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2)
  },
  pageLocation: {
    fontWeight: 'bolder'
  },
  btnGroup: {
    flexGrow: 1,
    display:'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(10)
  },
  menuButton: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: theme.spacing(2)
  },
  logo: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    maxHeight: 75,
    maxWidth: 75
  }
}))

const CustomHeader = ({ title }) => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);
  const [ pageTitle, setPageTitle ] = useState(title);
  const [ showMenu, setShowMenu ] = useState(true);
  const [ open, setOpen ] = useState(false);
  const anchorRef = React.useRef(null);

  let { mainState, mainDispatch } = context;

  useEffect(() => {

    switch(title) {
      case 'Confirm Account | Change Password':
      case 'Sign Up':
      case 'Login':
      case 'Inactive Domain':
      case 'Public':
        setShowBtn(false);
        setShowMenu(false);
        break;

      case 'Requester':
        if(mainState.authUser) {
          setPageTitle(mainState.authUser.displayName);
        }
        break;

      default:
        setShowBtn(true);
    }

  },[])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClick = () => {
    if(!mainState.authUser || title === 'Home') {
      history.push('/login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/');
    SignOut();
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event,page) => {

    if(page) {
      goToPage(page);
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

  }

  const goToPage = page => {

    switch(page) {
      case 'approve-it':
        window.open('https://approveit.app/home');
        break;

      default:
        return;
    }

  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

    return (
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>
          <div className={classes.headerTitles}>
            <img
              onClick={() => history.push('/home')}
              className={classes.logo}
              src={"https://firebasestorage.googleapis.com/v0/b/manage-it-extension.appspot.com/o/assets%2FApproveItLogoTrans%20WHITE.png?alt=media&token=662d8e7e-62fa-4e26-a30f-1536e510f5f8"}
            />
            <Typography
              variant='h6'
              noWrap
              className={classes.mainTitle}
              onClick={() => history.push('/home')}
            >
            Manage iT  |
            </Typography>
            <Typography
              className={classes.pageLocation}
            >
            { showMenu ? pageTitle : null }
            </Typography>
          </div>

          <div className={classes.btnGroup}>
          {
            showMenu && (
              <div className={classes.menuButton}>
              <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color='inherit'
                >
                  Solutions
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={event => handleMenuClose(event,'')}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem
                              onClick={event => handleMenuClose(event,'approve-it')}
                            >
                              Automated Approval Forms
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )
          }

          {
            showMenu && (
              <div className={classes.menuButton}>
                <Button
                  color='inherit'
                  onClick={() => {
                    history.push('/pricing');
                  }}
                >
                  Pricing
                </Button>
              </div>
            )
          }

          </div>


          {
            showBtn ?

              <Button
                color='inherit'
                className={classes.loginBtn}
                onClick={handleClick}
              >
                {(mainState.authUser && title !== 'Home') ? 'Logout' : 'Login'}
              </Button>

            : null

          }

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
