import React, { useEffect, useState, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  ListItemAvatar,
  Accordion,
  AccordionSummary,
  AccordionDetails
}  from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainContext from '../state/main.context';
import BackDrop from '../backdrop.component';
import MaterialTable from "material-table";
import moment from 'moment';
import { firebaseApp } from '../../firebase';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import '../css/heat-map.css';

const startDate = moment().subtract(30,'days').format("YYYY-MM-DD");
const endDate = moment().format("YYYY-MM-DD");

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    width: '100vw'
  },
  leftContainer: {
    flexGrow: 1
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      color: '#f0006c'
    }
  },
  rightContainer: {
    flexGrow: 5,
    overflow: 'scroll'
  },
  img: {
    width: 50,
    height: 50
  },
  detailPanel: {
    width: '100%'
  },
  summary: {
    backgroundColor: 'lightgrey',
    flexDirection: 'row-reverse'
  },
  heading: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold'
  },
  subheading: {
    marginLeft: theme.spacing(2)
  },
  recordsNum: {
    marginLeft: theme.spacing(2)
  },
  accordionDetails: {
    // display: 'flex',
    // flexFlow: 'column',
    // flexWrap: 'wrap',
    // justifyContent: 'flex-start',
    // height: 600,
    minWidth: '100%',
    // overflow: 'auto'
  },
  time: {
    maxWidth: 300,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  toolTipDiv: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    minWidth: 600,
    maxWidth: 600,
    minHeight: 200,
    maxHeight: 500,
    overflow: 'auto'
  },
  time2: {
    maxWidth: 100,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  heatmap: {
    minWidth: 500,
    margin: theme.spacing(2)
  },
}));

const HeatMap = ({records }) => {
  const classes = useStyles();
  const [ data, setData ] = useState([]);

  useEffect(() => {
    if(records.length > 0) {
      let tempData = records.reduce((acc,current) => {
        let date = moment(current).format('YYYY-MM-DD');
        let itemExist = false;

        acc.some(item => {
          if(item.date == date) {
            item.count++;
            item.time.push(current)
            itemExist = true;
            return true;
          }
          return false;
        })

        if(!itemExist){
          acc.push({
            date: date,
            count: 1,
            time: [current]
          })
        }

        return acc;
      },[]);
      setData(tempData);
    }
  },[]);

  return (
    <div className={classes.heatmap}>
      <CalendarHeatmap
        startDate={moment().subtract(90,'days')}
        endDate={new Date()}
        values={data}
        classForValue={value => {
          if (!value) {
            return 'color-empty';
          }
          return `color-github-${value.count}`;
        }}
        tooltipDataAttrs={value => {

          if(!value.date) {
            return {
              'data-tip': ''
            }
          } else {
            return {
              // 'data-tip': `${value.date} has count: ${
              //   value.count
              // }`,
              'data-tip':`${value.date}`
            };
          }

        }}
        showWeekdayLabels={true}
      />
      <ReactTooltip
        getContent={dataTip => {
          if(!dataTip) {
            return <Typography>No data for this day.</Typography>
          }

          let dateData = data.filter(d => d.date == dataTip);

          return (
            <div>

              <Typography align='center'>{moment(dataTip).format("ddd, MMMM Do YYYY")}</Typography>

              <div className={classes.toolTipDiv}>
              {
                dateData[0].time.sort((a,b) => b - a).map((record,i) =>

                    <Typography key={i} className={classes.time2}>
                      { moment(record).format("h:mm a") }
                    </Typography>

                )
              }
              </div>
            </div>
          )
        }}
        effect='solid'
        delayHide={500}
        delayShow={500}
        delayUpdate={500}
        place={'right'}
        border={true}
      />
    </div>
  )
}

const SiteDetailPanel = ({ studentEmail, site }) => {
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ loading, setLoading ] = useState(true);
  const [ data, setData ] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getSiteDetails();
  },[studentEmail]);

  const getSiteDetails = async () => {

    setLoading(true);

    let token = await firebaseApp.auth().currentUser.getIdToken();

    let options = {
      method: 'Post',
      body: JSON.stringify({
        email: mainState.authUser.email,
        studentEmail: studentEmail,
        idToken: token,
        site: site
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getStudentSiteData";

    let initFetch = await fetch(url,options);

    let json = await initFetch.json();

    if(initFetch.ok) {
      if(json.length > 0) {
        setData([...json]);
      } else {
        setData([]);
      }
    }

    setLoading(false);
  }

  return(
    <div className={classes.detailPanel}>

      {
        data.length <= 0 && (
          <Typography>No data available.</Typography>
        )
      }

      {
        data.length > 0 && (
          <div>
            {
              data.map(each =>
                <Accordion key={each.url} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.summary}
                  >
                    <Typography className={classes.heading}> { each.title } </Typography>

                    <Typography className={classes.subheading}>
                     <a href={each.url} target="_blank">{ each.url }</a>
                    </Typography>

                    <Typography className={classes.recordsNum}>
                     { each.records.length }
                    </Typography>

                  </AccordionSummary>

                  <AccordionDetails
                    className={classes.accordionDetails}
                  >

                    <HeatMap records={each.records} />

                  </AccordionDetails>

                </Accordion>
              )
            }
          </div>
        )
      }

      <BackDrop loading={loading} />
    </div>
  )
}

const StudentActivityComponent = ({ studentEmail }) => {

  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const [ visitedSites, setVisitedSites ] = useState([]);

  useEffect(() => {
    getStudentSiteVisits();
  },[studentEmail]);

  const getStudentSiteVisits = async () => {

    setLoading(true);

    let token = await firebaseApp.auth().currentUser.getIdToken();

    let options = {
      method: 'Post',
      body: JSON.stringify({
        email: mainState.authUser.email,
        studentEmail: studentEmail,
        idToken: token
      })
    }

    let url = "https://us-central1-manage-it-extension.cloudfunctions.net/getStudentSiteVisits";

    let initFetch = await fetch(url,options);

    let json = await initFetch.json();

    if(initFetch.ok) {
      if(json.length > 0) {
        setVisitedSites([...json]);
      } else {
        setVisitedSites([]);
      }
    }

    setLoading(false);

  }

  const columns = [
    {
      render: rowData => {
        if(rowData.hasOwnProperty('favIconUrl') && rowData.favIconUrl) {
          return (
            <img src={rowData.favIconUrl} className={classes.img} />
          )
        }
        return (
          <div></div>
        )
      },
      width: 60
    },
    {
      title: "Sites",
      field: 'title',
      render: rowData => (
        <Typography>{ rowData.title.toUpperCase() }</Typography>
      ),
      width: 250
    },
    {
      title: "# Visits",
      field:'visits',
      defaultSort: 'desc',
      render: rowData => (
        <Typography>{ rowData.visits }</Typography>
      ),
      width: 100
    },
    {
      title: "Last Visited",
      render: rowData => (
        <Typography>{ moment(rowData.last_visit).format("dd, MMM Do YYYY, h:mm a") }</Typography>
      )
    }
  ]

  return (
    <div>

      <MaterialTable
        title={studentEmail}
        data={visitedSites}
        columns={columns}
        style={{
          maxWidth: '80vw',
          margin: '10px',
          marginTop: '30px'
        }}
        options={{
          search: true,
          sorting: true,
          pageSize: 20
        }}

        detailPanel={ rowData => {
            return (
              <SiteDetailPanel
                studentEmail={studentEmail}
                site={rowData.title}
              />
            )
        }}

      />

      <BackDrop loading={loading} />

    </div>
  )
}

const CoursesDetailComponent = ({ courseId }) => {

  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { credentials, authUser, } = mainState;
  const [ loading, setLoading ] = useState(false);
  const [ students, setStudents ] = useState([]);
  const [ selectedStudent, setSelectedStudent ] = useState(null);
  const [ visitedSites, setVisitedSites ] = useState([]);

  useEffect(() => {
    if(!courseId){
      return;
    }
    getStudents();
  }, [])

  const getStudents = async () => {

    let url = `https://classroom.googleapis.com/v1/courses/${courseId}/students?pageSize=50&access_token=${credentials.accessToken}&key=${mainState.classroomKey}`;

    try {
      setLoading(true);

      let initGetStudents = await fetch(url);
      let json = await initGetStudents.json();

      if(initGetStudents.ok && json.hasOwnProperty('students') && json.students.length > 0) {
        setStudents(json.students);
      }

      setLoading(false);

    } catch (e) {
      console.dir(e);
      setLoading(false);
    }


  }

  return (
    <div className={classes.root}>

      <Paper className={classes.leftContainer}>
        {
          students.length > 0 && (
            <List>
            {
              students.map(student =>
                <div key={student.userId}>
                <ListItem
                  alignItems="flex-start"
                  className={classes.listItem}
                  onClick={() => {
                    setSelectedStudent(null);
                    setVisitedSites([]);
                    setSelectedStudent(student.profile)
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="Student Image"
                      src=""
                      />
                  </ListItemAvatar>
                  <ListItemText>
                    { student.profile.name.fullName }
                  </ListItemText>
                </ListItem>
                <Divider />
                </div>
              )
            }
            </List>
          )
        }
      </Paper>

      <div className={classes.rightContainer}>
          {
            !selectedStudent && (
              <Typography align="center">
              Select a student on the left.
              </Typography>
            )
          }

          {
            selectedStudent ?

              <StudentActivityComponent
                studentEmail={selectedStudent.emailAddress}
              />
            : null
          }
      </div>

      <BackDrop loading={loading} />
    </div>
  )
}

export default CoursesDetailComponent
